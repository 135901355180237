import React, { Component } from 'react';
import SCurrencyDisplay from 'shared/components/CurrencyDisplay';
import styled from 'styled-components';
import ImageBW from '../ImageBoundsWrapper';
import Tooltip from '../Tooltip';
import { TYPEOF } from 'shared/constants';
import { TopBarInsetWrapper } from './styledComponents';

export const currencyDisplayComponentName = 'CurrencyDisplay';

export default class CurrencyDisplay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value,
      shouldAnimate: false,
    };
    this.componentRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value && this.state.shouldAnimate) {
      this.setState({ value: this.props.value });
    } else if (prevProps.value !== this.props.value) {
      this.setState({ value: this.props.value });
    }
  }

  componentDidMount() {
    this.getElementPosition();
    this.setState({ shouldAnimate: true });
    window.addEventListener('resize', this.getElementPosition);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.getElementPosition);
  }

  _hoverHandler = event => {
    this.props.changeTooltipType(this.props.currencyType);
  };

  _toggleTooltip = event => {
    this.props.changeTooltipType(this.props.tooltipType === this.props.currencyType ? null : this.props.currencyType);
  };

  getElementPosition() {
    if (this.componentRef) {
      const element = this.componentRef.current;
      const { x, y, width, height } = element.getBoundingClientRect();
      const innerWidth = window.innerWidth;

      x < innerWidth / 2 && this.props.setPosition({ component: currencyDisplayComponentName, x, y, width, height });
      this.props.setDimensions({ component: currencyDisplayComponentName, width, height });
    }
  }

  render() {
    return (
      <SCurrencyDisplay
        {...this.props}
        navigation={this.props.navigation}
        value={this.state.value}
        imageOnly={this.props.imageOnly}
        onClick={this.props.onClick}
        toggleTooltip={this._toggleTooltip}
        render={args => {
          const { displayText, rawValue, truncated, onClickHandler, containerHeight, containerWidth } = args;

          const needHover = truncated || this.props.imageOnly;

          return (
            <div
              id={'CurrencyDisplay'}
              className={`CurrencyDisplayWrapper ${this.props.additionalWrapperClassNames}`}
              style={{
                ...styles.containerStyle,
                ...this.props.style,
                ...(this.props.onClick ? { cursor: 'pointer' } : {}),
              }}
              onMouseEnter={needHover ? this._hoverHandler : null}
              onClick={
                typeof onClickHandler === TYPEOF.FUNCTION
                  ? () => (!needHover ? onClickHandler(this.props.currencyType) : null)
                  : onClickHandler
              }
            >
              <CurrencyImageWrapper id="CurrencyBar" ref={this.componentRef} className="CurrencyIconWrapper">
                {this.props.ibw && (
                  <ImageBW
                    source={this.props.source}
                    ibw={this.props.ibw}
                    manualAssetScale={this.props.manualAssetScale || 3}
                    style={{
                      ...styles.currencyImageBWStyle,
                      ...this.props.imgStyle,
                    }}
                  />
                )}
                {!this.props.ibw && (
                  <img
                    src={this.props.source}
                    style={{
                      ...styles.currencyImageStyle,
                      ...this.props.imgStyle,
                    }}
                    alt=""
                  />
                )}
                {!this.props.imageOnly && (
                  <>
<TopBarInsetWrapper shouldRunAnimation={!this.props.isCoinsGlowAnimationStopped && this.props.isCoinsGlowAnimationEnabled}>
                      <ImageBW
                        source={require(asset`images/Top_Bar_Assets/Bar_Inset@3x.png`)}
                        ibw={require(asset`images/Top_Bar_Assets/Bar_Inset@3x.ibw`)}
                        style={styles.currencyBarImage}
                      >
                        <div
                          className="TopBarCurrencyDisplay"
                          style={{
                            ...this.props.innerStyle,
                          }}
                        >
                          {displayText}
                        </div>
                      </ImageBW>
                    </TopBarInsetWrapper>
                  </>
                )}
                <Tooltip
                  source={this.props.source}
                  ibw={this.props.ibw}
                  displayText={rawValue}
                  iconType={this.props.iconType}
                  visible={this.props.tooltipType && this.props.tooltipType === this.props.currencyType}
                  actionHandler={this.props.onClick}
                  style={{
                    height: containerHeight,
                    width: containerWidth,
                    left: this.props.imageOnly ? (50 - containerWidth) / 2 : 'unset',
                  }}
                />
              </CurrencyImageWrapper>
            </div>
          );
        }}
      />
    );
  }
}

const CurrencyImageWrapper = styled.div`
  flex: 1;
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const styles = {
  containerStyle: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '1rem',
    marginRight: '2rem',
    maxWidth: 230,
  },
  currencyImageStyle: {
    position: 'absolute',
    left: 0,
    top: -2,
    zIndex: 1,
    height: 39,
    width: 32,
    objectFit: 'contain',
  },
  currencyImageBWStyle: {
    position: 'absolute',
    left: 0,
    top: -2,
    zIndex: 1,
    height: 36,
    width: 36,
    objectFit: 'contain',
  },
  currencyBarImage: {
    height: '100%',
    width: '100%',
  },
};

