import styled from 'styled-components';
import theme from 'shared/assets/style/theme';
import Animated from 'animated/lib/targets/react-dom';
const windowWidth = window.innerWidth;
const windowHeight = window.innerHeight;

export const PageContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url(${props => props.background});
  background-size: 100% 100%;
`;

export const HiddenContentWrapper = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const WheelSlice = styled(Animated.div)`
  position: absolute;
  left: 0px;
  right: 0px;
  height: 50px;
  transform: rotate(${({ rowindex }) => (360 / 16) * rowindex}deg);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

export const WheelSliceContainer = styled.div`
  width: 23%;
  display: flex;
  align-items: center;
  justify-content: left;
`;

export const SliceCurrencyIcon = styled.img`
  height: 30px;
  width: 30px;
  object-fit: contain;
`;

export const SliceTextWrapper = styled.span`
  ${({ theme }) => theme}
  text-stroke: 1px ${theme.palette.primary[4]};
  -webkit-text-stroke: 1px ${theme.palette.primary[4]};
  margin-left: ${({ outstanding }) => (outstanding ? 20 : 0)}px;
`;

export const SkipButton = styled(Animated.div)`
  position: absolute;
  right: 16px;
  height: 57px;
  width: 88px;
  pointer-events: all;
`;

export const TickerContaner = styled.div`
  position: absolute;
  top: 40%;
  right: -15%;
  height: 20%;
  width: 20%;
  background-color: transparent;
  border-radius: 50%;
`;

export const StreakColumnContainer = styled(Animated.div)`
  flex: 1;
  width: ${props => props.width + props.unit};
  padding: ${({ hero }) =>
    hero && windowHeight < 800
      ? 0
      : windowHeight > 920 && windowWidth < 1300
      ? '4.2vw 0'
      : windowHeight > 1020
      ? '3.2vw 0'
      : '1.2vw 0'};
  box-sizing: border-box;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 580px;
`;

export const StreakColumnContentWrapper = styled.div`
  width: 70%;
  height: 95%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const StreakColumnBackground = styled.img`
  width: 100%;
  height: 100%;
  object-fit: fill;
  position: absolute;
  top: 0;
  left: 0;
`;

export const StreakColumnHolder = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
`;

export const StreakHeaderTextWrapper = styled.div`
  position: relative;
  top: -4px;
  font-size: 0.8rem;
  color: ${theme.palette.common[1]};
`;

export const MilestoneRow = styled.div`
  position: absolute;
  top: ${({ hero }) =>
    hero ? (windowHeight < 950 ? '10%' : '13.5%') : windowHeight < 800 ? '17%' : windowHeight < 1000 ? '20%' : '21%'};
  width: 142%;
  height: ${({ hero }) => (hero ? 210 : 170)}px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  z-index: 2;
`;

export const MilestoneBackground = styled.img`
  width: 100%;
  height: 100%;
  object-fit: fill;
  position: absolute;
  top: 0;
  left: -3px;
`;

export const MilestoneRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100px;
  justify-content: space-between;
  align-items: center;
  padding: 0px 50px;
  z-index: 2;
`;

export const MilestonePrizeItemWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  line-height: 1;
`;

export const MilestonePrizeTextHolder = styled.div`
  text-align: center;
  margin-left: 0.5rem;
  font-size: 2.1rem;
  font-weight: 700;
  ${({ theme }) => theme}
`;

export const MilestonePlusText = styled(MilestonePrizeTextHolder)`
  margin-right: 0.5rem;
  margin-left: 0px;
`;

export const MilestoneCircleText = styled.div`
  font-size: ${({ length }) => (windowHeight < 800 ? (length < 3 ? '20px' : '25px') : length < 3 ? '2rem' : '1.5rem')};
  font-weight: 700;
  ${({ theme }) => theme}
`;

export const StreakColumnHolderSpace = styled.div`
  display: flex;
  flex: 0.5;
`;

export const DaysStreakContainer = styled.div`
  flex: 1;
  align-self: stretch;
  margin: 0px 24px;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-evenly;
`;

export const RegDaysRow = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: ${windowHeight < 750 ? '35px' : 'auto'};
`;

export const RegDaysLightImageLeft = styled.img`
  height: 30px;
  width: 30px;
  position: absolute;
  left: -20px;
`;

export const RegDaysLightImageRight = styled(RegDaysLightImageLeft)`
  right: -20px;
  left: auto;
`;

export const RegDaysStreakDayText = styled.div`
  color: ${theme.palette.common[1]};
  width: 45px;
  text-align: center;
  font-size: ${({ length }) => (length === 1 ? 1.4 : length === 2 ? 1.1 : 0.9)}rem;
  font-weight: 600;
  padding-right: 5px;
  ${({ theme }) => theme}
`;

export const RegDaysDayText = styled(RegDaysStreakDayText)`
  font-size: 1.3rem;
  opacity: ${props => props.opacity};
`;

export const RegDayRowContentWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  z-index: 1;
`;

export const RegDaysItemWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const RegDaysItemText = styled.div`
  opacity: ${props => props.opacity};
  color: ${theme.palette.common[1]};
  text-align: center;
  margin-left: 2px;
  font-size: ${windowWidth > 1530 ? 1.6 : 1.4}rem;
  font-weight: 600;
  ${({ theme }) => theme}
`;

export const RegDaysItemWrapperInnerIcon = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: ${({ paddingright }) => paddingright || 0}px;
`;
export const RegDaysItemWrapperInnerText = styled.div`
  flex: ${({ flex }) => flex || 2};
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const RegDaysItemWrapperCenter = styled(RegDaysItemWrapper)`
  justify-content: center;
  flex: 0.3;
  color: ${theme.palette.common[1]};
  font-size: 1.5rem;
  font-weight: 600;
  opacity: ${props => props.opacity};
`;

export const EmptyDivSpace = styled.div`
  flex: ${props => props.flex};
`;

export const RegDaysItemWrapperRight = styled(RegDaysItemWrapper)`
  justify-content: flex-end;
`;

export const RegDaysItemCoinIcon = styled.img`
  height: 28px;
  width: 28px;
  opacity: ${props => props.opacity};
  object-fit: contain;
`;

export const BottomBarContainer = styled(Animated.div)`
  ${({ theme }) => theme}
`;

export const BottomBarBackgroundWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  object-fit: fill;
`;

export const BottomBarBackgroundWrapperImage = styled.img`
  ${({ theme }) => theme}
  position: absolute;
  bottom: 0px;
  left: 0px;
  pointer-events: none;
`;

export const BottomLeftPartIcon = styled.img`
  height: 55%;
  object-fit: contain;
  filter: drop-shadow(2px 2px 4px ${theme.palette.common[26]});
`;

export const BottomBarPlusText = styled.span`
  font-size: 2rem;
  font-weight: 400;
  text-shadow: 2px 2px 4px ${theme.palette.common[26]};
  z-index: 1;
`;

export const BottomRightSideContainer = styled.div`
  position: relative;
  align-self: flex-start;
  width: 60%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BottomRightContentWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;

export const BottomTotalBonusWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 0.5rem;
  width: 100%;
`;

export const BottomTotalBonusImage = styled.img`
  margin-left: 14px;
  height: ${windowWidth < 1400 ? 25 : 30}px;
  object-fit: contain;
  object-position: 0%;
`;

export const BottomRightCoinImage = styled.img`
  height: ${windowHeight < 900 ? 30 : 40}px;
  width: ${windowHeight < 900 ? 30 : 40}px;
  object-fit: contain;
  ${({ theme }) => theme}
`;

export const BottomRightItemsText = styled.div`
  text-align: center;
  margin-left: ${({ left }) => left || '5px'};
  font-size: 1.8rem;
  font-weight: 600;
`;

export const BottomBarRightPlusText = styled.div`
  color: ${theme.palette.common[1]};
  font-size: 34px;
  margin-left: 20px;
  margin-right: 15px;
`;

export const BottomRightLoyaltyImage = styled(BottomRightCoinImage)`
  height: 50px;
  width: 50px;
`;

export const BottomBarRightSideBottomText = styled.img`
  width: 85%;
  max-width: 600px;
  align-self: flex-end;
  ${({ theme }) => theme}
`;

/**
 * Onboarding
 */

export const OnboardingContainer = styled.div`
  cursor: pointer;
`;

export const OnboardingImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 1em;
`;

export const Styles = {
  wheelSliceContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  wheelSliceContainerInner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  wheelFrameAndTicker: {
    position: 'absolute',
    height: '100%',
    width: '100%',
  },
  spinButtonWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    objectFit: 'contain',
  },
  spinButtonWrapperInner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  skipButton: {
    position: 'absolute',
    right: 16,
    height: 57,
    width: 88,
    pointerEvents: 'all',
    objectFit: 'contain',
    margin: 0,
  },
  skipButtonImage: {
    width: 88,
  },
};

export const StreakColumnStyles = {
  prizeTopper: {
    height: 45,
    zIndex: 1,
  },
  prizeTopperManual: {
    top: -20,
    left: -20,
    right: -20,
    bottom: -40,
  },
  prizeRowHolder: {
    flex: 1,
  },
  prizeRowHolderInner: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  milestoneRibbon: {
    height: '86%',
    alignSelf: 'stretch',
    zIndex: 1,
  },
  milestoneRibbonInner: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  mileStoneRowCoinIcon: {
    height: 24,
    width: 24,
    objectFit: 'contain',
  },
  milestonePlusText: {
    fontSize: 32,
  },
  milestoneCircleImage: {
    position: 'absolute',
    top: windowHeight < 800 ? -10 : -20,
    height: windowHeight < 800 ? 40 : 55,
    left: 0,
    width: '100%',
    zIndex: 1,
  },
  milestoneCircleImageInner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: -15,
  },
  milestoneCircleDayText: {
    fontSize: 20,
  },
  mileStoneRowLoyaltyIcon: {
    height: 40,
    width: 40,
  },
  daysStreakContainer: {
    flex: 1,
    alignSelf: 'stretch',
    margin: '0px 24px',
    display: 'flex',
    flexDirection: 'column-reverse',
    justifyContent: 'space-evenly',
  },
  daysStreakContainerInner: {
    display: 'flex',
    flexDirection: 'column-reverse',
    justifyContent: 'space-evenly',
    alignItems: 'stretch',
  },
  streakDayRowIndicator: {
    height: 30,
    width: 30,
    marginLeft: 5,
    marginRight: 10,
    zIndex: 2,
  },
  streakDayRowIndicatorInner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  regDaysItemLoyaltyIcon: {
    height: 24,
    width: 24,
  },
  rowDivider: {
    width: '100%',
    height: 3,
    zIndex: 10,
  },
};

export const BottomBarStyles = {
  bottomBarImageWrapper: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    top: 0,
    objectFit: 'fill',
  },
  bottomBarImageWrapperInner: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
  },
  bottomRightWrapper: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    top: 10,
    height: '65%',
    width: '95%',
    objectFit: 'fill',
    inset: 'auto',
  },
  bottomRightWrapperInner: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0.5rem 0',
  },
  bottomCollectButton: {
    objectFit: 'contain',
    cursor: 'pointer',
    pointerEvents: 'all',
    width: '35%',
  },
  bottomCollectButtonContainer: {
    width: '35%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '10%',
    marginBottom: 4,
  },
};

