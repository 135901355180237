import React, { Component } from 'react';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import SFavouriteListDialogue from 'shared/screens/FavouriteListDialogue';
import Utils from 'shared/utils';
import BannerSlide from '../../components/PinnedPanel/components/BannerSlide';

export default class FavouriteListDialogue extends Component {
  state = {};

  static get AsModalProps() {
    return {
      dialogClassName: 'gtp-modal-favouriteListDialogue',
    };
  }

  render() {
    return (
      <SFavouriteListDialogue
        navigation={this.props.navigation}
        {...this.props}
        render={args => {
          const { favouriteGames } = args;
          return (
            <div>
              <div className="headerStyle">
                <h1>{getText(TEXT_KEY.FAVOURITES_ARE_FULL)}</h1>
                <h2 style={{ fontSize: '24px' }}> {getText(TEXT_KEY.SELECT_A_GAME_TO_REPLACE)}</h2>
              </div>
              <div className="Live-Panels-Container">
                <div className="live-panel-grid">
                  {!Utils.isEmpty(favouriteGames) &&
                    favouriteGames.map(game => <BannerSlide itemData={[game]} key={game.item_id} />)}
                </div>
              </div>
            </div>
          );
        }}
      />
    );
  }
}

FavouriteListDialogue.hideDefaultBackground = false;
