import _defineProperty from "/var/jenkins/workspace/Web Clients and Images - Production/Code/WEB CLIENT - GTP 2.0 Deploy/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";

var _sendActionCommandsEv;

import { ACTION_COMMANDS_EVENT_TYPE } from '../constants';
import Logger from '../../../lib/analytics/logger';
import { EVENT_ACTIONS, EVENT_DOMAINS, EVENT_SCREENS, EVENT_TYPES, EVENT_WIDGETS } from '../../../lib/analytics/constants';

var sendMetrics = function sendMetrics(event, body) {
  return Logger.sendEvent(event, body);
};

var displayAppModalEvent = function displayAppModalEvent(modalType, _ref, payload) {
  var executionId = _ref.executionId,
      triggerType = _ref.triggerType;
  var isFtueFlow = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
  return sendMetrics(EVENT_TYPES.UI_EVENT, {
    action: EVENT_ACTIONS.DISPLAY,
    domain: modalType,
    screen: EVENT_SCREENS[EVENT_DOMAINS.GENERAL].LOBBY,
    trigger: triggerType,
    executionId: executionId,
    isFtueFlow: isFtueFlow
  });
};

var acceptAppModalEvent = function acceptAppModalEvent(modalType, _ref2, payload) {
  var executionId = _ref2.executionId,
      triggerType = _ref2.triggerType;
  var isFtueFlow = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
  return sendMetrics(EVENT_TYPES.UI_EVENT, {
    action: EVENT_ACTIONS.PRESS,
    domain: modalType,
    screen: EVENT_SCREENS[EVENT_DOMAINS.GENERAL].LOBBY,
    widget: EVENT_WIDGETS[EVENT_DOMAINS.GENERAL].ACCEPT_BUTTON,
    trigger: triggerType,
    executionId: executionId,
    isFtueFlow: isFtueFlow
  });
};

var dismissAppModalEvent = function dismissAppModalEvent(modalType, _ref3, payload) {
  var _ref3$triggerExecutio = _ref3.triggerExecution,
      executionId = _ref3$triggerExecutio.executionId,
      triggerType = _ref3$triggerExecutio.triggerType;
  var isFtueFlow = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
  return sendMetrics(EVENT_TYPES.UI_EVENT, {
    action: EVENT_ACTIONS.PRESS,
    domain: modalType,
    screen: EVENT_SCREENS[EVENT_DOMAINS.GENERAL].LOBBY,
    widget: EVENT_WIDGETS[EVENT_DOMAINS.GENERAL].DISMISS_BUTTON,
    trigger: triggerType,
    executionId: executionId,
    isFtueFlow: isFtueFlow
  });
};

var displayNativeModalEvent = function displayNativeModalEvent(modalType, _ref4) {
  var _ref4$triggerExecutio = _ref4.triggerExecution,
      executionId = _ref4$triggerExecutio.executionId,
      triggerType = _ref4$triggerExecutio.triggerType;
  return sendMetrics(EVENT_TYPES.UI_EVENT, {
    action: EVENT_ACTIONS.DISPLAY,
    domain: EVENT_DOMAINS.ALLOW_NOTIFICATIONS_NATIVE_MODAL,
    screen: EVENT_SCREENS[EVENT_DOMAINS.GENERAL].LOBBY,
    trigger: triggerType,
    executionId: executionId
  });
};

var acceptNativeModalEvent = function acceptNativeModalEvent(modalType, _ref5) {
  var _ref5$triggerExecutio = _ref5.triggerExecution,
      executionId = _ref5$triggerExecutio.executionId,
      triggerType = _ref5$triggerExecutio.triggerType;
  return sendMetrics(EVENT_TYPES.UI_EVENT, {
    action: EVENT_ACTIONS.PRESS,
    domain: EVENT_DOMAINS.ALLOW_NOTIFICATIONS_NATIVE_MODAL,
    screen: EVENT_SCREENS[EVENT_DOMAINS.GENERAL].LOBBY,
    widget: EVENT_WIDGETS[EVENT_DOMAINS.GENERAL].ACCEPT_BUTTON,
    trigger: triggerType,
    executionId: executionId
  });
};

var dismissNativeModalEvent = function dismissNativeModalEvent(modalType, _ref6) {
  var _ref6$triggerExecutio = _ref6.triggerExecution,
      executionId = _ref6$triggerExecutio.executionId,
      triggerType = _ref6$triggerExecutio.triggerType;
  return sendMetrics(EVENT_TYPES.UI_EVENT, {
    action: EVENT_ACTIONS.PRESS,
    domain: EVENT_DOMAINS.ALLOW_NOTIFICATIONS_NATIVE_MODAL,
    screen: EVENT_SCREENS[EVENT_DOMAINS.GENERAL].LOBBY,
    widget: EVENT_WIDGETS[EVENT_DOMAINS.GENERAL].DISMISS_BUTTON,
    trigger: triggerType,
    executionId: executionId
  });
};

var signInEmailAppModalEvent = function signInEmailAppModalEvent(modalType, _ref7) {
  var _ref7$triggerExecutio = _ref7.triggerExecution,
      executionId = _ref7$triggerExecutio.executionId,
      triggerType = _ref7$triggerExecutio.triggerType;
  return sendMetrics(EVENT_TYPES.UI_EVENT, {
    action: EVENT_ACTIONS.PRESS,
    domain: modalType,
    screen: EVENT_SCREENS[EVENT_DOMAINS.GENERAL].LOBBY,
    widget: EVENT_WIDGETS[EVENT_DOMAINS.ACTIONS_COMMANDS].SIGN_IN_EMAIL_BUTTON,
    trigger: triggerType,
    executionId: executionId
  });
};

var signInFacebookAppModalEvent = function signInFacebookAppModalEvent(modalType, _ref8) {
  var _ref8$triggerExecutio = _ref8.triggerExecution,
      executionId = _ref8$triggerExecutio.executionId,
      triggerType = _ref8$triggerExecutio.triggerType;
  return sendMetrics(EVENT_TYPES.UI_EVENT, {
    action: EVENT_ACTIONS.PRESS,
    domain: modalType,
    screen: EVENT_SCREENS[EVENT_DOMAINS.GENERAL].LOBBY,
    widget: EVENT_WIDGETS[EVENT_DOMAINS.ACTIONS_COMMANDS].SIGN_IN_FACEBOOK_BUTTON,
    trigger: triggerType,
    executionId: executionId
  });
};

var signInGoogleAppModalEvent = function signInGoogleAppModalEvent(modalType, _ref9) {
  var _ref9$triggerExecutio = _ref9.triggerExecution,
      executionId = _ref9$triggerExecutio.executionId,
      triggerType = _ref9$triggerExecutio.triggerType;
  return sendMetrics(EVENT_TYPES.UI_EVENT, {
    action: EVENT_ACTIONS.PRESS,
    domain: modalType,
    screen: EVENT_SCREENS[EVENT_DOMAINS.GENERAL].LOBBY,
    widget: EVENT_WIDGETS[EVENT_DOMAINS.ACTIONS_COMMANDS].SIGN_IN_GOOGLE_BUTTON,
    trigger: triggerType,
    executionId: executionId
  });
};

var signInAppleAppModalEvent = function signInAppleAppModalEvent(modalType, _ref10) {
  var _ref10$triggerExecuti = _ref10.triggerExecution,
      executionId = _ref10$triggerExecuti.executionId,
      triggerType = _ref10$triggerExecuti.triggerType;
  return sendMetrics(EVENT_TYPES.UI_EVENT, {
    action: EVENT_ACTIONS.PRESS,
    domain: modalType,
    screen: EVENT_SCREENS[EVENT_DOMAINS.GENERAL].LOBBY,
    widget: EVENT_WIDGETS[EVENT_DOMAINS.ACTIONS_COMMANDS].SIGN_IN_APPLE_BUTTON,
    trigger: triggerType,
    executionId: executionId
  });
};

var displayAppTooltipEvent = function displayAppTooltipEvent(tooltipType, _ref11, payload) {
  var executionId = _ref11.executionId,
      triggerType = _ref11.triggerType;
  var isFtueFlow = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
  sendMetrics(EVENT_TYPES.UI_EVENT, {
    action: EVENT_ACTIONS.DISPLAY,
    domain: tooltipType,
    screen: EVENT_SCREENS[EVENT_DOMAINS.GENERAL].LOBBY,
    widget: EVENT_WIDGETS[EVENT_DOMAINS.ACTIONS_COMMANDS].ACTIONS_COMMANDS_TOOLTIP,
    trigger: triggerType,
    executionId: executionId,
    isFtueFlow: isFtueFlow
  });
};

export var sendActionCommandsEvent = (_sendActionCommandsEv = {}, _defineProperty(_sendActionCommandsEv, ACTION_COMMANDS_EVENT_TYPE.DISPLAY_APP_MODAL, displayAppModalEvent), _defineProperty(_sendActionCommandsEv, ACTION_COMMANDS_EVENT_TYPE.ACCEPT_APP_MODAL, acceptAppModalEvent), _defineProperty(_sendActionCommandsEv, ACTION_COMMANDS_EVENT_TYPE.DISMISS_APP_MODAL, dismissAppModalEvent), _defineProperty(_sendActionCommandsEv, ACTION_COMMANDS_EVENT_TYPE.DISPLAY_NATIVE_MODAL, displayNativeModalEvent), _defineProperty(_sendActionCommandsEv, ACTION_COMMANDS_EVENT_TYPE.ACCEPT_NATIVE_MODAL, acceptNativeModalEvent), _defineProperty(_sendActionCommandsEv, ACTION_COMMANDS_EVENT_TYPE.DISMISS_NATIVE_MODAL, dismissNativeModalEvent), _defineProperty(_sendActionCommandsEv, ACTION_COMMANDS_EVENT_TYPE.SIGN_IN_EMAIL_APP_MODAL, signInEmailAppModalEvent), _defineProperty(_sendActionCommandsEv, ACTION_COMMANDS_EVENT_TYPE.SIGN_IN_FACEBOOK_APP_MODAL, signInFacebookAppModalEvent), _defineProperty(_sendActionCommandsEv, ACTION_COMMANDS_EVENT_TYPE.SIGN_IN_GOOGLE_APP_MODAL, signInGoogleAppModalEvent), _defineProperty(_sendActionCommandsEv, ACTION_COMMANDS_EVENT_TYPE.SIGN_IN_APPLE_APP_MODAL, signInAppleAppModalEvent), _defineProperty(_sendActionCommandsEv, ACTION_COMMANDS_EVENT_TYPE.DISPLAY_APP_TOOLTIP, displayAppTooltipEvent), _sendActionCommandsEv);