import React, { Component } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import FormValidator from 'shared/components/FormValidator';
import Validators from 'shared/components/Form/InputValidator';
import AutoFormInput from '../../components/AutoFormInput';
import FormType from 'shared/components/Form/FormType';
import FormId from 'shared/components/Form/FormId';
import theme from 'shared/assets/style/theme';
import store from 'shared/state/store';
import { setCardNickname } from 'shared/state/actions/prePurchase';
import { PaymentProviders } from 'shared/utils/paymentProviders';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import styled from 'styled-components';
import ThemeContext from 'shared/context/ThemeContext';
import Button from '../../components/Button/Button';
import { BUTTON_STROKES } from 'shared/utils/buttonTextStrokes';

export default class CreditCardDetails extends Component {
  state = {
    saveCard: false,
    showTooltip: false,
    cardNickname: 'visa',
    isSavedName: false,
    buyButtonHasBeenClicked: false,
  };

  _hoverHandler = event => {
    this.setState({
      showTooltip: true,
    });
  };

  _leaveHandler = event => {
    this.setState({
      showTooltip: false,
    });
  };

  onValidationFailed = nextHandle => {
    nextHandle(false);
  };

  componentDidMount() {
    store.dispatch(setCardNickname(null));
  }

  saveNickName = data => {
    const storedNickName = store.getState().prePurchase.cardNickname;
    if (data && data.saveCard && data.cardNickname && storedNickName !== data.cardNickname) {
      store.dispatch(setCardNickname(data.cardNickname));
    }
  };

  getDropdown = (controlId, formId, validators, formType, label, options, isValid, newDataChangeHandle, name) => {
    return (
      <>
        <AutoFormInput
          controlId={controlId}
          id={formId}
          validator={validators}
          type={formType}
          keyboardHint={''}
          label={label}
          options={options}
          validated={isValid}
          emitInput={newDataChangeHandle}
          name={name}
        />
        <img className="downIcon" src={require(asset`caret_down.png`)} alt="caret_down" />
      </>
    );
  };

  getFormField = (controlId, formId, validators, formType, label, isValid, newDataChangeHandle, maxChar, name) => {
    const trimInput = controlId === FormId.CARD_NUMBER || controlId === FormId.CARD_CVV;
    return (
      <AutoFormInput
        controlId={controlId}
        id={formId}
        validator={validators}
        type={formType}
        keyboardHint={''}
        label={label}
        validated={isValid}
        emitInput={newDataChangeHandle}
        maxChar={maxChar}
        name={name}
        trimInput={trimInput}
      />
    );
  };

  handleBuyButtonClick = submitHandle => {
    if (this.buyButtonHasBeenClicked) {
      return;
    } else {
      this.setState({ buyButtonHasBeenClicked: true });
      submitHandle();
    }
  };

  toggleSaveCard = data => {
    this.setState({ saveCard: !this.state.saveCard }, () => {
      if (this.state.saveCard) {
        data[FormId.SAVE_CARD] = true;
      } else {
        data[FormId.SAVE_CARD] = '';
      }
    });
  };

  render() {
    const themeContext = this.context;
    const {
      nextHandle,
      purchaseType,
      promoReward,
      getPurchaseStrings,
      hasOffer,
      renderTotal,
      onSubmit,
      packageData,
      termsAndConditionsUrl,
      formUrl,
      accessCode,
      paymentProvider,
    } = this.props;

    const primaryButtonSrc = require(asset`RoundedRect_Primary@3x.png`);
    const disabledButtonSrc = require(asset`RoundedRect_Disabled@3x.png`);
    if (packageData === null) return null;

    let { purchaseNameString, purchasePriceString } = getPurchaseStrings(purchaseType, packageData, hasOffer);

    var months = [{ value: 'mm', label: getText(TEXT_KEY.CREDIT_CARD_MONTH) }];
    for (let i = 1; i <= 12; i++) {
      months.push({ value: i.toString(), label: i });
    }

    var years = [{ value: 'yy', label: getText(TEXT_KEY.CREDIT_CARD_YEAR) }];
    for (let i = 0; i < 20; i++) {
      var yr = (new Date().getFullYear() + i) % 100;
      years.push({ value: yr, label: yr });
    }

    const cardOptions = [
      {
        value: 'visa',
        label: 'VISA',
      },
      {
        value: 'mastercard',
        label: 'MasterCard',
      },
      {
        value: 'amex',
        label: 'AMEX',
      },
    ];

    return (
      <FormValidator
        schema={{
          [FormId.CARD_TYPE]: Validators.NOT_EMPTY,
          [FormId.CARD_HOLDER_NAME]:
            paymentProvider === PaymentProviders.EWAY ? Validators.NOT_EMPTY : Validators.NO_VALIDATION,
          [FormId.CARD_NUMBER]: Validators.CREDIT_CARD,
          [FormId.CARD_EXPIRY_MONTH]: Validators.NUMBERS_ONLY,
          [FormId.CARD_EXPIRY_YEAR]: Validators.NUMBERS_ONLY,
          [FormId.CARD_CVV]: Validators.CVV,
          [FormId.CARD_NICKNAME]: (value, data) => {
            if (data[FormId.SAVE_CARD]) {
              return Validators.NOT_EMPTY(value);
            }
            return Validators.NO_VALIDATION(value);
          },
          [FormId.SAVE_CARD]: Validators.NO_VALIDATION,
          [FormId.PROMO_CODE]: Validators.NO_VALIDATION,
        }}
        key="billing-form-2"
        onSubmit={validatedData => onSubmit(nextHandle, validatedData)}
        onValidationFailed={() => this.onValidationFailed(nextHandle)}
        render={({ data, valid, complete, newDataChangeHandle, submitHandle }) => (
          <Form
            className="form-container credit-card-details"
            method={paymentProvider === PaymentProviders.EWAY ? 'POST' : ''}
            action={paymentProvider === PaymentProviders.EWAY ? formUrl : ''}
            onSubmit={paymentProvider === PaymentProviders.EWAY ? this.saveNickName(data) : submitHandle}
            id="payment_form"
          >
            {paymentProvider === PaymentProviders.EWAY && (
              <>
                <input type="hidden" name="EWAY_ACCESSCODE" value={accessCode} />
                <input type="hidden" name="EWAY_PAYMENTTYPE" value="Credit Card" />
              </>
            )}
            <Row>
              <Col>
                {paymentProvider !== PaymentProviders.EWAY && (
                  <div className="cardType" onChange={e => this.setState({ cardNickname: e.target.value })}>
                    {this.getDropdown(
                      FormId.CARD_TYPE,
                      FormId.CARD_TYPE,
                      Validators.NO_VALIDATION,
                      FormType.DROPDOWN,
                      getText(TEXT_KEY.CARD_TYPE),
                      cardOptions,
                      valid[FormId.CARD_TYPE],
                      newDataChangeHandle
                    )}
                  </div>
                )}
              </Col>
            </Row>
            <FormRow>
              <Col>
                {paymentProvider === PaymentProviders.EWAY &&
                  this.getFormField(
                    FormId.CARD_HOLDER_NAME,
                    FormId.CARD_HOLDER_NAME,
                    Validators.NOT_EMPTY,
                    FormType.TEXT,
                    getText(TEXT_KEY.CARD_HOLDER_NAME),
                    valid[FormId.CARD_HOLDER_NAME],
                    newDataChangeHandle,
                    100,
                    'EWAY_CARDNAME'
                  )}
              </Col>
            </FormRow>
            <FormRow>
              <Col>
                {this.getFormField(
                  FormId.CARD_NUMBER,
                  FormId.CARD_NUMBER,
                  Validators.CREDIT_CARD,
                  FormType.TEXT,
                  getText(TEXT_KEY.CREDIT_CARD_NUMBER),
                  valid[FormId.CARD_NUMBER],
                  newDataChangeHandle,
                  16,
                  paymentProvider === PaymentProviders.EWAY ? 'EWAY_CARDNUMBER' : ''
                )}
              </Col>
            </FormRow>
            <FormRow>
              <Col>
                <div className="expiryMonth">
                  {paymentProvider === PaymentProviders.EWAY
                    ? this.getFormField(
                        FormId.CARD_EXPIRY_MONTH,
                        FormId.CARD_EXPIRY_MONTH,
                        Validators.NUMBERS_ONLY,
                        FormType.TEXT,
                        getText(TEXT_KEY.EXPIRY_MONTH),
                        valid[FormId.CARD_EXPIRY_MONTH],
                        newDataChangeHandle,
                        2,
                        'EWAY_CARDEXPIRYMONTH'
                      )
                    : this.getDropdown(
                        FormId.CARD_EXPIRY_MONTH,
                        FormId.CARD_EXPIRY_MONTH,
                        Validators.NUMBERS_ONLY,
                        FormType.DROPDOWN,
                        getText(TEXT_KEY.EXPIRY_MONTH),
                        months,
                        valid[FormId.CARD_EXPIRY_MONTH],
                        newDataChangeHandle
                      )}
                </div>
              </Col>
              <Col>
                <div className="expiryYear">
                  {paymentProvider === PaymentProviders.EWAY
                    ? this.getFormField(
                        FormId.CARD_EXPIRY_YEAR,
                        FormId.CARD_EXPIRY_YEAR,
                        Validators.NUMBERS_ONLY,
                        FormType.TEXT,
                        getText(TEXT_KEY.EXPIRY_YEAR),
                        valid[FormId.CARD_EXPIRY_YEAR],
                        newDataChangeHandle,
                        2,
                        'EWAY_CARDEXPIRYYEAR'
                      )
                    : this.getDropdown(
                        FormId.CARD_EXPIRY_YEAR,
                        FormId.CARD_EXPIRY_YEAR,
                        Validators.NUMBERS_ONLY,
                        FormType.DROPDOWN,
                        getText(TEXT_KEY.EXPIRY_YEAR),
                        years,
                        valid[FormId.CARD_EXPIRY_YEAR],
                        newDataChangeHandle
                      )}
                </div>
              </Col>
              <FormCvvColumn>
                <div className="ccvForm">
                  {paymentProvider === PaymentProviders.EWAY
                    ? this.getFormField(
                        FormId.CARD_CVV,
                        FormId.CARD_CVV,
                        Validators.CVV,
                        FormType.TEXT,
                        getText(TEXT_KEY.CVV),
                        valid[FormId.CARD_CVV],
                        newDataChangeHandle,
                        4,
                        'EWAY_CARDCVN'
                      )
                    : this.getFormField(
                        FormId.CARD_CVV,
                        FormId.CARD_CVV,
                        Validators.CVV,
                        FormType.TEXT,
                        getText(TEXT_KEY.CVV),
                        valid[FormId.CARD_CVV],
                        newDataChangeHandle,
                        4
                      )}
                  <div
                    className="questionIconContainer"
                    onMouseEnter={this._hoverHandler.bind(this)}
                    onMouseLeave={this._leaveHandler}
                  >
                    <img className="questionIcon" src={require(asset`Icon_Question@2x.png`)} alt="Question Icon" />
                  </div>
                </div>
              </FormCvvColumn>
            </FormRow>
            <div className="cvvTooltip">
              {this.state.showTooltip && (
                <div>
                  <CvvHelpContainer />
                  <div className="cvvTooltipText">
                    <span className="cvvTooltipTitle">{getText(TEXT_KEY.CVV_NUMBER)}</span>
                    <br />
                    <span className="cvvTooltipDescription">{getText(TEXT_KEY.CVV_OUTLINED_IN_GREEN)}</span>
                    <div className="cvv_credit_mc">
                      <CvvHelpImage src={require(asset`VISA_MC@3x.png`)} alt="visa_mastercard" />
                    </div>
                  </div>
                </div>
              )}
            </div>
            <Row>
              <SaveCardCheckBoxHolder>
                <div className="saveCard">
                  {this.state.saveCard ? (
                    <CheckBoxContainer
                      onClick={() => {
                        this.toggleSaveCard(data);
                      }}
                    >
                      <img
                        style={themeContext.CreditCardDetails.CheckBoxBackgroundImage}
                        className="checkBox"
                        src={require(asset`Checkbox@3x.png`)}
                        alt="Checkbox"
                      />
                    </CheckBoxContainer>
                  ) : (
                    <CheckBoxContainer
                      onClick={() => {
                        this.toggleSaveCard(data);
                      }}
                    >
                      <img
                        style={themeContext.CreditCardDetails.CheckBoxBackgroundImage}
                        className="checkBox"
                        src={require(asset`Checkbox_Deselected@3x.png`)}
                        alt="Checkbox"
                      />
                    </CheckBoxContainer>
                  )}
                  <AutoFormInput
                    controlId={FormId.SAVE_CARD}
                    id={FormId.SAVE_CARD}
                    validator={Validators.NO_VALIDATION}
                    type={FormType.CHECKBOX}
                    keyboardHint={''}
                    label={getText(TEXT_KEY.PAYMENT_INFO_SAVE_YOUR_CARD)}
                    validated={valid[FormId.SAVE_CARD]}
                    emitInput={newDataChangeHandle}
                    focused
                  />
                </div>
              </SaveCardCheckBoxHolder>
            </Row>
            <SavedCardRow state={this.state.saveCard}>
              <Col>
                <AutoFormInput
                  controlId={FormId.CARD_NICKNAME}
                  id={FormId.CARD_NICKNAME}
                  validator={Validators.NO_VALIDATION}
                  type={FormType.TEXT}
                  keyboardHint={''}
                  label={getText(TEXT_KEY.CARD_NICKNAME)}
                  initialValue={`${getText(TEXT_KEY.MY)} ` + this.state.cardNickname.toUpperCase()}
                  validated={valid[FormId.CARD_NICKNAME]}
                  emitInput={newDataChangeHandle}
                  maxChar={100}
                />
              </Col>
            </SavedCardRow>
            {renderTotal(purchaseNameString, purchasePriceString, promoReward)}
            <BuyButtonWrapper>
              <Button
                imageSource={complete ? primaryButtonSrc : disabledButtonSrc}
                textStyle={themeContext.CreditCardDetails.BuyNowButton}
                label={getText(TEXT_KEY.BUY_NOW)}
                onClick={complete ? () => this.handleBuyButtonClick(submitHandle) : () => {}}
                disabled={!complete}
                textStroke={BUTTON_STROKES.PRIMARY}
              />
            </BuyButtonWrapper>
            <TermsTexContainer>
              <TermsTextLink target="_blank" href={termsAndConditionsUrl}>
                {getText(TEXT_KEY.TERMS_AND_CONDITIONS_IAP)} {getText(TEXT_KEY.APPLY)}
              </TermsTextLink>
            </TermsTexContainer>
          </Form>
        )}
      />
    );
  }
}

CreditCardDetails.contextType = ThemeContext;

const CvvHelpContainer = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  background-image: url(${require(asset`Pointerbox.png`)});
  background-size: 100% 100%;
  opacity: 1;
  transform: rotate(180deg);
  z-index: 12;
  filter: drop-shadow(${theme.palette.common[16]} 0px 0px 2px);
  border-radius: 0.5em;
`;

const CvvHelpImage = styled.img`
  width: 90%;
`;

const CheckBoxContainer = styled.div`
  cursor: pointer;
  display: inline-block;
`;

const FormRow = styled(Row)`
  margin-top: -0.5em;
`;

const FormCvvColumn = styled(Col)`
  flex-grow: 1.5;
`;

const SaveCardCheckBoxHolder = styled(Col)`
  text-align: center;
`;

const SavedCardRow = styled(Row)`
  margin-top: -2rem;
  visibility: ${({ state }) => (state ? 'visible' : 'hidden')};
`;

const TermsTexContainer = styled(Row)`
  bottom: -4%;
  justify-content: center;
`;

const TermsTextLink = styled.a`
  textdecoration: underline;
  color: ${theme.palette.primary[9]};
  cursor: pointer;
`;

const BuyButtonWrapper = styled(Row)`
  justify-content: center;
  align-items: center;
  padding: 1rem;
`;
