import React from 'react';
import ImageBW from '../../../../../../components/ImageBoundsWrapper';
import Button from '../../../../../../components/Button/Button';
import Modal from '../../../../../../components/JSModal';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import {
  KycPopUpContainer,
  KycContentWrapper,
  KycModalCloseWrapper,
  KycCheckTextWrapper,
  KycRewardMessageWrapper,
  KycRewardMessageStyle,
  KycContinueButtonWrapper,
  kycConsentPopupStyles,
} from './styledComponents';

const KycConsentPopUp = ({ onKycCheck, hideKycPopUp, showKycPopUp }) => {
  return (
    <Modal isVisible={showKycPopUp} onBackButtonPress={hideKycPopUp}>
      <KycPopUpContainer>
        <KycContentWrapper>
          <ImageBW
            style={{ height: '100%', width: '100%' }}
            source={require(asset`Modal_Backing_9Slice.png`)}
            ibw={require(asset`Modal_Backing_9Slice.ibw`)}
            manualAssetScale={0.5}
          >
            <KycModalCloseWrapper onClick={hideKycPopUp}>
              <ImageBW
                source={require(asset`CloseButton.png`)}
                ibw={require(asset`CloseButton.ibw`)}
                manualAssetScale={3}
                style={{ objectFit: 'contain', height: '100%', width: '100%' }}
                alt="Close"
              />
            </KycModalCloseWrapper>
            <KycCheckTextWrapper>
              <span style={kycConsentPopupStyles.kycCheckTextStyle}>{getText(TEXT_KEY.KYC_CHECK)}</span>
            </KycCheckTextWrapper>
            <KycRewardMessageWrapper>
              <ImageBW
                source={require(asset`Shop_VIP_Divider_Glows.png`)}
                ibw={require(asset`Shop_VIP_Divider_Glows.ibw`)}
                style={{ height: '50%', width: '100%', marginTop: '2%' }}
              />
              <KycRewardMessageStyle>{getText(TEXT_KEY.REWARD_CENTER_KYC_MESSAGE)}</KycRewardMessageStyle>
            </KycRewardMessageWrapper>
            <KycContinueButtonWrapper>
              <Button
                imageSource={require(asset`RoundedRect_Primary.png`)}
                imageStyle={{ width: '150px', height: '50px' }}
                onClick={onKycCheck}
                label={getText(TEXT_KEY.CONTINUE_BUTTON)}
              />
            </KycContinueButtonWrapper>
          </ImageBW>
        </KycContentWrapper>
      </KycPopUpContainer>
    </Modal>
  );
};

export default KycConsentPopUp;

