import React, { useCallback, useRef, useEffect } from 'react';
export default function useSlideFadeAnimation(_ref) {
  var hideItem = _ref.hideItem,
      Animated = _ref.Animated,
      Easing = _ref.Easing;
  var showItemOpacity = useRef(new Animated.Value(hideItem ? 0 : 1)).current;
  var runAnimation = useCallback(function () {
    Animated.timing(showItemOpacity, {
      toValue: hideItem ? 0 : 1,
      duration: 800,
      easing: Easing.linear,
      useNativeDriver: true
    }).start(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hideItem, showItemOpacity]);
  useEffect(function () {
    runAnimation();
  }, [runAnimation]);
  return {
    showItemOpacity: showItemOpacity
  };
}