import React, { useCallback, useEffect, useRef, useState } from 'react';
function useShowScrollByDefault({ showByDefault, timeout, showVertical, showHorizontal }) {
  const scrollBoxRef = useRef();
  const [scrollBarWidth, changeScrollBarWidth] = useState(0);
  const [scrollBarXHeight, changeScrollBarXHeight] = useState(0);

  const onScroll = () => {
    if (scrollBarWidth === 0 && showVertical) changeScrollBarWidth(0.5);
    if (scrollBarXHeight === 0 && showHorizontal) changeScrollBarXHeight(10);
  };

  const checkToShowScrollBar = useCallback(() => {
    if (showByDefault) {
      setTimeout(() => {
        const scrollHeight = scrollBoxRef.current?.getScrollHeight();
        const clientHeight = scrollBoxRef.current?.getClientHeight();
        const scrollWidth = scrollBoxRef.current?.getScrollWidth();
        const clientWidth = scrollBoxRef.current?.getClientWidth();
        if (scrollHeight > clientHeight && scrollBarWidth === 0 && showVertical) changeScrollBarWidth(0.5);
        if (scrollWidth - clientWidth > 50 && scrollBarXHeight === 0 && showHorizontal) changeScrollBarXHeight(10);
      }, [timeout]);
    }
  }, [showByDefault, timeout]);

  useEffect(() => {
    checkToShowScrollBar();
  }, [checkToShowScrollBar]);

  return {
    scrollBoxRef,
    scrollBarWidth,
    scrollBarXHeight,
    onScroll,
  };
}

export default useShowScrollByDefault;

