import WelcomeBonus from './WelcomeBonus';
import { connect } from '../../node_modules/react-redux';
import { addCoins as _addCoins } from '../../state/actions/extras';
import { setIsWelcomeBonusConsumed as _setIsWelcomeBonusConsumed } from '../../state/actions/consumables';
import screens from '..';

var mapStateToProps = function mapStateToProps(state) {
  return {
    welcomeBonus: state.consumables.welcomeBonus,
    shouldCollectWelcomeBonus: !state.consumables.isWelcomeBonusConsumed && !state.load.initAppResponse.TUTORIAL,
    actionCommands: state.actionCommands.actions.find(function (action) {
      var _action$payload;

      return (action === null || action === void 0 ? void 0 : (_action$payload = action.payload) === null || _action$payload === void 0 ? void 0 : _action$payload.screen) === screens.Home;
    })
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    addCoins: function addCoins(value) {
      dispatch(_addCoins(value));
    },
    setIsWelcomeBonusConsumed: function setIsWelcomeBonusConsumed(value) {
      dispatch(_setIsWelcomeBonusConsumed(value));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WelcomeBonus);