import React from 'react';
import { setAuthCallbackIntent } from 'shared/state/actions/callbackIntent';
import { useSelector } from 'shared/node_modules/react-redux';
import UnityLinkAccount from './UnityLinkAccount';
import DefaultLinkAccount from './DefaultLinkAccount';

const LinkAccount = () => {
  const playerPropertyLoyaltyCardType = useSelector(state => state.properties?.data?.playerPropertyLoyaltyCardType);
  const LinkAccount =
    {
      default: DefaultLinkAccount,
      unity: UnityLinkAccount,
    }[playerPropertyLoyaltyCardType] || DefaultLinkAccount;

  return <LinkAccount setAuthCallbackIntent={setAuthCallbackIntent} />;
};

LinkAccount.AsModalProps = {
  dialogClassName: 'gtp-modal-link-account',
};

export default LinkAccount;

