import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import theme from 'shared/assets/style/theme';
import styled from 'styled-components';
import useShowScrollByDefault from './hooks/useShowScrollByDefault';
import useDragToScroll from './hooks/useDragToScroll';

export default function CustomScrollBar({
  customHeight = '460px',
  children,
  showByDefault,
  timeout = 0,
  customeStyle = {},
  customHorizontalTrackStyle = {},
  showHorizontal = false,
  showVertical = true,
  isDraggableX = false,
  isDraggableY = false,
}) {
  const { onScroll, scrollBarWidth, scrollBarXHeight, scrollBoxRef } = useShowScrollByDefault({
    showByDefault,
    timeout,
    showVertical,
    showHorizontal,
  });
  useDragToScroll({ scrollBoxRef, isDraggableX, isDraggableY });

  return (
    <Scrollbars
      ref={scrollBoxRef}
      style={{ height: `calc(100vh - ${customHeight})`, marginRight: '-2px' }}
      onScroll={onScroll}
      renderThumbVertical={props => (
        <VerticalScrollThumb {...props} width={scrollBarWidth} display={showVertical && scrollBarWidth > 0} />
      )}
      renderTrackVertical={props => (
        <VerticalScrollTrack {...props} width={scrollBarWidth} display={showVertical && scrollBarWidth > 0} />
      )}
      renderView={props => <CustomRenderView {...props} customeStyle={customeStyle} />}
      renderThumbHorizontal={props => (
        <HorizontalScrollThumb {...props} height={scrollBarXHeight} display={showHorizontal} />
      )}
      renderTrackHorizontal={props => (
        <HorizontalScrollTrack
          {...props}
          height={scrollBarXHeight}
          display={showHorizontal}
          customTrackStyle={customHorizontalTrackStyle}
        />
      )}
    >
      {children}
    </Scrollbars>
  );
}

const CustomRenderView = styled.div`
  ${({ customeStyle }) => customeStyle}
`;

const VerticalScrollTrack = styled.div`
  background-color: ${theme.palette.primary[24]};
  width: ${({ width }) => width}rem;
  top: 8px;
  right: 0px;
  bottom: 8px;
  border-radius: 8px;
  position: absolute;
  display: ${({ display }) => (display ? 'block' : 'none')};
`;

const VerticalScrollThumb = styled.div`
  background-color: ${theme.palette.primary[25]};
  width: ${({ width }) => width}rem;
  cursor: pointer;
  border-radius: 8px;
  position: relative;
  display: ${({ display }) => (display ? 'block' : 'none')};
`;

const HorizontalScrollTrack = styled.div`
  background-color: ${theme.palette.primary[24]};
  width: 97%;
  left: 14px;
  bottom: 3vh;
  border-radius: 8px;
  height: ${({ height }) => height}px !important;
  position: absolute;
  display: ${({ display }) => (display ? 'block' : 'none')};

  @media only screen and (max-height: 700px) {
    bottom: 5vh;
  }
  ${({ customTrackStyle }) => customTrackStyle};
`;

const HorizontalScrollThumb = styled.div`
  background-color: ${theme.palette.primary[25]};
  height: ${({ height }) => height}px !important;
  cursor: pointer;
  border-radius: 8px;
  position: relative;
  display: ${({ display }) => (display ? 'block' : 'none')};
`;

