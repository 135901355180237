import React, { Component } from 'react';
import styled from 'styled-components';
import GamePage from '../../components/GamePage';
import SFavourites from 'shared/screens/Favourites';
import Utils from 'shared/utils';
import audio from 'shared/utils/audio';
import ThemeContext from 'shared/context/ThemeContext';
import { GameListWrapper } from '../Home/styledComponents';

export default class Favourites extends Component {
  slider = React.createRef();

  handleNextClick = () => {
    audio.onClick();
    this.slider.current.slickNext();
  };

  handlePrevClick = () => {
    audio.onClick();
    this.slider.current.slickPrev();
  };

  handleFirstClick = () => {
    audio.onClick();
    this.slider.current.slickGoTo(0);
  };

  handleLastClick = pagesCount => {
    audio.onClick();
    this.slider.current.slickGoTo(pagesCount);
  };

  render() {
    const themeContext = this.context;
    return (
      <SFavourites
        navigation={this.props.navigation}
        {...this.props}
        render={args => {
          const { pagedGames, lobbyPromotions } = args;
          const hasPromotions = !Utils.isEmpty(lobbyPromotions);
          return (
            <div className="roomPage">
              <div className="roomContent">
                <div className="NavigationArrowsContainer left">
                  <img
                    src={require(asset`images/Lobby_Assets/Scroll_Buttons@3x.png`)}
                    className="LeftNavigationArrows"
                    alt="Left navigation arrows"
                  />
                  <div onClick={this.handlePrevClick} className="NavigationArrowTop" />
                  <div onClick={this.handleFirstClick} className="NavigationArrowBottom" />
                </div>
                <GameListWrapper banner={hasPromotions}>
                  {Utils.isEmpty(pagedGames) && (
                    <div
                      className={`GameListContainer ${!hasPromotions ? 'no-margin' : ''}`}
                      style={themeContext.Favourites.FavouritesImageContainer}
                    >
                      <EmptyFavoritesImage
                        src={require(asset`images/Empty_favorites_Message/Empty_Info@3x.png`)}
                        alt="Empty Favourites Message"
                        banner={hasPromotions}
                      />
                    </div>
                  )}
                  {!Utils.isEmpty(pagedGames) && (
                    <div className={`GameListContainer ${!hasPromotions ? 'no-margin' : ''}`}>
                      <GamePage {...args} sliderRef={this.slider} navigation={this.props.navigation} />
                    </div>
                  )}
                </GameListWrapper>
                <div className="NavigationArrowsContainer right">
                  <img
                    src={require(asset`images/Lobby_Assets/Scroll_Buttons@3x.png`)}
                    className="RightNavigationArrows"
                    alt="Right navigation arrows"
                  />
                  <div onClick={this.handleNextClick} className="NavigationArrowTop" />
                  <div onClick={() => this.handleLastClick(pagedGames.length - 1)} className="NavigationArrowBottom" />
                </div>
              </div>
            </div>
          );
        }}
      />
    );
  }
}

Favourites.contextType = ThemeContext;

const EmptyFavoritesImage = styled.img`
  height: 100%;
  margin-right: ${({ banner }) => (banner ? '10%' : '0px')};
`;
