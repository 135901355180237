import styled from 'styled-components';
import theme from 'shared/assets/style/theme';
import { height as BotHeight } from 'shared/components/BottomBar/sizes';
import generateRender from 'shared/utils/generateRender';

export const BlackOverlay = styled.div`
  ${props => `opacity: ${props.isVisible ? `1` : `0`};`}
  ${props => `display: ${props.isVisible ? `block` : `none`};`}
  z-index: 202;
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  transition: opacity 500ms ease-out;
`;

export const DefaultPinnedPanelContainer = styled.div`
  width: 250px;
  z-index: 200;
  display: flex;
  margin-left: 8rem;
  position: absolute;
  justify-content: center;
  align-items: center;
  height: ${({ slides }) => (slides > 1 ? 'calc(100% - 6.5rem)' : 'calc(100% - 5rem)')};

  @media only screen and (min-width: 1500px) {
    margin-left: 9.5rem;
    width: 280px;
  }

  @media only screen and (min-width: 1800px) {
    margin-left: 12rem;
    width: 340px;
  }

  @media only screen and (min-width: 2100px) {
    margin-left: 22rem;
  }

  @media only screen and (max-height: 790px) {
    margin-left: 8.4rem;
    width: 230px;
    height: ${({ slides }) => (slides > 1 ? 'calc(100% - 8rem)' : 'calc(100% - 5rem)')};
  }
`;

export const AdmiralPinnedPanelContainer = styled.div`
  width: 250px;
  z-index: 200;
  display: flex;
  margin-left: 8rem;
  position: absolute;
  justify-content: center;
  align-items: center;
  height: ${({ slides }) => (slides > 1 ? 'calc(93% - 6.5rem)' : 'calc(93% - 5rem)')};

  @media only screen and (min-width: 1500px) {
    margin-left: 9.5rem;
    width: 280px;
  }

  @media only screen and (min-width: 1800px) {
    margin-left: 12rem;
    width: 340px;
  }

  @media only screen and (min-width: 2100px) {
    margin-left: 22rem;
  }

  @media only screen and (max-height: 790px) {
    margin-left: 8.4rem;
    width: ${({ slides }) => (slides > 1 ? '220px' : '240px')};
    height: ${({ slides }) => (slides > 1 ? 'calc(93% - 9rem)' : 'calc(93% - 5rem)')};
  }
`;

export const PinnedPanelContainer = generateRender({
  default: DefaultPinnedPanelContainer,
  admes: AdmiralPinnedPanelContainer,
  admiral: AdmiralPinnedPanelContainer,
});

export const MainSceneContainer = styled.div`
  ${({ theme }) => theme}
  padding-bottom: ${({ bottomState }) => (bottomState ? '0px' : BotHeight)};
`;

export const ModalFooterText = styled.p`
  z-index: 100;
  margin-bottom: -24px;
  width: 100%;
  text-align: center;
  font-weight: 600;
  color: ${theme.palette.common[29]};
`;

