import styled from 'styled-components';
import ImageBW from '../../../../../../components/ImageBoundsWrapper';
import Button from '../../../../../../components/Button/Button';

const HistoryDetailContainer = styled.div`
  width: 100%;
  height: 98%;
`;

const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const BarCodeContainer = styled.div`
  display: flex;
  margin-top: 2rem;
`;

const RemainingBalanceContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  left 3rem;
`;

const DividerImage = styled(ImageBW).attrs(() => ({
  innerStyle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    paddingLeft: '1vmin',
    paddingRight: '1vmin',
  },
}))`
  height: 1.5vmin;
  margin-top: 1vmin;
`;

const SendEmailButton = styled(Button).attrs(() => ({
  imageStyle: { width: '50%' },
  containerStyle: {
    position: 'relative',
    textAlign: 'center',
    cursor: 'pointer',
    pointerEvents: 'auto',
    width: '23em',
    height: '3.5em',
    flex: 1,
    left: '-6em',
    marginTop: '1.8em',
    marginBottom: '1.5em',
  },
  textStyle: {
    position: 'absolute',
    textAlign: 'center',
    textTransform: 'uppercase',
    top: '50%',
    left: '50%',
    width: '100%',
    padding: '1em',
    fontWeight: 500,
    fontSize: 'calc(14px + (22 - 14) * ((100vw - 800px) / (1600 - 800)))',
  },
}))``;

export {
  HistoryDetailContainer,
  ContentContainer,
  BarCodeContainer,
  RemainingBalanceContainer,
  DividerImage,
  SendEmailButton,
};

