import _toConsumableArray from "/var/jenkins/workspace/Web Clients and Images - Production/Code/WEB CLIENT - GTP 2.0 Deploy/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import { persistStore } from '../node_modules/redux-persist';
import reactotron from '../utils/reactotron';
import { pReducer } from './rootReducer';
import rootSaga from './sagas';
import createSagaMiddleware from '../node_modules/redux-saga';
import { configureStore, getDefaultMiddleware } from '../node_modules/@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import { missionsMiddleware } from './query/missions';
import { assetsPackageMiddleware } from './query/assetsPackage';
import { linkGuestAccountMiddleware } from './query/linkGuestAccount';
import { purchaseMiddleware } from './query/purchase';
import { restartSessionMiddleware } from './query/restartSession';
import { urlsMiddleware } from './query/urls';
import { loginMiddleware } from './query/login';
import { signupMiddleware } from './query/signup';
import { oneTimePasswordMiddleware } from './query/oneTimePassword';
import { rewardCenterMiddleware } from './query/rewardCenter';
import { textsMiddleware } from './query/texts';
import { dailyWheelMiddleware } from './query/dailyWheel';
import { gamesMiddleware } from './query/games';
import { actionCommandsMiddleware } from './query/actionCommands';
import { registerNewCardMiddleware } from './query/RegisterNewCard';
import { eventsMiddleware } from './query/events';
import { shopMiddleware } from './query/shop';
var sagaMiddleware = createSagaMiddleware();
var customizedDefaultMiddleware = getDefaultMiddleware({
  serializableCheck: false,
  immutableCheck: false
});
var middlewares = [missionsMiddleware, assetsPackageMiddleware, linkGuestAccountMiddleware, purchaseMiddleware, restartSessionMiddleware, urlsMiddleware, loginMiddleware, signupMiddleware, oneTimePasswordMiddleware, registerNewCardMiddleware, rewardCenterMiddleware, textsMiddleware, dailyWheelMiddleware, gamesMiddleware, actionCommandsMiddleware, eventsMiddleware, shopMiddleware];
var enhancers = [];
middlewares.push(sagaMiddleware);
reactotron && enhancers.push(reactotron.createEnhancer());
export var store = configureStore({
  reducer: pReducer,
  devTools: true,
  middleware: [].concat(_toConsumableArray(customizedDefaultMiddleware), middlewares),
  enhancers: [].concat(enhancers)
});
sagaMiddleware.run(rootSaga);
setupListeners(store.dispatch);
export var persistor = persistStore(store);
export default store;