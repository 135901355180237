import { TEXT_KEY } from './localization/constants';
import { counterTypes } from './TimestampManager';
export var buildTimeRemainingBydays = function buildTimeRemainingBydays(_ref) {
  var from = _ref.from,
      until = _ref.until,
      getText = _ref.getText,
      counterType = _ref.counterType,
      _ref$showZeroOnEnd = _ref.showZeroOnEnd,
      showZeroOnEnd = _ref$showZeroOnEnd === void 0 ? false : _ref$showZeroOnEnd,
      _ref$showMinOnDayZero = _ref.showMinOnDayZero,
      showMinOnDayZero = _ref$showMinOnDayZero === void 0 ? false : _ref$showMinOnDayZero;
  if (showZeroOnEnd && until - from <= 0) return {
    timeString: "00".concat(getText(TEXT_KEY.TIMER_LEFT_HOUR), " 00").concat(getText(TEXT_KEY.TIMER_LEFT_MINUTE)),
    isTimeInDanger: false
  }; // get total seconds between the times

  var delta = Math.abs(until - from) / 1000; // calculate (and subtract) whole days

  var days = Math.floor(delta / 86400);
  delta -= days * 86400; // calculate (and subtract) whole hours

  var hrs = Math.floor(delta / 3600) % 24;
  delta -= hrs * 3600; // calculate (and subtract) whole minutes

  var mins = Math.floor(delta / 60) % 60;
  delta -= mins * 60;
  var isTimeInDanger = false;
  var timeString = '';
  var timeDangerZoneInDay = 1;

  if (days < timeDangerZoneInDay && counterType !== counterTypes.EVENTS) {
    isTimeInDanger = true;
  }

  if (showMinOnDayZero) {
    if (days > 0) {
      if (days < 10) {
        days = '0' + "".concat(days);
      }

      timeString += "".concat(days).concat(getText(TEXT_KEY.TIMER_LEFT_DAY), " ");
    }

    if (hrs >= 0) {
      if (hrs < 10) hrs = '0' + "".concat(hrs);
      timeString += "".concat(hrs).concat(getText(TEXT_KEY.TIMER_LEFT_HOUR), " ");
    }

    if (days <= 0 && mins >= 0) {
      if (mins < 10) {
        mins = '0' + "".concat(mins);
      }

      timeString += "".concat(mins).concat(getText(TEXT_KEY.TIMER_LEFT_MINUTE));
    }
  } else {
    if (days >= 0) {
      if (days < 10) {
        days = '0' + "".concat(days);
      }

      timeString += "".concat(days).concat(getText(TEXT_KEY.TIMER_LEFT_DAY), " ");
    }

    if (hrs >= 0) {
      if (hrs < 10) hrs = '0' + "".concat(hrs);
      timeString += "".concat(hrs).concat(getText(TEXT_KEY.TIMER_LEFT_HOUR), " ");
    }
  }

  return {
    timeString: timeString,
    isTimeInDanger: isTimeInDanger
  };
};
export var buildTimeRemainingDefault = function buildTimeRemainingDefault(_ref2) {
  var from = _ref2.from,
      until = _ref2.until,
      counterType = _ref2.counterType,
      getText = _ref2.getText;
  var msHours = 1000 * 60 * 60;
  var msMinutes = 1000 * 60;
  var msSeconds = 1000;
  var timeDangerZoneInHour = 1;
  var isTimeInDanger = false; // Difference in ms

  var ms = until - from;

  if (ms < 0) {
    return '';
  } // Extract hours


  var hrs = Math.floor(ms / msHours);
  ms -= hrs * msHours; // Extract minutes

  var mins = Math.floor(ms / msMinutes);
  ms -= mins * msMinutes; // Extract seconds

  var secs = Math.floor(ms / msSeconds);
  var timeString = '';

  if (counterType !== counterTypes.MISSION_CARD) {
    if (hrs >= 0) {
      if (hrs < 10) {
        hrs = '0' + "".concat(hrs);
      }

      timeString += "".concat(hrs, ":");
    }

    if (mins >= 0) {
      if (mins < 10) {
        mins = '0' + "".concat(mins);
      }

      timeString += "".concat(mins, ":");
    }

    if (secs >= 0) {
      if (secs < 10) {
        secs = '0' + "".concat(secs);
      }

      timeString += "".concat(secs);
    }
  } else {
    if (hrs < timeDangerZoneInHour) {
      isTimeInDanger = true;
    }

    if (hrs >= 0) {
      if (hrs < 10) {
        hrs = '0' + "".concat(hrs);
      }

      timeString += "".concat(hrs).concat(getText(TEXT_KEY.TIMER_LEFT_HOUR), " ");
    }

    if (mins >= 0) {
      if (mins < 10) {
        mins = '0' + "".concat(mins);
      }

      timeString += "".concat(mins).concat(getText(TEXT_KEY.TIMER_LEFT_MINUTE));
    }
  }

  return {
    timeString: timeString,
    isTimeInDanger: isTimeInDanger
  };
};