import React, { Component } from 'react';
import SBottomBar from 'shared/components/BottomBar';
import Screens from 'shared/screens';
import QueryParamsProxy from 'shared/utils/queryParamProxy';
import RouteParamConstants from 'shared/utils/routeParamConstants';
import ThemeContext from 'shared/context/ThemeContext';
import TimeRemainingText from '../TimeRemainingText';
import audio from 'shared/utils/audio';
import Chest from '../Chest';
import { activateBottomBarAnimation } from 'shared/screens/Missions/utils/missionsPerformance';
import { sendMissionsEvent } from 'shared/screens/Missions/analytics/missionsLogger';
import { MISSIONS_EVENT_TYPES } from 'shared/screens/Missions/constants';
import { shouldChangeTutorialStep, MissionTutorialStepsKeys } from 'shared/utils/tutorial';
import LottieAnimation from '../LottieAnimation/LottieAnimation';
import { ANIMATION_TYPE } from 'shared/components/LottieAnimation/constants';
import useFeatureFlags from 'shared/hooks/useFeatureFlags';
import { FE_ANIMATION } from 'shared/utils/featureFlags/constants';
import EventsIcon from './components/EventsIcon';
import NotificationBadge from './components/NotificationBadge';

export const chestComponentName = 'BottomBarChest';
const MAX_CHEST_UPDATE_RETRIES = 15;
export default class BottomBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: true,
      showLeftTooltip: false,
      showRightTooltip: false,
      playChestAnimation: false,
      animationWidth: 0,
      animationHeight: window.innerHeight,
      chestPosition: { x: 0, y: 0, width: 0, height: 0 },
    };
    this.chestRef = React.createRef();
  }

  componentDidMount() {
    window.addEventListener('resize', this.resize);
    this.getElements = setInterval(() => {
      if (!this.currencyDisplay) {
        this.currencyDisplay = document.getElementById('CurrencyDisplay');
      }
      if (!this.animation) {
        this.animation = document.getElementById('animationContainer');
      }

      if (this.animation && this.currencyDisplay && this.state.animationWidth === 0) {
        const { animationWidth, animationHeight } = this.getAnimationDimensions();
        this.setState({ animationWidth, animationHeight });
        clearInterval(this.getElements);
      }
    }, 100);
    this.getChestPosition();
  }

  componentDidUpdate(prevProps, prevState) {
    this.updateDimensions(prevState);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
    clearTimeout(this.updateTimeout);
  }

  updateDimensions(prevState, retries = 0) {
    if (this.animation && this.currencyDisplay) {
      const { animationWidth, animationHeight } = this.getAnimationDimensions();
      if ((animationWidth <= 0 || animationHeight <= 0) && retries <= MAX_CHEST_UPDATE_RETRIES) {
        this.updateTimeout = setTimeout(() => this.updateDimensions(prevState, retries + 1), 50);
      } else {
        if (animationWidth !== prevState.animationWidth || animationHeight !== prevState.animationHeight) {
          this.setState({ animationWidth, animationHeight });
        }
        this.getChestPosition();
      }
    }
  }

  getAnimationDimensions = () => {
    const animationWidth =
      window.innerWidth -
      (window.innerWidth - (this.animation.getBoundingClientRect().x + this.animation.getBoundingClientRect().width)) -
      this.currencyDisplay.getBoundingClientRect().x -
      30;
    const animationHeight = window.innerHeight;

    return { animationWidth, animationHeight };
  };

  resize = () => {
    this.currencyDisplay = document.getElementById('CurrencyDisplay');
    this.animation = document.getElementById('animationContainer');
    if (this.animation && this.currencyDisplay) {
      const { animationWidth, animationHeight } = this.getAnimationDimensions();
      this.setState({ animationWidth, animationHeight });
    }
  };

  getChestPosition() {
    if (this.chestRef) {
      const element = this.chestRef.current;
      const { x, y, width, height } = element.getBoundingClientRect();
      const innerWidth = window.innerWidth;

      if (
        x !== this.state.chestPosition.x ||
        y !== this.state.chestPosition.y ||
        width !== this.state.chestPosition.width ||
        height !== this.state.chestPosition.height
      ) {
        x > innerWidth / 2 &&
          this.props.setPosition({
            component: chestComponentName,
            x,
            y,
            width,
            height,
          });

        this.setState({ chestPosition: { x, y, width, height } });
      }
    }
  }

  _sharedWillUpdate = (nextProps, nextState) => {
    if (this.state.visible !== nextState.visible) this.setState({ visible: nextState.visible });
  };

  showTooltip = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  shouldRunMissionsAnimation = () =>
    activateBottomBarAnimation(this.props.missionsData) && !this.props.activeTutorial && this.props.missionsAssets;

  handleMissionButton = () => {
    const { activeTutorial, tutorialStep, setTutorialStep, navigation } = this.props;
    if (activeTutorial) {
      sendMissionsEvent(MISSIONS_EVENT_TYPES.MISSIONS_TUTORIAL_NEXT_STEP, { step: tutorialStep });
      shouldChangeTutorialStep(tutorialStep) && setTutorialStep(MissionTutorialStepsKeys.CHECK_GOAL);
    }
    QueryParamsProxy.navigate(navigation, Screens.Missions);
  };

  render() {
    const themeContext = this.context;
    const divider = (
      <img
        alt="divider"
        src={require(asset`images/Bottom_Bar_Assets/Divider@3x.png`)}
        style={themeContext.BottomBar.Divider}
      />
    );

    return (
      <SBottomBar
        navigation={this.props.navigation}
        sharedWillUpdate={this._sharedWillUpdate}
        render={args => {
          const {
            showModal,
            openChest,
            TIME_LEFT,
            timedBonusHandle,
            canEnterVipRoom,
            isVipRoomUnlocked,
            videoAds,
            appBackground,
            sidebarState,
            setSidebarState,
            claimingBonus,
            unreadMessageCount,
            unreadOfferCount,
            showMissionsButton,
            showEventsButton,
            rewardCenterMiddleware,
          } = args;
          const isVIPRoom = this.props.navigation.state.routes[this.props.navigation.state.index].key === Screens.VIP;

          const showBadge = unreadMessageCount > 0;
          const showRewardCenterBadge = unreadOfferCount > 0;
          const showPlusOneHundred = unreadMessageCount > 99;
          const isChestSpriteLoaded = this.state.animationWidth > 0 && this.state.animationHeight > 0;

          const { hasFeature } = useFeatureFlags();
          const activeAnimations = hasFeature(FE_ANIMATION);

          return (
            <div
              className={'navBar bottom' + (this.state.visible === false ? ' hide' : ' show')}
              style={themeContext.BottomBar.BottomBarBackground}
            >
              <div style={themeContext.BottomBar.BottomBarContainer}>
                <div className="flexBarSection" style={{ flex: 2 }}>
                  {!showEventsButton && <div style={themeContext.BottomBar.BarItem} />}
                  <div
                    style={themeContext.BottomBar.BarItem}
                    onClick={
                      videoAds && (videoAds.timeUntilReset === 0 || videoAds.remaining > 0)
                        ? () => showModal(Screens.VideoAds)
                        : () => showModal(Screens.WatchAndEarnInfo)
                    }
                  >
                    <img
                      alt="Bottom Bar"
                      src={require(asset`images/Bottom_Bar_Assets/WATCH@3x.png`)}
                      draggable="false"
                      style={themeContext.BottomBar.BarItemIcon}
                    />
                    {videoAds && videoAds.remaining > 0 && (
                      <NotificationBadge
                        textStyle={themeContext.BottomBar.NotificationText}
                        count={videoAds.remaining}
                        theme={themeContext.BottomBar.NotificationWatchAndEarn}
                      />
                    )}
                  </div>
                  {showEventsButton && <EventsIcon navigation={this.props.navigation} />}
                  {divider}
                  <div style={themeContext.BottomBar.BarItem} onClick={() => showModal(Screens.Messages)}>
                    <img
                      alt="Bottom Bar"
                      src={require(asset`images/Bottom_Bar_Assets/MAIL@3x.png`)}
                      draggable="false"
                      style={themeContext.BottomBar.BarItemIcon}
                    />
                    {showBadge && (
                      <NotificationBadge
                        textStyle={themeContext.BottomBar.NotificationText}
                        count={showPlusOneHundred ? '99' : unreadMessageCount}
                        theme={themeContext.BottomBar.NotificationMail}
                      />
                    )}
                  </div>
                </div>
                <div className="flexBarSection" style={{ height: '100%', flex: 1 }}>
                  <>
                    {divider}
                    <div style={themeContext.BottomBar.BarItem} onClick={rewardCenterMiddleware}>
                      <img
                        alt="Bottom Bar"
                        src={require(asset`images/Bottom_Bar_Assets/REWARDS@3x.png`)}
                        draggable="false"
                        style={themeContext.BottomBar.RewardCenterIcon}
                      />
                      {showRewardCenterBadge && (
                        <div style={themeContext.BottomBar.RewardCenterBadge}>{unreadOfferCount}</div>
                      )}
                    </div>
                  </>
                  {divider}
                </div>
                <div className="flexBarSection" style={{ flex: 2 }}>
                  <div
                    onClick={() => {
                      audio.onClick();
                      setSidebarState(!sidebarState);
                      if (isVIPRoom) {
                        QueryParamsProxy.navigate(this.props.navigation, Screens.Home);
                      } else if (!canEnterVipRoom) {
                        QueryParamsProxy.setQueryParams({
                          [RouteParamConstants.PARAM_MODAL]: Screens.VipAccessFlow,
                        });
                      } else {
                        QueryParamsProxy.navigate(this.props.navigation, Screens.VIP);
                      }
                    }}
                  >
                    {!isVIPRoom && (
                      <div className="VIPIconContainer">
                        {!isVipRoomUnlocked && (
                          <img
                            alt="Bottom Bar VIP"
                            src={require(asset`images/Bottom_Bar_Assets/VIP_Entry_Locked@3x.png`)}
                            draggable="false"
                            style={themeContext.BottomBar.VIPIcon}
                          />
                        )}
                        {appBackground !== 'vipGames' && isVipRoomUnlocked && (
                          <img
                            alt="Bottom Bar VIP"
                            src={require(asset`images/Bottom_Bar_Assets/VIP_Room@3x.png`)}
                            draggable="false"
                            style={themeContext.BottomBar.VIPIcon}
                          />
                        )}
                      </div>
                    )}
                    {(isVIPRoom || appBackground === 'vipGames') && (
                      <div className="SlotIconContainer">
                        <img
                          className="SlotIcon"
                          alt="Bottom Bar Slot"
                          src={require(asset`images/Bottom_Bar_Assets/SLOTS@3x.png`)}
                          draggable="false"
                          style={themeContext.BottomBar.BarItemIcon}
                        />
                      </div>
                    )}
                  </div>
                  {divider}
                  <div
                    ref={this.chestRef}
                    style={themeContext.BottomBar.BarItem}
                    onClick={
                      openChest
                        ? () => {
                            timedBonusHandle();
                            this.props.startAnimationCallback && this.props.startAnimationCallback();
                            this.setState({ playChestAnimation: true });
                          }
                        : () => {}
                    }
                  >
                    <>
                      <img
                        style={{
                          opacity: !isChestSpriteLoaded && !openChest ? 1 : !claimingBonus ? 0 : 1,
                          ...themeContext.BottomBar.BarItemIcon,
                        }}
                        alt="Bar Item Icon"
                        className={!claimingBonus ? 'TimedChest' : 'TimedChest RotateChest'}
                        src={require(asset`images/Timed_Bonus_Assets/Timed_Bonus_Closed@3x.png`)}
                        draggable="false"
                      />
                      <img
                        style={{
                          display: !isChestSpriteLoaded && openChest ? 'block' : 'none',
                          ...themeContext.BottomBar.BarItemIcon,
                        }}
                        alt="Bar Item Icon"
                        className="TimedChest"
                        src={require(asset`images/Timed_Bonus_Assets/Timed_Bonus_Open@3x.png`)}
                        draggable="false"
                      />
                      {
                        <Chest
                          style={{
                            opacity: !claimingBonus ? 1 : 0,
                            width: this.state.animationWidth,
                            height: this.state.animationHeight,
                            position: 'absolute',
                            bottom: 0,
                            right: 0,
                            pointerEvents: 'none',
                          }}
                          showOpen={!openChest}
                          shouldPlay={this.state.playChestAnimation && !claimingBonus}
                          showEnd={TIME_LEFT > 0}
                        />
                      }
                    </>
                    {TIME_LEFT === 0 && (
                      <img
                        className="TimerCollect"
                        alt="Timer Collect"
                        src={require(asset`images/Timed_Bonus_Assets/Timer_COLLECT@3x.png`)}
                        draggable="false"
                      />
                    )}
                    {TIME_LEFT > 0 && (
                      <div style={themeContext.BottomBar.TimerWrapper}>
                        <img
                          style={themeContext.BottomBar.TimerBackground}
                          alt="Timer Wrapper"
                          src={require(asset`images/Timed_Bonus_Assets/Timer_WAITING@3x.png`)}
                          draggable="false"
                        />
                        <TimeRemainingText style={themeContext.BottomBar.TimerLabel} until={TIME_LEFT} hideOnFinish />
                      </div>
                    )}
                  </div>
                  {showMissionsButton && this.props.missionsData.length > 0 ? (
                    <>
                      {divider}
                      <div
                        className={`BarItem ${this.shouldRunMissionsAnimation() && 'AnimatedIcon'}`}
                        onClick={() => this.handleMissionButton()}
                      >
                        {activateBottomBarAnimation(this.props.missionsData) &&
                          !this.props.activeTutorial &&
                          this.props.missionsAssets && (
                            <NotificationBadge
                              textStyle={themeContext.BottomBar.NotificationText}
                              count="!"
                              theme={themeContext.BottomBar.NotificationMission}
                            />
                          )}
                        {activeAnimations ? (
                          <>
                            <LottieAnimation
                              startDelay={3000}
                              loopDelay={1000}
                              animationType={ANIMATION_TYPE.MISSIONS_LOBBY_ICON_ANIMATION}
                              autoplay={true}
                              speed={0.3}
                              animationStyle={themeContext.BottomBar.MissionsIconAnimation}
                            />
                            <img
                              alt="Bottom Bar"
                              src={require(asset`images/Bottom_Bar_Assets/GTP_Missions_Icon_Text@x3.png`)}
                              draggable="false"
                              style={themeContext.BottomBar.MissionsIconText}
                            />
                          </>
                        ) : (
                          <img
                            alt="Bottom Bar"
                            src={require(asset`images/Bottom_Bar_Assets/MISSIONS@3x.png`)}
                            draggable="false"
                            style={themeContext.BottomBar.MissionsIcon}
                          />
                        )}
                      </div>
                    </>
                  ) : (
                    <div style={themeContext.BottomBar.BarItem} />
                  )}
                </div>
              </div>
            </div>
          );
        }}
      />
    );
  }
}

BottomBar.contextType = ThemeContext;

