import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { setNavState } from '../state/actions/nav_state';
import NavigationConstants from '../components/LobbyOverlay/NavigationConstants';

var useNavState = function useNavState() {
  var dispatch = useDispatch();
  useEffect(function () {
    dispatch(setNavState(NavigationConstants.HIDE_BOTTOM));
    return function () {
      dispatch(setNavState(NavigationConstants.NO_HIDE));
    }; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useNavState;