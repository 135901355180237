import React, { Component } from 'react';

import GamePage from '../../components/GamePage';
import STableGames from 'shared/screens/TableGames';
import Utils from 'shared/utils';
import audio from 'shared/utils/audio';
import { GameListWrapper } from '../Home/styledComponents';

export default class TableGames extends Component {
  slider = React.createRef();

  handleNextClick = () => {
    audio.onClick();
    this.slider.current.slickNext();
  };

  handlePrevClick = () => {
    audio.onClick();
    this.slider.current.slickPrev();
  };

  handleFirstClick = () => {
    audio.onClick();
    this.slider.current.slickGoTo(0);
  };

  handleLastClick = pagesCount => {
    audio.onClick();
    this.slider.current.slickGoTo(pagesCount);
  };

  render() {
    return (
      <STableGames
        navigation={this.props.navigation}
        {...this.props}
        render={args => {
          const { pagedGames, lobbyPromotions } = args;
          const hasPromotions = !Utils.isEmpty(lobbyPromotions);
          return (
            <div className="roomPage">
              <div className="roomContent">
                <div className="NavigationArrowsContainer left">
                  <img
                    src={require(asset`images/Lobby_Assets/Scroll_Buttons@3x.png`)}
                    className="LeftNavigationArrows"
                    alt="Left navigation arrows"
                  />
                  <div onClick={this.handlePrevClick} className="NavigationArrowTop" />
                  <div onClick={this.handleFirstClick} className="NavigationArrowBottom" />
                </div>

                <GameListWrapper banner={hasPromotions}>
                  <div className={`GameListContainer ${!hasPromotions ? 'no-margin' : ''}`}>
                    <GamePage {...args} sliderRef={this.slider} navigation={this.props.navigation} />
                  </div>
                </GameListWrapper>

                <div className="NavigationArrowsContainer right">
                  <img
                    src={require(asset`images/Lobby_Assets/Scroll_Buttons@3x.png`)}
                    className="RightNavigationArrows"
                    alt="Right navigation arrows"
                  />
                  <div onClick={this.handleNextClick} className="NavigationArrowTop" />
                  <div onClick={() => this.handleLastClick(pagedGames.length - 1)} className="NavigationArrowBottom" />
                </div>
              </div>
            </div>
          );
        }}
      />
    );
  }
}
