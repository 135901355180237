import _defineProperty from "/var/jenkins/workspace/Web Clients and Images - Production/Code/WEB CLIENT - GTP 2.0 Deploy/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import generateRender from '../../../../utils/generateRender';
import { handleTooltip } from './tooltipCommands';
var _window = window,
    width = _window.innerWidth;

var getLeftRewardCenterTooltip = function getLeftRewardCenterTooltip() {
  return width <= 1300 ? '37.5vw' : width <= 1400 ? '38.5vw' : width <= 1600 ? '39.5vw' : '41.5vw';
};

var getPositionLinkAccountTooltip = function getPositionLinkAccountTooltip() {
  return generateRender({
    default: {
      left: window.innerWidth >= 1920 ? '69%' : window.innerWidth >= 1700 ? '72vw' : '75vw'
    },
    admiral: {
      left: window.innerWidth >= 1920 ? '67.5%' : window.innerWidth >= 1700 ? '72vw' : '75vw'
    },
    admes: {
      left: window.innerWidth >= 1920 ? '67.5%' : window.innerWidth >= 1700 ? '72vw' : '75vw'
    }
  });
};

export var handleLinkAccountTooltip = function handleLinkAccountTooltip(actionCommand, props) {
  var text = actionCommand.tooltip.text;
  var details = {
    title: text,
    animationState: 'AnimationFadeIn',
    style: _objectSpread({
      top: '12vh',
      zIndex: '9999',
      display: 'flex',
      alignItems: 'center',
      fontWeight: '700',
      marginLeft: 'auto',
      marginRight: 'auto'
    }, getPositionLinkAccountTooltip()),
    displayTextStyle: {
      fontSize: '17px',
      fontStyle: 'italic',
      flex: 1,
      textAlign: 'center',
      margin: '5px'
    },
    arrowStyle: {
      left: '2vw'
    },
    innerStyle: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }
  };
  handleTooltip(details, props, actionCommand);
};
export var handleRewardCenterTooltip = function handleRewardCenterTooltip(actionCommand, props) {
  var text = actionCommand.tooltip.text;
  var details = {
    title: text,
    animationState: 'AnimationFadeIn',
    style: {
      top: '70vh',
      zIndex: '9999',
      display: 'flex',
      alignItems: 'center',
      fontWeight: '700',
      marginLeft: 'auto',
      marginRight: 'auto',
      left: getLeftRewardCenterTooltip()
    },
    displayTextStyle: {
      fontSize: '17px',
      fontStyle: 'italic',
      flex: 1,
      textAlign: 'center',
      margin: '5px'
    },
    arrowStyle: {
      position: 'absolute',
      top: '78px',
      transform: 'rotate(180deg)',
      flex: '0',
      width: '5rem',
      height: '5rem'
    },
    innerStyle: {
      display: 'flex',
      alignItems: 'center'
    }
  };
  handleTooltip(details, props, actionCommand);
};

var getDetailEventsTooltip = function getDetailEventsTooltip(title) {
  return {
    title: title,
    animationState: 'AnimationFadeIn',
    style: {
      zIndex: '9999',
      fontWeight: '600',
      top: '10vh',
      left: '1vw',
      width: '23vw',
      height: '13vh'
    },
    displayTextStyle: {
      fontSize: '15px',
      fontStyle: 'italic',
      flex: 1
    },
    arrowStyle: {
      display: 'none'
    },
    innerStyle: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: 20
    }
  };
};

export var handleEventsTooltip = function handleEventsTooltip(actionCommand, props) {
  var details = getDetailEventsTooltip(actionCommand.tooltip.text);
  handleTooltip(details, props, actionCommand);
};