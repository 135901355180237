import Logger from '../../../lib/analytics/logger';
import { EVENTS_EVENT_TYPES } from '../constants/events';
import { EVENT_ACTIONS, EVENT_DOMAINS, EVENT_SCREENS, EVENT_WIDGETS } from '../../../lib/analytics/constants';
import { EVENT_TYPES } from '../../../lib/analytics/constants';
export var sendEventsEvent = function sendEventsEvent(eventType, payload) {
  switch (eventType) {
    case EVENTS_EVENT_TYPES.ENTER_EVENTS_SCREEN:
      Logger.sendEvent(EVENT_TYPES.UI_EVENT, {
        action: EVENT_ACTIONS.DISPLAY,
        domain: EVENT_DOMAINS.EVENTS,
        screen: EVENT_SCREENS[EVENT_DOMAINS.GENERAL].MAIN
      });
      break;

    case EVENTS_EVENT_TYPES.DISPLAY_EVENTS_ICON:
      Logger.sendEvent(EVENT_TYPES.UI_EVENT, {
        action: EVENT_ACTIONS.DISPLAY,
        domain: EVENT_DOMAINS.EVENTS,
        screen: EVENT_SCREENS[EVENT_DOMAINS.GENERAL].LOBBY,
        widget: EVENT_WIDGETS[EVENT_DOMAINS.GENERAL].EVENTS_ICON
      });
      break;

    case EVENTS_EVENT_TYPES.EVENTS_OPEN_EVENT_CARD:
      Logger.sendEvent(EVENT_TYPES.UI_EVENT, {
        action: EVENT_ACTIONS.PRESS,
        domain: EVENT_DOMAINS.EVENTS,
        screen: EVENT_SCREENS[EVENT_DOMAINS.GENERAL].MAIN,
        widget: EVENT_WIDGETS[EVENT_DOMAINS.EVENTS].EVENTS_CARD,
        eventId: payload.eventId
      });
      break;

    case EVENTS_EVENT_TYPES.EVENTS_OPEN_INFO_SCREEN:
      Logger.sendEvent(EVENT_TYPES.UI_EVENT, {
        action: EVENT_ACTIONS.PRESS,
        domain: EVENT_DOMAINS.EVENTS,
        screen: EVENT_SCREENS[EVENT_DOMAINS.GENERAL].MAIN,
        widget: EVENT_WIDGETS[EVENT_DOMAINS.EVENTS].EVENTS_INFO_BUTTON
      });
      break;

    case EVENTS_EVENT_TYPES.DISPLAY_EVENTS_TOOLTIP:
      Logger.sendEvent(EVENT_TYPES.UI_EVENT, {
        action: EVENT_ACTIONS.DISPLAY,
        domain: EVENT_DOMAINS.EVENTS,
        screen: EVENT_SCREENS[EVENT_DOMAINS.GENERAL].LOBBY,
        widget: EVENT_WIDGETS[EVENT_DOMAINS.EVENTS].EVENTS_TOOLTIP
      });
      break;

    case EVENTS_EVENT_TYPES.DISPLAY_EVENTS_TUTORIAL:
      Logger.sendEvent(EVENT_TYPES.UI_EVENT, {
        action: EVENT_ACTIONS.DISPLAY,
        domain: EVENT_DOMAINS.EVENTS,
        screen: EVENT_SCREENS[EVENT_DOMAINS.GENERAL].INFO
      });
      break;

    case EVENTS_EVENT_TYPES.SKIP_EVENTS_TUTORIAL:
      Logger.sendEvent(EVENT_TYPES.UI_EVENT, {
        action: EVENT_ACTIONS.PRESS,
        domain: EVENT_DOMAINS.EVENTS,
        screen: EVENT_SCREENS[EVENT_DOMAINS.GENERAL].INFO,
        widget: EVENT_WIDGETS[EVENT_DOMAINS.EVENTS].EVENTS_SKIP_BUTTON,
        context_tutorial_step: payload.step
      });
      break;

    default:
      break;
  }
};