var PushNotification = {
  _notificationsEnabled: false,

  get notificationsEnabled() {
    return this._notificationsEnabled;
  },

  set notificationsEnabled(value) {
    this._notificationsEnabled = value;
  },

  /**
   * The platform should override the scheduleNotification method so that shared flows may
   * re-use functionality and send the same data.
   */
  scheduleNotification: function scheduleNotification(payload) {
    console.error("Attempted to execute no-op PushNotification.scheduleNotification with payload:", payload);
  },
  scheduleMissYouNotification: function scheduleMissYouNotification(payload) {
    console.error("Attempted to execute no-op PushNotification.scheduleMissYouNotification with payload:", payload);
  },
  scheduleMissionReminderNotification: function scheduleMissionReminderNotification(payload) {
    console.error("Attempted to execute no-op PushNotification.scheduleMissionReminderNotification with payload:", payload);
  },
  scheduleTimedBonusNotification: function scheduleTimedBonusNotification(payload) {
    console.error("Attempted to execute no-op PushNotification.scheduleTimedBonusNotification with payload:", payload);
  },
  checkPermissions: function checkPermissions() {
    console.error("Attempted to execute no-op PushNotification.checkPermission.");
  },
  cancelInvalidNotifications: function cancelInvalidNotifications(payload) {
    console.error("Attempted to execute no-op PushNotification.cancelLocalNotifications with id:", payload);
  },
  cancelMissionReminderNotification: function cancelMissionReminderNotification(payload) {
    console.error("Attempted to execute no-op PushNotification.cancelMissionReminderNotification with id:", payload);
  },
  popInitialNotification: function popInitialNotification(payload) {
    console.error("Attempted to execute no-op PushNotification.popInitialNotification with payload:", payload);
  }
};
export default PushNotification;