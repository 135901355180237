import React from 'react';
import styled from 'styled-components';
import Button from '../../../../components/Button/Button';

const RewardCenterCategoriesOpened = props => {
  const {
    categories,
    rewardCenterAssets,
    setFilterData,
    setShowCategories,
    categorySelected,
    setCategorySelected,
    clearFilterData,
  } = props;

  const onClickButton = (categoryId, categoryName) => {
    if (categoryId === categorySelected) {
      clearFilterData();
      return;
    }
    setCategorySelected(categoryId);
    setFilterData(categoryName);
    setShowCategories(false);
  };

  return (
    <Container>
      <Background length={categories.length} bgImage={rewardCenterAssets.REWARD_CENTER_OPENED_MENU_BACKGROUND}>
        <Header>
          <Arrow src={rewardCenterAssets.REWARD_CENTER_OPENED_MENU_ARROW} />
          <Title src={rewardCenterAssets.REWARD_CENTER_OPENED_MENU_TITLE} />
          <Arrow src={rewardCenterAssets.REWARD_CENTER_OPENED_MENU_ARROW} />
        </Header>
        <Buttons>
          {categories.map(({ categoryId, categoryName }) => (
            <Button
              key={categoryId}
              label={categoryName}
              textStroke={categorySelected === categoryId ? '#7C028D' : '#2E074F'}
              imageSource={
                categorySelected === categoryId
                  ? rewardCenterAssets.REWARD_CENTER_OPENED_MENU_BUTTON_ACTIVE
                  : rewardCenterAssets.REWARD_CENTER_OPENED_MENU_BUTTON_DEFAULT
              }
              textStyle={{ textTransform: 'uppercase', fontSize: '1rem' }}
              imageStyle={{ width: 155 }}
              containerStyle={{
                marginBottom: '5%',
              }}
              onClick={() => onClickButton(categoryId, categoryName)}
            />
          ))}
        </Buttons>
      </Background>
    </Container>
  );
};

export default RewardCenterCategoriesOpened;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Background = styled.div`
  width: 100%;
  height: 100%;
  background-image: ${({ bgImage }) => `url(${bgImage})`};
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  width: 35rem;
  height: ${({ length }) => 8 + Math.ceil(length / 3) * 2}rem;
  position: absolute;
  top: ${({ length }) => -2 + Math.ceil(length / 3) * -2}rem;
  z-index: 2;
`;

const Header = styled.div`
  justify-content: space-around;
  display: flex;
  margin-top: 1rem;
`;

const Title = styled.img`
  width: 45%;
  height: 100%;
  object-fit: contain;
`;

const Arrow = styled.img`
  width: 3%;
  height: 100%;
  object-fit: contain;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 5% 1.5%;
`;

