var FormId = require("../../../components/Form/FormId").default;

var FormType = require("../../../components/Form/FormType").default;

var InputValidator = require("../../../components/Form/InputValidator").default;

module.exports = {
  header: {
    title: "",
    backButtonText: ""
  },
  steps: [{
    title: "",
    formInputList: [{
      id: FormId.SUBJECT,
      label: "Message Title",
      type: FormType.TEXT,
      validator: InputValidator.NOT_EMPTY,
      maxChar: 150,
      inputStyle: true
    }, {
      id: FormId.BODY,
      label: "",
      type: FormType.TEXT,
      validator: InputValidator.NOT_EMPTY,
      multiline: true,
      numberOfLines: 6,
      rows: 8,
      containerStyle: {
        marginTop: '0.5rem',
        paddingLeft: 5,
        paddingTop: 5,
        backgroundColor: '#110F1D',
        borderBottom: 'none'
      },
      placeholder: 'Type your message here',
      inputStyle: true
    }],
    submitButtonText: "SEND"
  }]
};