import React from 'react';
import useRewardCenter from 'shared/screens/RewardCenter/hooks/useRewardCenter';
import styled from 'styled-components';
import ScrollContainer from 'react-indiana-drag-scroll';
import Menu from './components/RewardCenterMenu';
import RewardCenterProfile from './components/RewardCenterProfile';
import RewardCenterCategories from './components/Cards/RewardCenterCategories';
import RewardCenterOffers from './components/Cards/RewardCenterOffers';
import RewardCenterOfferDetail from './components/RewardCenterOfferDetail';
import { Spinner } from 'react-bootstrap';
import LoadingLayer from '../../components/Loading/Loading';

const LoadingSpinner = () => (
  <Loading>
    <Spinner animation="border" />
  </Loading>
);

const RewardCenter = props => {
  const rewardCenterHookData = useRewardCenter(props);
  const { rewardCenterAssets, showCategories, categories, offers, selectedOffer, clearFilterData } =
    rewardCenterHookData;

  return rewardCenterAssets ? (
    <>
      {selectedOffer && <RewardCenterOfferDetail {...rewardCenterHookData} />}
      <RewardCenterProfile {...rewardCenterHookData} />
      <Background src={rewardCenterAssets.REWARD_CENTER_BACKGROUND} />
      {!showCategories && (
        <BackButtonmage onClick={clearFilterData} src={rewardCenterAssets?.REWARD_CENTER_ARROW_BACK_BUTTON} />
      )}

      {categories.length === 0 && <LoadingSpinner />}
      {(categories.length !== 0 || offers.length !== 0) && (
        <RewardCenterContainer>
          <RewardCenterContent>
            {showCategories ? (
              <RewardCenterList
                count={categories?.length}
                data={categories}
                renderItem={object => <RewardCenterCategories object={object} {...rewardCenterHookData} />}
              />
            ) : (
              <RewardCenterList
                data={offers}
                count={offers?.length}
                renderItem={object => <RewardCenterOffers object={object} {...rewardCenterHookData} />}
              />
            )}
          </RewardCenterContent>
          <Menu {...rewardCenterHookData} />
        </RewardCenterContainer>
      )}
    </>
  ) : (
    <LoadingLayer loading={!rewardCenterAssets} />
  );
};

export default RewardCenter;

const RewardCenterList = ({ data, renderItem, count }) => {
  return (
    <RewardCenterCardsContainer count={count} vertical={false}>
      {data?.map(renderItem)}
    </RewardCenterCardsContainer>
  );
};

const Loading = styled.div`
  position: absolute;
  z-index: 99;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const RewardCenterContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const RewardCenterContent = styled.div`
  height: 80%;
  z-index: 1;
`;

const Background = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
`;

const RewardCenterCardsContainer = styled(ScrollContainer)`
  padding-right: 100px;
  padding-left: 100px;
  display: flex;
  gap: ${({ count }) => (count > 3 ? 8 : 10)}rem;
  z-index: 1;
  padding-bottom: 5rem;

  @media only screen and (min-height: 1080px) {
    height: 100%;
  }

  @media only screen and (min-width: 1490px) {
    gap: ${({ count }) => (count > 3 ? 9 : 10)}rem;
  }

  @media only screen and (min-width: 1670px) {
    gap: ${({ count }) => (count > 3 ? 6 : 10)}rem;
  }

  @media only screen and (min-width: 2150px) {
    gap: ${({ count }) => (count > 3 ? 7 : 10)}rem;
  }

  @media only screen and (min-width: 1900px) {
    gap: ${({ count }) => (count > 3 ? 5 : 10)}rem;
  }
`;

const BackButtonmage = styled.img`
  position: absolute;
  cursor: pointer;
  height: 3.6rem;
  width: 3.6rem;
  top: 6rem;
  left: 2rem;
  z-index: 2;
`;

