import React, { useContext } from 'react';
import Button from '../../../../../../components/Button/Button';
import Modal from '../../../../../../components/JSModal';
import { PURCHASE_RESPONSE } from 'shared/constants';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import {
  ContentWrapper,
  DividerImage,
  errorModalMainBodyStyle,
  IconWrapper,
  MainContainer,
  MessageContainer,
  MessageIcon,
  modalStyle,
  successModalMainBodyStyle,
} from './styledComponents';
import ThemeContext from 'shared/context/ThemeContext';

const PurchaseResponsePopUp = ({
  purchaseResponse,
  onDismissPurchaseResponse,
  setIsPurchaseButtonDisabled,
  rewardCenterAssets,
}) => {
  const RENDER_CONFIG = {
    [PURCHASE_RESPONSE.SUCCESS]: {
      headerText: getText(TEXT_KEY.CONGRATULATIONS),
      iconSource: rewardCenterAssets.REWARD_CENTER_PURCHASE_SUCCESS,
      buttonText: getText(TEXT_KEY.DONE),
      modalStyle: successModalMainBodyStyle,
    },
    [PURCHASE_RESPONSE.ERROR]: {
      headerText: getText(TEXT_KEY.OOPS),
      buttonText: getText(TEXT_KEY.OK),
      modalStyle: errorModalMainBodyStyle,
    },
  };

  const renderConfig = RENDER_CONFIG[purchaseResponse.status] || {};

  const handleDismissPurchaseResponse = () => {
    onDismissPurchaseResponse();
    setIsPurchaseButtonDisabled(false);
  };

  const themeContext = useContext(ThemeContext);

  return (
    <Modal
      isVisible={true}
      onBackButtonPress={() => onDismissPurchaseResponse(null)}
      style={modalStyle}
      modalMainBodyStyle={renderConfig.modalStyle}
      hideCloseButton
    >
      <MainContainer>
        <MessageContainer>
          <div style={themeContext.OfferDetail.RewardCenterPurchaseResponseHeader}>{renderConfig.headerText}</div>
          <ContentWrapper>
            <DividerImage
              source={require(asset`Shop_VIP_Divider_Glows.png`)}
              ibw={require(asset`Shop_VIP_Divider_Glows.ibw`)}
              innerStyle={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-evenly',
                alignItems: 'center',
                paddingLeft: '1vmin',
                paddingRight: '1vmin',
              }}
            />
            {renderConfig.iconSource && (
              <IconWrapper>
                <MessageIcon alt="" src={renderConfig.iconSource} />
              </IconWrapper>
            )}
            <div style={themeContext.OfferDetail.RewardCenterPurchaseResponseMessage}>{purchaseResponse.message}</div>
            <Button
              onClick={handleDismissPurchaseResponse}
              containerStyle={{
                margin: '1em auto',
                position: 'relative',
                textAlign: 'center',
                cursor: 'pointer',
                pointerEvents: 'auto',
                width: '10em',
                height: '14%',
                paddingBottom: purchaseResponse.status === PURCHASE_RESPONSE.SUCCESS ? '2.4em' : 0,
                paddingTop: purchaseResponse.status === PURCHASE_RESPONSE.SUCCESS ? 0 : '3.4em',
              }}
              imageStyle={{ width: '100%', height: '100%' }}
              imageSource={require(asset`RoundedRect_Primary@3x.png`)}
              label={renderConfig.buttonText}
            />
          </ContentWrapper>
        </MessageContainer>
      </MainContainer>
    </Modal>
  );
};

export default PurchaseResponsePopUp;

