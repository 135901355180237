import React, { useContext } from 'react';
import ImageBW from '../../../../components/ImageBoundsWrapper';
import Button from '../../../../components/Button/Button';
import { dailyWheelCollectButtonFontSizeCalc } from 'shared/utils/fontSizeCalculator';
import ThemeContext from 'shared/context/ThemeContext';
import { useSelector } from 'shared/node_modules/react-redux';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import { BUTTON_STROKES } from 'shared/utils/buttonTextStrokes';
import BottomBarLeftSide from './LeftSide';
import {
  BottomBarStyles,
  BottomBarContainer,
  BottomRightSideContainer,
  BottomRightContentWrapper,
  BottomTotalBonusWrapper,
  BottomTotalBonusImage,
  BottomRightCoinImage,
  BottomRightItemsText,
  BottomBarRightPlusText,
  BottomRightLoyaltyImage,
  BottomBarRightSideBottomText,
  BottomBarBackgroundWrapper,
  BottomBarBackgroundWrapperImage,
} from '../styledComponents';

export default function BottomBar(props) {
  const themeContext = useContext(ThemeContext);
  const dailyWheelCard = useSelector(state => state.properties.data.dailyWheelCard);
  const dailyWheelAssets = useSelector(state => state.assetsPackage.assets.DAILY_WHEEL);
  const assetsUrls = useSelector(state => state.app.assetsUrls);

  const {
    bottomBarAnimDeg,
    wheelBonus,
    dailyBonus,
    isPhysicallyVerified,
    linkedAccount,
    currentTierBonus,
    showTotalCoins,
    totalCoinsAfterTierBonus,
    totalLoyalty,
    showTotalLoyalty,
    nextStepHandler,
    isLoading,
    step,
  } = props;

  const cardIconPath = dailyWheelCard && linkedAccount ? assetsUrls.ImagesCdnBaseUrl + '/' + dailyWheelCard : null;

  return (
    <BottomBarContainer
      theme={themeContext.DailySpin.BottomBar}
      style={{
        transform: [
          {
            translateY: bottomBarAnimDeg,
          },
        ],
      }}
    >
      <BottomBarBackgroundWrapper>
        <BottomBarBackgroundWrapperImage
          theme={themeContext.DailySpin.BottomBarBackgroundWrapperImage}
          src={dailyWheelAssets.DAILY_SPIN_BOTTOM_BACKGROUND}
        />
        <div style={themeContext.DailySpin.BottomBarContainer}>
          <BottomBarLeftSide
            dailyWheelAssets={dailyWheelAssets}
            step={step}
            wheelBonus={wheelBonus}
            dailyBonus={dailyBonus}
            linkedAccount={linkedAccount}
            currentTierBonus={currentTierBonus}
            cardIconPath={cardIconPath}
          />
          <BottomRightSideContainer>
            <ImageBW
              source={dailyWheelAssets.DAILY_SPIN_BOTTOM_BOX}
              ibw={require(asset`images/Daily_Spin/Bottom_Inset.ibw`)}
              style={BottomBarStyles.bottomRightWrapper}
              innerStyle={BottomBarStyles.bottomRightWrapperInner}
              imageStyle={themeContext.DailySpin.BottomInsetImage}
            >
              <BottomRightContentWrapper>
                <BottomTotalBonusWrapper>
                  <BottomTotalBonusImage src={dailyWheelAssets.DAILY_SPIN_TEXT_TOTAL} alt="TOTAL BONUS" />
                </BottomTotalBonusWrapper>
                {showTotalCoins && (
                  <div
                    className={`${
                      step === 2
                        ? isPhysicallyVerified
                          ? 'TotalBonusAnimationVerified'
                          : 'TotalBonusAnimationNotVerified'
                        : 'TotalBonusSmall'
                    }`}
                  >
                    <BottomRightCoinImage
                      theme={themeContext.DailySpin.BottomRightCoinImage}
                      src={icons.coin}
                      alt="Currency"
                    />
                    <BottomRightItemsText data-label={totalCoinsAfterTierBonus}>
                      {totalCoinsAfterTierBonus}
                    </BottomRightItemsText>
                  </div>
                )}
                {showTotalCoins && showTotalLoyalty && (
                  <BottomBarRightPlusText>{getText(TEXT_KEY.PLUS_SYMBOL)}</BottomBarRightPlusText>
                )}
                {showTotalLoyalty && (
                  <div
                    className={`${
                      step === 2
                        ? isPhysicallyVerified
                          ? 'TotalBonusAnimationVerified'
                          : 'TotalBonusAnimationNotVerified'
                        : 'TotalBonusSmall'
                    }`}
                  >
                    <BottomRightLoyaltyImage src={icons.loyalty} alt="Loyalty" />
                    <BottomRightItemsText left={'0px'} data-label={totalLoyalty}>
                      {totalLoyalty}
                    </BottomRightItemsText>
                  </div>
                )}
              </BottomRightContentWrapper>
              <Button
                imageSource={
                  !isLoading ? require(asset`RoundedRect_Primary@3x.png`) : require(asset`RoundedRect_Disabled@3x.png`)
                }
                label={getText(TEXT_KEY.COLLECT_BUTTON)}
                isDebounce={true}
                textStyle={dailyWheelCollectButtonFontSizeCalc(getText(TEXT_KEY.COLLECT_BUTTON))}
                containerStyle={BottomBarStyles.bottomCollectButtonContainer}
                imageStyle={BottomBarStyles.bottomCollectButton}
                disabled={isLoading}
                onClick={() => nextStepHandler(1)}
                textStroke={BUTTON_STROKES.PRIMARY}
              />
            </ImageBW>
            <BottomBarRightSideBottomText
              theme={themeContext.DailySpin.BottomBarBottomText}
              src={dailyWheelAssets.DAILY_SPIN_TEXT_LOGIN}
            />
          </BottomRightSideContainer>
        </div>
      </BottomBarBackgroundWrapper>
    </BottomBarContainer>
  );
}

const icons = {
  coin: require(asset`images/Coin_Assets/Coin_Icon@3x.png`),
  loyalty: require(asset`Star_Icon@3x.png`),
};

