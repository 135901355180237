import _defineProperty from "/var/jenkins/workspace/Web Clients and Images - Production/Code/WEB CLIENT - GTP 2.0 Deploy/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var LOGIN_ERROR_CODE = {
  APP_INTERFACE_INIT_APP_CATCH: 1,
  REQUEST_HELPER_API_REQUEST_CATCH: 2,
  REQUEST_HELPER_API_REQUEST_SET_RESTART_SESSION: 3,
  REQUEST_HELPER_ALLOW_ARRAY_API_REQUEST_CATCH: 4,
  REQUEST_HELPER_ALLOW_SET_API_REQUEST_RESTART_SESSION: 5,
  LOGIN_INTERFACE_RESTART_SESSION_CATCH: 6,
  LOGIN_INTERFACE_LOGIN_CATCH: 7,
  LOGIN_INTERFACE_APPLE_LOGIN_CATCH: 8,
  LOGIN_INTERFACE_GOOGLE_LOGIN_CATCH: 9,
  LOGIN_INTERFACE_FB_LOGIN_CATCH: 10,
  LOGIN_INTERFACE_GUEST_LOGIN_CATCH: 11,
  RESTART_SESSION_LOGIN_ERROR_HANDLER: 12,
  RESTART_SESSION_LOGOUT_USER: 13,
  FACEBOOK_SDK_INIT_CATCH: 43,
  FETCH_DAILY_WHEEL_CATCH: 47,
  TC_STAR_GOOGLE_RENDER_BUTTON_ERROR: 60
};
var ODR_ERROR_CODE = {
  ODR_DOWNLOAD_RESOURCE_CATCH: 14,
  ODR_WEBVIEW_LOADING_ERROR: 15,
  ODR_GET_PATH_IS_AVAILABLE_CATCH: 16,
  ODR_SET_HIGH_PRESERVATION_PRIORITY_CATCH: 17,
  ODR_SET_LOW_PRESERVATION_PRIORITY_CATCH: 18
};
var DIVE_SDK_ERROR_CODE = {
  DIVE_REMOTE_CONFIG_FAIL: 19,
  DIVE_JSON_PARSE_FAIL: 23
};
var JSONSCHEMA_ERROR_CODE = {
  JSONSCHEMA_VALIDATION_FAIL: 20,
  JSONSCHEMA_ERRORS_SKIPED: 22
};
var SAGAS_RESPONSE_ERROR_CODE = {
  SAGAS_RESPONSE_FAIL: 21
};
var APP_INTERFACE_ERROR_CODE = {
  UPDATE_APP_EVENTS_FAIL: 24,
  GET_URLS_FAIL: 25
};
var BONUS_INTERFACE_ERROR_CODE = {
  GET_DAILY_BONUS_FAIL: 26,
  CLAIM_DAILY_BONUS_FAIL: 27,
  CLAIM_TIMED_BONUS_FAIL: 28,
  SUBMIT_BONUSABLE_LINK_FAIL: 29,
  COLLECT_WELCOME_BONUS_FAIL: 30,
  DAILY_SPIN_GET_BONUS_INFO_ERROR: 47
};
var REQUEST_HELPER_ERROR_CODE = {
  API_REQUEST_WITH_EXTRAS_FAIL: 31
};
var GAME_INTERFACE_ERROR_CODE = {
  LOBBY_OVERLAY_FETCH_GAME_ROOMS_FAIL: 32
};
var PURCHASE_MOBILE_FLOW_ERROR = {
  PURCHASE_ERROR_HANDLER_LISTENER: 33,
  PURCHASE_ERROR_BUY_HANDLE: 34,
  PURCHASE_ERROR_FINISH_PURCHASE: 35,
  PURCHASE_ERROR_CANCELED: 36,
  PURCHASE_CONNECTION: 37,
  PURCHASE_ITEM_OWNED: 38,
  PURCHASE_ITEM_SKU_EMPTY: 39,
  PURCHASE_RECEIPT_UNAVAILABLE: 40
};
var PROPERTIES_ERROR_CODE = {
  GET_TEXTS_FAIL: 41
};
var QUERY_RESPONSE_ERROR_CODE = {
  QUERY_RESPONSE_ERROR: 42
};
var SIGNUP_ERROR_CODE = {
  SIGNUP_ERROR: 44
};
var ACTIONS_COMANDS_ERROR_CODE = {
  ACTIONS_COMANDS_VALIDATION_ERROR: 45,
  ACTIONS_COMANDS_ASSETS_ERROR: 46
};
var GET_LOTTIE_ANIMATIONS_ERROR_CODE = {
  LOTTIE_ANIMATION_DOWNLOAD_ERROR: 61,
  LOTTIE_ANIMATION_STORE_ERROR: 62,
  LOTTIE_ANIMATION_RETRIEVE_ERROR: 63,
  LOTTIE_ANIMATION_OPEN_IDB_ERROR: 64
};
var REWARD_CENTER_ERROR_CODE = {
  INIT_REWARD_CENTER_REQUEST_ERROR: 49,
  GET_REWARD_DETAILS_REQUEST_ERROR: 68,
  PURCHASE_OFFER_REQUEST_ERROR: 69,
  GET_REWARD_HISTORY_REQUEST_ERROR: 70,
  GET_OFFER_HISTORY_DETAILS_REQUEST_ERROR: 71,
  SEND_OFFER_EMAIL_REQUEST_ERROR: 72
};
var TEXTS_ERROR_CODE = {
  TEXTS_ERROR: 48,
  TERMS_AND_CONDITIONS_TEXTS_ERROR: 65,
  TERMS_AND_CONDITIONS_TEXTS_BLOCKS_ERROR: 66,
  PRIVACY_POLICTY_TEXTS_ERROR: 67
};
var SHOP_INTERFACE_ERROR_CODE = {
  GET_BILLING_PROFILES_ERROR: 50,
  GET_ADDRESS_FOR_PURCHASE_ERROR: 51,
  GET_CURRENCY_PACKAGES_ERROR: 52,
  REMOVE_BILLING_PROFILE_ERROR: 53,
  FINISH_WEB_PURCHASE_ERROR: 54,
  CHECK_PROMO_CODE_ERROR: 55,
  PURCHASE_WITH_CASINO_DOLLARS_ERROR: 56,
  REFRESH_DTS_ERROR: 57,
  REFRESH_ACTIVE_CONSUMABLES_ERROR: 58,
  CLAIM_FREE_CONSUMABLE_ERROR: 59
};

var ERROR_CODE = _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, LOGIN_ERROR_CODE), ODR_ERROR_CODE), DIVE_SDK_ERROR_CODE), JSONSCHEMA_ERROR_CODE), SAGAS_RESPONSE_ERROR_CODE), APP_INTERFACE_ERROR_CODE), BONUS_INTERFACE_ERROR_CODE), REQUEST_HELPER_ERROR_CODE), GAME_INTERFACE_ERROR_CODE), PROPERTIES_ERROR_CODE), PURCHASE_MOBILE_FLOW_ERROR), QUERY_RESPONSE_ERROR_CODE), SIGNUP_ERROR_CODE), TEXTS_ERROR_CODE), ACTIONS_COMANDS_ERROR_CODE), GET_LOTTIE_ANIMATIONS_ERROR_CODE), SHOP_INTERFACE_ERROR_CODE), REWARD_CENTER_ERROR_CODE);

export default ERROR_CODE;