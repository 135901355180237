import _defineProperty from "/var/jenkins/workspace/Web Clients and Images - Production/Code/WEB CLIENT - GTP 2.0 Deploy/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import { REWARD_CENTER_OFFERS_STATUS } from '../constants/dictionary';

var generateFrame = function generateFrame(rewardCenterAssets, ribbontype) {
  return ribbontype === REWARD_CENTER_OFFERS_STATUS.SOLD_OUT ? rewardCenterAssets.REWARD_CENTER_FRAME_CARD_DISABLED : rewardCenterAssets.REWARD_CENTER_FRAME_CARD;
};

var generateBanner = function generateBanner(rewardCenterAssets) {
  var _ref;

  return _ref = {}, _defineProperty(_ref, REWARD_CENTER_OFFERS_STATUS.EXCLUSIVE, rewardCenterAssets.REWARD_CENTER_RIBBON_EXCLUSIVE), _defineProperty(_ref, REWARD_CENTER_OFFERS_STATUS.FEATURED, rewardCenterAssets.REWARD_CENTER_RIBBON_FEATURED), _defineProperty(_ref, REWARD_CENTER_OFFERS_STATUS.HOT, rewardCenterAssets.REWARD_CENTER_RIBBON_HOT), _defineProperty(_ref, REWARD_CENTER_OFFERS_STATUS.LIMITED, rewardCenterAssets.REWARD_CENTER_RIBBON_LIMITED), _defineProperty(_ref, REWARD_CENTER_OFFERS_STATUS.NEW, rewardCenterAssets.REWARD_CENTER_RIBBON_NEW), _defineProperty(_ref, REWARD_CENTER_OFFERS_STATUS.SOLD_OUT, rewardCenterAssets.REWARD_CENTER_RIBBON_SOLD_OUT), _ref;
};

var generateShadow = function generateShadow(rewardCenterAssets, ribbontype) {
  return ribbontype === REWARD_CENTER_OFFERS_STATUS.SOLD_OUT ? rewardCenterAssets.REWARD_CENTER_FRAME_CARD_SHADOW : null;
};

var generateLoyaltyIcon = function generateLoyaltyIcon(rewardCenterAssets, ribbontype) {
  return ribbontype === REWARD_CENTER_OFFERS_STATUS.SOLD_OUT ? rewardCenterAssets.REWARD_CENTER_LOYALTY_ICON_DISABLED : rewardCenterAssets.REWARD_CENTER_LOYALTY_ICON;
};

var generateCoinsIcon = function generateCoinsIcon(rewardCenterAssets, ribbontype) {
  return ribbontype === REWARD_CENTER_OFFERS_STATUS.SOLD_OUT ? rewardCenterAssets.REWARD_CENTER_COINS_ICON_DISABLED : rewardCenterAssets.REWARD_CENTER_COINS_ICON;
};

var generateRewardCenterCardDataRendering = function generateRewardCenterCardDataRendering(_ref2, rewardCenterAssets) {
  var ribbontype = _ref2.ribbontype;
  return {
    Frame: generateFrame(rewardCenterAssets, ribbontype),
    Banner: generateBanner(rewardCenterAssets)[ribbontype],
    Shadow: generateShadow(rewardCenterAssets, ribbontype),
    Loyalty: generateLoyaltyIcon(rewardCenterAssets, ribbontype),
    Coins: generateCoinsIcon(rewardCenterAssets, ribbontype)
  };
};

export default generateRewardCenterCardDataRendering;