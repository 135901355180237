export var ACTION_COMMANDS_EVENT_TYPE = {
  /* general events for our popup */
  DISPLAY_APP_MODAL: 'DISPLAY_APP_MODAL',
  DISMISS_APP_MODAL: 'DISMISS_APP_MODAL',
  ACCEPT_APP_MODAL: 'ACCEPT_APP_MODAL',

  /* notifications events for the native (iOS/Android) popup */
  DISPLAY_NATIVE_MODAL: 'DISPLAY_NATIVE_MODAL',
  DISMISS_NATIVE_MODAL: 'DISMISS_NATIVE_MODAL',
  ACCEPT_NATIVE_MODAL: 'ACCEPT_NATIVE_MODAL',

  /* link account events for the action commands */
  SIGN_IN_EMAIL_APP_MODAL: 'SIGN_IN_EMAIL_APP_MODAL',
  SIGN_IN_FACEBOOK_APP_MODAL: 'SIGN_IN_FACEBOOK_APP_MODAL',
  SIGN_IN_GOOGLE_APP_MODAL: 'SIGN_IN_GOOGLE_APP_MODAL',
  SIGN_IN_APPLE_APP_MODAL: 'SIGN_IN_APPLE_APP_MODAL',

  /* tooltips events for the action commands */
  DISPLAY_APP_TOOLTIP: 'DISPLAY_APP_TOOLTIP'
};