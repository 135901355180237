import _defineProperty from "/var/jenkins/workspace/Web Clients and Images - Production/Code/WEB CLIENT - GTP 2.0 Deploy/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _slicedToArray from "/var/jenkins/workspace/Web Clients and Images - Production/Code/WEB CLIENT - GTP 2.0 Deploy/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import QueryParamProxy from '../../../utils/queryParamProxy';
import audio from '../../../utils/audio';
import routeParamConstants from '../../../utils/routeParamConstants';
import QueryParamsProxy from '../../../utils/queryParamProxy';
import Screens from '../../../screens';
import { REWARD_CENTER_TABS } from '../constants';

var useRewardCenterProfile = function useRewardCenterProfile(_ref) {
  var profile = _ref.profile,
      setShowProfile = _ref.setShowProfile,
      rewardCenterAssets = _ref.rewardCenterAssets;

  var _useState = useState(null),
      _useState2 = _slicedToArray(_useState, 2),
      interfaceData = _useState2[0],
      setInterfaceData = _useState2[1];

  var _useState3 = useState(null),
      _useState4 = _slicedToArray(_useState3, 2),
      activeHistoryItem = _useState4[0],
      setActiveHistoryItem = _useState4[1];

  var _useSelector = useSelector(function (state) {
    return {
      enableOrderCard: state.properties.data.enableOrderCard,
      showLinkLoyaltyCardSetting: state.properties.data.showLinkLoyaltyCardSetting,
      copys: state.properties.data.textsFile,
      propertyName: state.playerInfo.propertyName,
      playerId: state.playerInfo.playerId
    };
  }),
      enableOrderCard = _useSelector.enableOrderCard,
      showLinkLoyaltyCardSetting = _useSelector.showLinkLoyaltyCardSetting,
      copys = _useSelector.copys,
      propertyName = _useSelector.propertyName,
      playerId = _useSelector.playerId;

  var _ref2 = profile !== null && profile !== void 0 ? profile : {},
      linkedCasinoId = _ref2.linkedCasinoId,
      displayRewardProfileScreen = _ref2.displayRewardProfileScreen,
      displayRewardHistoryScreen = _ref2.displayRewardHistoryScreen,
      displayRewardLoyaltyScreen = _ref2.displayRewardLoyaltyScreen,
      loyaltyExpiryDate = _ref2.loyaltyExpiryDate,
      _ref2$playerProfileIn = _ref2.playerProfileInfo;

  _ref2$playerProfileIn = _ref2$playerProfileIn === void 0 ? {} : _ref2$playerProfileIn;
  var memberLevel = _ref2$playerProfileIn.memberLevel,
      balances = _ref2$playerProfileIn.balances;
  var profileBalances = useMemo(function () {
    return linkedCasinoId && balances && Object.values(balances);
  }, [linkedCasinoId, balances]);
  var profileTabs = [{
    key: REWARD_CENTER_TABS.PROFILE,
    selectedImage: rewardCenterAssets.REWARD_CENTER_MENU_TABS_MY_PROFILE_SELECTED,
    defaultImage: rewardCenterAssets.REWARD_CENTER_MENU_TABS_MY_PROFILE,
    show: displayRewardProfileScreen && showLinkLoyaltyCardSetting
  }, {
    key: REWARD_CENTER_TABS.HISTORY,
    selectedImage: rewardCenterAssets.REWARD_CENTER_MENU_TABS_MY_HISTORY_SELECTED,
    defaultImage: rewardCenterAssets.REWARD_CENTER_MENU_TABS_MY_HISTORY,
    show: displayRewardHistoryScreen
  }, {
    key: REWARD_CENTER_TABS.LOYALTY_POINTS,
    selectedImage: rewardCenterAssets.REWARD_CENTER_MENU_TABS_LOYALTY_POINTS_SELECTED,
    defaultImage: rewardCenterAssets.REWARD_CENTER_MENU_TABS_LOYALTY_POINTS,
    show: displayRewardLoyaltyScreen && loyaltyExpiryDate
  }];

  var handleCheckBenefits = function handleCheckBenefits() {
    var _QueryParamsProxy$set;

    QueryParamsProxy.setQueryParams((_QueryParamsProxy$set = {}, _defineProperty(_QueryParamsProxy$set, routeParamConstants.PARAM_MODAL, Screens.LinkAccount), _defineProperty(_QueryParamsProxy$set, routeParamConstants.PARAM_RC_PROFILE_LINK_BENEFITS, true), _QueryParamsProxy$set));
  };

  var handleLinkAccountForm = function handleLinkAccountForm() {
    setShowProfile(false);
    QueryParamProxy.setQueryParams(_defineProperty({}, routeParamConstants.PARAM_MODAL, Screens.LinkAccountForm));
  };

  var handleRegisterRewardCard = function handleRegisterRewardCard() {
    setShowProfile(false);
    QueryParamProxy.setQueryParams(_defineProperty({}, routeParamConstants.PARAM_MODAL, Screens.RegisterRewardCard));
  };

  var handleSetActiveHistoryItem = function handleSetActiveHistoryItem(historyItem) {
    var prevTab = interfaceData === null || interfaceData === void 0 ? void 0 : interfaceData.activeTab;
    setActiveHistoryItem(historyItem);
    setActiveTab(REWARD_CENTER_TABS.HISTORY_DETAIL);
    setPrevTab(prevTab);
  };

  var setActiveTab = function setActiveTab(newTab) {
    audio.onClick();
    setInterfaceData(function (prevState) {
      return _objectSpread(_objectSpread({}, prevState), {}, {
        activeTab: newTab
      });
    });
  };

  var setPrevTab = function setPrevTab(prevTab) {
    setInterfaceData(function (prevState) {
      return _objectSpread(_objectSpread({}, prevState), {}, {
        prevTab: prevTab
      });
    });
  };

  return {
    interfaceData: interfaceData,
    showLinkLoyaltyCardSetting: showLinkLoyaltyCardSetting,
    copys: copys,
    propertyName: propertyName || '',
    memberLevel: memberLevel,
    profileBalances: profileBalances,
    showBalances: linkedCasinoId && profileBalances,
    enableOrderCard: enableOrderCard,
    activeHistoryItem: activeHistoryItem,
    profileTabs: profileTabs,
    handleLinkAccountForm: handleLinkAccountForm,
    handleRegisterRewardCard: handleRegisterRewardCard,
    setActiveTab: setActiveTab,
    setPrevTab: setPrevTab,
    setActiveHistoryItem: handleSetActiveHistoryItem,
    handleCheckBenefits: handleCheckBenefits,
    playerId: playerId
  };
};

export default useRewardCenterProfile;