import React from 'react';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import Button from '../../../../../components/Button/Button';
import ENVIRONMENT_VARIABLES from 'shared/utils/environmentVariables';
import { getDecimalAmount } from 'shared/utils/number';
import theme from 'shared/assets/style/theme';
import Utils from 'shared/utils';
import styled from 'styled-components';

const HistoryList = ({ history, onViewDetails, rewardCenterAssets }) => {
  const displayDetailsColumn = history.some(item => item.showDetailsButton === true);
  return (
    <>
      <div style={{ display: 'flex', width: '100%', flexDirection: 'column', height: '95%' }}>
        <div
          className="HistoryHeadings"
          style={{
            backgroundImage: `url(${rewardCenterAssets.REWARD_CENTER_MENU_HISTORY_BANNER})`,
            zIndex: -100,
            backgroundSize: '100% 100%',
          }}
        >
          {ENVIRONMENT_VARIABLES.REWARD_CENTER_QR_LAYOUT === 'true' ? (
            <>
              <div className="HistoryHeadingStar HistoryItemOverview">{getText(TEXT_KEY.OFFER)}</div>
              <div className="HistoryHeadingStar HistoryItemStatus">{getText(TEXT_KEY.STATUS)}</div>
              <div className="HistoryHeadingStar HistoryItemDate">{getText(TEXT_KEY.REDEMPTION_DATE)}</div>
              <div className="HistoryHeadingStar Remaining">{getText(TEXT_KEY.REMAINING)}</div>
              {displayDetailsColumn && <div className="HistoryHeadingStar HistoryItemDetailsAction"></div>}
            </>
          ) : (
            <>
              <div className="HistoryHeading HistoryItemOverview">{getText(TEXT_KEY.OFFER)}</div>
              <div className="HistoryHeading HistoryItemStatus">{getText(TEXT_KEY.STATUS)}</div>
              <div className="HistoryHeading HistoryItemDate">{getText(TEXT_KEY.REDEMPTION_DATE)}</div>
              {displayDetailsColumn && <div className="HistoryHeading HistoryItemDetailsAction"></div>}
            </>
          )}
        </div>

        <ScrollContainer>
          {history?.map((historyItem, index) => (
            <div
              key={`HistoryItem-${historyItem.rpid}`}
              className={
                ENVIRONMENT_VARIABLES.REWARD_CENTER_QR_LAYOUT === 'true'
                  ? 'HistoryItemStar ' + (index % 2 === 0 ? 'even' : 'odd')
                  : 'HistoryItem ' + (index % 2 === 0 ? 'even' : 'odd')
              }
            >
              <div className="HistoryItemOverview">
                <img
                  alt=""
                  className={'RewardCenterHistoryCategoryIcon' + (Utils.ieVersion() !== 0 ? ' ieHack' : '')}
                  src={historyItem.cat_icon}
                />
                <div className="HistoryItemName" style={{ alignSelf: 'center' }}>
                  {historyItem.desc}
                </div>
              </div>
              <div className="HistoryItemStatus">{historyItem.state}</div>
              <div className="HistoryItemDate">{historyItem.date}</div>
              {ENVIRONMENT_VARIABLES.REWARD_CENTER_QR_LAYOUT === 'true' && (
                <div className="HistoryItemRemaining">
                  {historyItem.state !== 'expired' ? getDecimalAmount(historyItem.amountLeft || 0) : '-'}
                </div>
              )}
              {historyItem.showDetailsButton && (
                <div className="HistoryItemDetailsAction">
                  <Button
                    containerStyle={{ position: 'relative' }}
                    imageSource={require(asset`RoundedRect_Danger@3x.png`)}
                    onClick={() => onViewDetails(historyItem)}
                    imageStyle={{ width: '75%', height: '100%' }}
                    textStyle={{ padding: '2%' }}
                    label={getText(TEXT_KEY.DETAILS)}
                  />
                </div>
              )}
            </div>
          ))}
        </ScrollContainer>
      </div>
    </>
  );
};

const ScrollContainer = styled.div`
  margin-right: 2px;
  margin-top: 0;
  margin-bottom: 4px;
  width: 100%;
  height: 100%;

  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-track {
    background-color: ${theme.palette.secondary[27]};
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${theme.palette.primary[34]};
    border-radius: 10px;
  }

  scrollbar-width: thin;
  scrollbar-color: ${theme.palette.primary[34]} ${theme.palette.secondary[27]};
`;

export default HistoryList;

