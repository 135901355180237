import React, { useState } from 'react';
import styled from 'styled-components';
import CategoriesClosed from './Categories/CategoriesClosed';
import CategoriesOpened from './Categories/CategoriesOpened';

const RewardCenterMenu = props => {
  const { rewardCenterAssets, setShowProfile, profile } = props;
  const { displayRewardProfileScreen, displayRewardHistoryScreen, displayRewardLoyaltyScreen } = profile;

  const [openCategories, setOpenCategories] = useState(false);

  const displayMyProfile = displayRewardProfileScreen || displayRewardHistoryScreen || displayRewardLoyaltyScreen;
  return (
    <Container>
      <ProfileClickable onClick={() => (displayMyProfile ? setShowProfile(true) : null)}>
        {displayMyProfile && <ProfileIcon src={rewardCenterAssets.REWARD_CENTER_PROFILE_ICON} />}
      </ProfileClickable>
      <CategoriesClickable onClick={() => setOpenCategories(!openCategories)}>
        {!openCategories ? (
          <CategoriesClosed rewardCenterAssets={rewardCenterAssets} />
        ) : (
          <CategoriesOpened {...props} />
        )}
      </CategoriesClickable>
    </Container>
  );
};

export default RewardCenterMenu;

const Container = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;
  z-index: 2;
`;

const ProfileClickable = styled.div`
  position: relative;
  width: 10rem;
  height: 10rem;
  right: 12rem;
  padding-bottom: 2rem;
  @media only screen and (max-height: 800px) {
    right: 7rem;
  }
  @media only screen and (min-height: 800px) and (max-height: 900px) {
    right: 9rem;
  }
  @media only screen and (min-height: 955px) and (max-height: 1000px) {
    right: 6rem;
  }
`;

const ProfileIcon = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  cursor: pointer;
`;

const CategoriesClickable = styled.div`
  height: 60%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 30%;
  position: relative;
  right: 22rem;
  @media only screen and (min-width: 2000px) {
    width: 25%;
  }
  @media only screen and (max-height: 800px) {
    right: 16rem;
  }
  @media only screen and (min-height: 800px) and (max-height: 900px) {
    right: 18.5rem;
  }
  @media only screen and (min-height: 955px) and (max-height: 1000px) {
    right: 15.5rem;
  }
`;

