import _slicedToArray from "/var/jenkins/workspace/Web Clients and Images - Production/Code/WEB CLIENT - GTP 2.0 Deploy/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useGetEventsQuery, usePostEventsMutation } from '../../../state/query/events';
import { sendEventsEvent } from '../analytics/eventsLogger';
import { EVENTS_ACTIONS, EVENTS_EVENT_TYPES } from '../constants';
import { getTimestamp } from '../../../utils/TimestampManager';
import { FE_EVENTS } from '../../../utils/featureFlags/constants';
import useFeatureFlags from '../../../hooks/useFeatureFlags';
import useNavState from '../../../hooks/useNavState';

var useEvents = function useEvents() {
  var _data$eventsFeatureSt3;

  var _useFeatureFlags = useFeatureFlags(),
      hasFeature = _useFeatureFlags.hasFeature;

  if (!hasFeature(FE_EVENTS)) return eventsFeatureStateDefault;
  useNavState();

  var _useSelector = useSelector(function (state) {
    return state.playerInfo;
  }),
      playerId = _useSelector.playerId;

  var _useState = useState(0),
      _useState2 = _slicedToArray(_useState, 2),
      pollingInterval = _useState2[0],
      setPollingInterval = _useState2[1];

  var _useGetEventsQuery = useGetEventsQuery({
    requestAssetsData: true,
    playerId: playerId
  }, {
    pollingInterval: pollingInterval,
    forceRefetch: true
  }),
      data = _useGetEventsQuery.data;

  var _usePostEventsMutatio = usePostEventsMutation(),
      _usePostEventsMutatio2 = _slicedToArray(_usePostEventsMutatio, 1),
      postEvents = _usePostEventsMutatio2[0];

  useEffect(function () {
    sendEventsEvent(EVENTS_EVENT_TYPES.ENTER_EVENTS_SCREEN);
  }, []);

  var handlePollingInterval = function handlePollingInterval() {
    var _data$events;

    var endtimestamp = [];
    data && ((_data$events = data.events) === null || _data$events === void 0 ? void 0 : _data$events.forEach(function (item) {
      return endtimestamp.push(item.endTimestamp);
    }));
    setPollingInterval(getTimestamp(Math.min.apply(Math, endtimestamp)));
  };

  useEffect(handlePollingInterval, [data]);
  useEffect(function () {
    var _data$eventsFeatureSt, _data$eventsFeatureSt2;

    var eventIds = data === null || data === void 0 ? void 0 : (_data$eventsFeatureSt = data.eventsFeatureState) === null || _data$eventsFeatureSt === void 0 ? void 0 : (_data$eventsFeatureSt2 = _data$eventsFeatureSt.events) === null || _data$eventsFeatureSt2 === void 0 ? void 0 : _data$eventsFeatureSt2.map(function (event) {
      return event.eventId;
    }).join(',');
    postEvents({
      action: EVENTS_ACTIONS.NOTIFY_EVENT_DISPLAYED,
      eventIds: eventIds,
      playerId: playerId
    });
  }, [data, playerId, postEvents]);
  return {
    eventsFeatureState: (_data$eventsFeatureSt3 = data === null || data === void 0 ? void 0 : data.eventsFeatureState) !== null && _data$eventsFeatureSt3 !== void 0 ? _data$eventsFeatureSt3 : eventsFeatureStateDefault.eventsFeatureState
  };
};

export default useEvents;
var eventsFeatureStateDefault = {
  eventsFeatureState: {
    events: [],
    assetsPackageId: null,
    texts: null
  }
};