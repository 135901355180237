import React, { Component } from 'react';
import SHome from 'shared/screens/Home';
import Utils from 'shared/utils';
import GamePage from '../../components/GamePage';
import audio from 'shared/utils/audio';
import { MissionTutorialStepsKeys, shouldChangeTutorialStep } from 'shared/utils/tutorial';
import { GameListWrapper } from './styledComponents';

/** @typedef {import('shared/api/Constants').LivePanelInfo} LivePanelInfo */
/** @typedef {import('shared/utils/LivePanels').LivePanelLayoutInfo} LivePanelLayoutInfo */

export default class Home extends Component {
  state = {};
  slider = React.createRef();

  updateTutorialStep = () => {
    const { tutorialStep, isTutorialActive, setTutorialStep } = this.props;
    isTutorialActive && shouldChangeTutorialStep(tutorialStep) && setTutorialStep(MissionTutorialStepsKeys.CHECK_OUT);
  };

  componentDidMount = () => {
    this.updateTutorialStep();
  };

  componentDidUpdate = () => {
    this.updateTutorialStep();
  };

  handleNextClick = () => {
    audio.onClick();
    this.slider.current.slickNext();
  };

  handlePrevClick = () => {
    audio.onClick();
    this.slider.current.slickPrev();
  };

  handleFirstClick = () => {
    audio.onClick();
    this.slider.current.slickGoTo(0);
  };

  handleLastClick = pagesCount => {
    audio.onClick();
    this.slider.current.slickGoTo(pagesCount);
  };

  render() {
    return (
      <SHome
        navigation={this.props.navigation}
        {...this.props}
        render={
          /**
           * @param {LivePanelLayoutInfo} args
           */
          args => {
            const { pagedGames, lobbyPromotions } = args;
            const hasPromotions = !Utils.isEmpty(lobbyPromotions);
            return (
              <div className="roomPage">
                <div className="roomContent">
                  <div className="NavigationArrowsContainer left">
                    <img
                      src={require(asset`images/Lobby_Assets/Scroll_Buttons@3x.png`)}
                      className="LeftNavigationArrows"
                      alt="Left navigation arrows"
                    />
                    <div onClick={this.handlePrevClick} className="NavigationArrowTop" />
                    <div onClick={this.handleFirstClick} className="NavigationArrowBottom" />
                  </div>

                  <GameListWrapper banner={hasPromotions}>
                    <div className={`GameListContainer ${!hasPromotions ? 'no-margin' : ''}`}>
                      <GamePage {...args} sliderRef={this.slider} navigation={this.props.navigation} />
                    </div>
                  </GameListWrapper>

                  <div className="NavigationArrowsContainer right">
                    <img
                      src={require(asset`images/Lobby_Assets/Scroll_Buttons@3x.png`)}
                      className="RightNavigationArrows"
                      alt="Right navigation arrows"
                    />
                    <div onClick={this.handleNextClick} className="NavigationArrowTop" />
                    <div
                      onClick={() => this.handleLastClick(pagedGames.length - 1)}
                      className="NavigationArrowBottom"
                    />
                  </div>
                </div>
              </div>
            );
          }
        }
      />
    );
  }
}

