import React, { useRef } from 'react';

export default function usePanelHeightCalculator() {
  const pinnedPanelRef = useRef(null);
  const updateParentHeight = () => {
    if (pinnedPanelRef.current) {
      const children = Array.from(pinnedPanelRef.current.children);
      const maxHeight = Math.max(...children.map(child => child.offsetHeight));
      pinnedPanelRef.current.style.height = `${maxHeight}px`;
    }
  };

  return {
    pinnedPanelRef,
    updateParentHeight,
  };
}

