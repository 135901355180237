import _defineProperty from "/var/jenkins/workspace/Web Clients and Images - Production/Code/WEB CLIENT - GTP 2.0 Deploy/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import useNotification from '../../../hooks/useNotification';
import PromotionAction, { PromoActionKey } from '../../../utils/PromotionAction';
import { getText, TEXT_KEY } from '../../../utils/localization';
import { useSelector } from 'react-redux';
import navigation from '../../../utils/navigation/navigation';
import CdnPath from '../../../utils/cdnPath';
import audio from '../../../utils/audio';
import { promoSlidePressEventHandler } from '../utils';
export default function usePromoDataHandler(_ref) {
  var _itemData$sort;

  var itemData = _ref.itemData,
      slideIndex = _ref.slideIndex;

  var _useNotification = useNotification(),
      addNotification = _useNotification.addNotification;

  var _useSelector = useSelector(function (state) {
    return state.app.assetsUrls;
  }),
      ImagesCdnBaseUrl = _useSelector.ImagesCdnBaseUrl;

  var isAccountLinked = useSelector(function (state) {
    return state.playerInfo.isAccountLinked === 'yes';
  });
  var itemsHandler = [];

  var promoActionHandler = function promoActionHandler(bannerData, bannerIndex) {
    audio.onClick();
    var action = bannerData.action,
        game = bannerData.game,
        externalUrl = bannerData.externalUrl;
    var eventHandlerPayload = {
      slideNumber: slideIndex + 1,
      bannerNumber: bannerIndex + 1,
      action: _objectSpread(_objectSpread({
        type: action
      }, game && {
        game: game
      }), externalUrl && {
        externalUrl: externalUrl
      })
    };
    var handler = null;

    if (action === PromoActionKey.LINK_LOYALTY_CARD && isAccountLinked) {
      handler = function handler() {
        promoSlidePressEventHandler(eventHandlerPayload);
        addNotification({
          message: getText(TEXT_KEY.ACCOUNT_ALREADY_LINKED),
          title: getText(TEXT_KEY.ACCOUNT_ALREADY_LINKED_TITLE)
        });
      };
    } else {
      handler = function handler() {
        promoSlidePressEventHandler(eventHandlerPayload);
        return PromotionAction.getActionCallback(navigation, {
          type: action,
          game: game,
          externalUrl: externalUrl
        })();
      };
    }

    return handler;
  };

  var getImageUrl = function getImageUrl(image) {
    return "".concat(ImagesCdnBaseUrl).concat(CdnPath.LOBBY_ADVERTS).concat(image);
  };

  itemData === null || itemData === void 0 ? void 0 : (_itemData$sort = itemData.sort(function (a, b) {
    return b.__sort_key - a.__sort_key;
  })) === null || _itemData$sort === void 0 ? void 0 : _itemData$sort.forEach(function (bannerData, index) {
    var actionHandler = promoActionHandler(bannerData, index);
    var imageUrl = getImageUrl(bannerData.image);
    itemsHandler.push({
      actionHandler: actionHandler,
      imageUrl: imageUrl
    });
  });
  return itemsHandler;
}