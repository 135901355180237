import React, { Component } from 'react';
import SSideMenu from 'shared/components/SideMenu';
import Animated from 'animated/lib/targets/react-dom';
import PanResponder from 'react-panresponder-web';
import { EVENT_TYPES } from 'shared/lib/analytics/constants';
import Logger from 'shared/lib/analytics/logger';
import { ONBOARDING_FLOWS } from 'shared/utils/onboarding';
import { FAVOURITE_ONBOARDING_STEPS } from '../OnboardingLayer/onboardingSteps';
import QueryParamsProxy from 'shared/utils/queryParamProxy';
import RouteParamConstants from 'shared/utils/routeParamConstants';
import Screens from 'shared/screens';
import audio from 'shared/utils/audio';
import { getText, TEXT_KEY } from 'shared/utils/localization';

const menuImages = {
  Slots: {
    default: require(asset`images/Lobby_Assets/Slots@3x.png`),
    purple: require(asset`images/Lobby_Assets/Slots_Purple@3x.png`),
  },
  VIP: {
    default: require(asset`images/Lobby_Assets/VIP@3x.png`),
    purple: require(asset`images/Lobby_Assets/VIP_Purple@3x.png`),
  },
  Table_Games: {
    default: require(asset`images/Lobby_Assets/Table_Games@3x.png`),
    purple: require(asset`images/Lobby_Assets/Table_Games_Purple@3x.png`),
  },
  Favourites: {
    default: require(asset`images/Lobby_Assets/Favourites@3x.png`),
    purple: require(asset`images/Lobby_Assets/Favourites_Purple@3x.png`),
  },
};

export default class SideMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageSrcs: {
        slots: this.getImageSrc('Slots', 'slots'),
        vip: this.getImageSrc('VIP', 'vip'),
        table: this.getImageSrc('Table_Games', 'table'),
        favourites: this.getImageSrc('Favourites', 'favourites'),
      },
    };
    this.favouritesOption = React.createRef();
  }

  componentDidMount() {
    this.updateImageSrcs();
  }

  componentDidUpdate(prevProps) {
    this.updateImageSrcs();
    const { onboardingStep, setOnboardingHighlightedPosition } = this.props;

    if (onboardingStep === prevProps.onboardingStep) return;
    if (onboardingStep === FAVOURITE_ONBOARDING_STEPS.GO_TO_FAVOURITES) {
      const { x, y, width, height } = this.favouritesOption.current.getBoundingClientRect();
      setOnboardingHighlightedPosition({ x, y, width, height }, { message: getText(TEXT_KEY.GAME_ADDED_TO_FAVS) });
    }
  }

  handleClick = (baseHandler, screen, args) => {
    audio.onClick();
    const { userCanFavourite, handleInformativeModal } = args;
    if (!userCanFavourite) {
      return handleInformativeModal();
    }

    const { onboardingStep, setOnboardingStep, setOnboardingHighlightedPosition } = this.props;
    if (screen === Screens.Favourites && onboardingStep === FAVOURITE_ONBOARDING_STEPS.GO_TO_FAVOURITES) {
      setOnboardingHighlightedPosition({ x: 0, y: 0, width: 0, height: 0 });
      setOnboardingStep(FAVOURITE_ONBOARDING_STEPS.FINISH);
      Logger.sendEvent(EVENT_TYPES.TUTORIAL_STEP_COMPLETE, {
        id: ONBOARDING_FLOWS.FAVOURITES,
        step: 3,
      });
    }
    baseHandler(screen);
  };

  showVipAccessFlow = () => {
    QueryParamsProxy.setQueryParams({
      [RouteParamConstants.PARAM_MODAL]: Screens.VipAccessFlow,
    });
  };

  getImageSrc(assetName, keyword) {
    return document.location.href.indexOf(keyword) !== -1
      ? menuImages[assetName].default
      : menuImages[assetName].purple;
  }

  updateImageSrcs() {
    const newImageSrcs = {
      slots: this.getImageSrc('Slots', 'slots'),
      vip: this.getImageSrc('VIP', 'vip'),
      table: this.getImageSrc('Table_Games', 'table'),
      favourites: this.getImageSrc('Favourites', 'favourites'),
    };

    if (JSON.stringify(this.state.imageSrcs) !== JSON.stringify(newImageSrcs)) {
      this.setState({ imageSrcs: newImageSrcs });
    }
  }

  render() {
    return (
      <SSideMenu
        navigation={this.props.navigation}
        animated={Animated}
        panResponder={PanResponder}
        render={
          /**
           * @param {Object} args
           * @param {Object} args.panHandlers
           * @param {Number} args.menuOffset
           * @param {Number} args.bgFadeAmount
           * @param {Boolean} args.bgFadeVisible
           * @param {Function} args.handleTouchedHandler
           * @param {Function} args.navigationTouchHandler
           */
          args => (
            <>
              <div className="sideBar">
                <div className="content">
                  <div className="MenuTabItem">
                    <img
                      onClick={() => args.navigationTouchHandler(Screens.Home)}
                      src={this.state.imageSrcs.slots}
                      alt="Slots Games"
                    />
                  </div>
                  <div className="MenuTabItem">
                    <img
                      onClick={
                        args.isVipRoomUnlocked
                          ? () => args.navigationTouchHandler(Screens.VIP)
                          : () => this.showVipAccessFlow()
                      }
                      src={this.state.imageSrcs.vip}
                      alt="VIP"
                    />
                  </div>
                  <div className="MenuTabItem">
                    <img
                      onClick={() => args.navigationTouchHandler(Screens.Tables)}
                      src={this.state.imageSrcs.table}
                      alt="Table Games"
                    />
                  </div>
                  <div className={args.userCanFavourite ? 'MenuTabItem' : 'MenuTabItemDisabled'}>
                    <img
                      ref={this.favouritesOption}
                      onClick={() => this.handleClick(args.navigationTouchHandler, Screens.Favourites, args)}
                      src={this.state.imageSrcs.favourites}
                      alt="Favourites"
                    />
                  </div>
                </div>
              </div>
            </>
          )
        }
      />
    );
  }
}

