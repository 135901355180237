import Platform from './platform';
import Connection from './connection';
import { counterTypes } from './TimestampManager';
import { buildTimeRemainingBydays, buildTimeRemainingDefault } from './timerStringGenerator';
export var MessageTypes = {
  NONE: '',
  FIRST_NAME_ERROR: 'first_name_error',
  LAST_NAME_ERROR: 'last_name_error',
  EMAIL_ERROR: 'email_error',
  PASSWORD_ERROR: 'password_error',
  SUCCESS: 'success'
};
export default {
  isReactNative: function isReactNative() {
    return window.navigator.product === 'ReactNative';
  },
  getPlatform: function getPlatform() {
    return Platform.getPlatform();
  },
  addOnlineConnectionListener: function addOnlineConnectionListener(callback) {
    return Connection.addOnlineConnectionListener(callback);
  },
  getUA: function getUA() {
    return window.navigator.userAgent ? window.navigator.userAgent : 'ReactNative';
  },
  getInactivityTimeout: function getInactivityTimeout() {
    return 900000; // 15 minutes
  },
  quickObjectComparision: function quickObjectComparision(objectA, objectB) {
    return JSON.stringify(objectA) === JSON.stringify(objectB);
  },
  parseMarkdown: function parseMarkdown(str, reg, callback) {
    if (str.hasOwnProperty('key')) {
      return str;
    }

    return str.split(reg).map(function (s) {
      if (reg.test(s)) {
        return callback(s);
      }

      return s;
    });
  },
  isEmpty: function isEmpty(n) {
    return !(n ? typeof n === 'object' ? Array.isArray(n) ? !!n.length : !!Object.keys(n).length : true : false);
  },

  /**
   * Creates a string as hh:mm:ss for time remaining between two dates.
   * @param {Date} from Starting date.
   * @param {Date} until  End date.
   */
  buildTimeRemaining: function buildTimeRemaining(from, until, counterType, getText) {
    switch (counterType) {
      case counterTypes.MISSION_GRAND_CARD:
        return buildTimeRemainingBydays({
          from: from,
          until: until,
          getText: getText,
          counterType: counterType
        });

      case counterTypes.EVENTS:
        return buildTimeRemainingBydays({
          from: from,
          until: until,
          getText: getText,
          counterType: counterType,
          showZeroOnEnd: true,
          showMinOnDayZero: true
        });

      default:
        return buildTimeRemainingDefault({
          from: from,
          until: until,
          counterType: counterType,
          getText: getText
        });
    }
  },

  /**
   * Gets the active route / screen name from onNavigationStateChange, usually used in App.js.
   * Used for passing screen changes to analytic libraries.
   */
  getActiveRouteName: function getActiveRouteName(navigationState) {
    if (!navigationState) {
      return null;
    }

    var route = navigationState.routes[navigationState.index]; // dive into nested navigators

    if (route.routes) {
      return this.getActiveRouteName(route);
    }

    return route.routeName;
  },
  getTruncationObject: function getTruncationObject(value) {
    var truncationTypes = [{
      value: 1,
      postfix: ''
    }, {
      value: 1000,
      postfix: 'K'
    }, {
      value: 1000000,
      postfix: 'M'
    }, {
      value: 1000000000,
      postfix: 'B'
    }, {
      value: 1000000000000,
      postfix: 'T'
    }];
    var rval = 0;
    truncationTypes.forEach(function (truncationType) {
      if (value >= truncationType.value) {
        rval = truncationType;
      }
    });
    return rval;
  },
  truncateFormat: function truncateFormat(value) {
    var truncater = this.getTruncationObject(value);
    return value / truncater.value + truncater.postfix;
  },
  ieVersion: function ieVersion() {
    if (this.getPlatform() === 'web') {
      var ua = window.navigator.userAgent;
      if (ua.indexOf('Trident/7.0') > 0) return 11;
      if (ua.indexOf('Trident/6.0') > 0) return 10;
      if (ua.indexOf('Trident/5.0') > 0) return 9;
      return 0; // not IE9, 10 or 11
    }

    return 0;
  },
  shuffleArray: function shuffleArray(array) {
    for (var i = array.length - 1; i > 0; i--) {
      var j = Math.floor(Math.random() * (i + 1));
      var temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }

    return array;
  },
  getDeviceType: function getDeviceType() {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent)) {
      return 'Mobile';
    }

    return 'Desktop';
  }
};