import React, { useEffect, useRef } from 'react';

export default function useCreateSliderCoolDownTimer() {
  const coolDownSlide = useRef(true);
  const coolDownTimer = useRef(false);

  const handleCoolDownTimer = () => {
    coolDownTimer.current && clearTimeout(coolDownTimer.current);
    coolDownTimer.current = setTimeout(() => {
      coolDownSlide.current = true;
    }, 700);
  };

  useEffect(() => {
    return () => coolDownTimer.current && clearTimeout(coolDownTimer.current);
  }, []);

  return {
    coolDownSlide,
    handleCoolDownTimer,
  };
}

