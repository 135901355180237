import React, { useState } from 'react';
import { TEXT_KEY, getText } from 'shared/utils/localization';
import {
  ContentContainer,
  DividerImage,
  HistoryDetailContainer,
  ScrollbarContainer,
  SendEmailButton,
} from './styledComponents';

const StandardHistoryDetail = ({ offerHistoryDetails, emailSentMessage, onSendEmail }) => {
  const [width, setWidth] = useState(0);

  const onScroll = () => {
    setWidth(0.5);
  };

  return (
    <>
      <HistoryDetailContainer>
        <ScrollbarContainer
          onScroll={onScroll}
          renderThumbVertical={props => <div {...props} className="thumb-vertical" style={{ width: width + 'rem' }} />}
          renderTrackVertical={props => (
            <div {...props} className="track-vertical" style={{ opacity: width > 0 ? 1 : 0 }} />
          )}
          renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: 'none' }} />}
          renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: 'none' }} />}
        >
          <ContentContainer>
            <div className="RewardCenterHistoryDetailTitle">{offerHistoryDetails.rewardname}</div>
            <div className="RewardCenterHistoryDetailSubtitle">{offerHistoryDetails.subtitle}</div>
            <DividerImage
              source={require(asset`Shop_VIP_Divider_Glows@3x.png`)}
              ibw={require(asset`Shop_VIP_Divider_Glows.ibw`)}
              manualAssetScale={3}
            />
            {offerHistoryDetails.showBarcode && (
              <>
                <img alt="" className="RewardCenterHistoryDetailBarcode" src={offerHistoryDetails.barcodeImageURL} />
                <div className="RewardCenterHistoryDetailDates">
                  <div className="RewardCenterHistoryDetailIssueDate">
                    {getText(TEXT_KEY.ISSUED_ON)}
                    {offerHistoryDetails.redeemDate}
                  </div>
                  <div className="RewardCenterHistoryDetailExpiryDate">{offerHistoryDetails.expiryDate}</div>
                </div>
                <SendEmailButton
                  imageSource={require(asset`RoundedRect_Primary@3x.png`)}
                  onClick={onSendEmail}
                  label={getText(TEXT_KEY.SEND_EMAIL)}
                />
              </>
            )}

            {emailSentMessage && <div className="RewardCenterHistoryDetailEmailSent">{emailSentMessage}</div>}

            <div className="RewardCenterHistoryDetailTermsTitle">
              {getText(TEXT_KEY.PURCHASE_AND_USAGE_LIMITATIONS)}
            </div>
            <div
              className="RewardCenterHistoryDetailTerms"
              dangerouslySetInnerHTML={{ __html: offerHistoryDetails.terms }}
            ></div>
          </ContentContainer>
        </ScrollbarContainer>
      </HistoryDetailContainer>
    </>
  );
};

export default StandardHistoryDetail;

