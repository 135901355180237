import styled from 'styled-components';
import { Scrollbars } from 'react-custom-scrollbars';
import ImageBW from '../../../../../../components/ImageBoundsWrapper';
import Button from '../../../../../../components/Button/Button';

const HistoryDetailContainer = styled.div`
  width: 100%;
  height: 98%;
`;

const ScrollbarContainer = styled(Scrollbars)`
  height: 460px;
`;

const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 10% 0;
`;

const DividerImage = styled(ImageBW).attrs(() => ({
  innerStyle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    paddingLeft: '1vmin',
    paddingRight: '1vmin',
  },
}))`
  height: 1.5vmin;
  width: 100%;
  margin-top: 1vmin;
`;

const SendEmailButton = styled(Button).attrs(() => ({
  imageStyle: { width: '50%' },
  containerStyle: {
    marginTop: '1em',
    position: 'relative',
    textAlign: 'center',
    cursor: 'pointer',
    pointerEvents: 'auto',
    width: '23em',
    height: '3.5em',
    flex: 1,
    left: '-6em',
  },
  textStyle: {
    position: 'absolute',
    textAlign: 'center',
    textTransform: 'uppercase',
    top: '50%',
    left: '50%',
    width: '100%',
    padding: '1em',
    fontWeight: 500,
    fontSize: 'calc(14px + (22 - 14) * ((100vw - 800px) / (1600 - 800)))',
  },
}))``;

export { HistoryDetailContainer, ScrollbarContainer, ContentContainer, DividerImage, SendEmailButton };

