import styled from 'styled-components';
import theme from 'shared/assets/style/theme';
import generateRender from 'shared/utils/generateRender';

const scrollBarTrackColor = generateRender({
  default: theme.palette.secondary[27],
  sga: theme.palette.secondary[41],
  ti: 'rgba(11, 39, 53, 1)',
  admiral: 'rgba(132, 129, 165, 1)',
  admes: 'rgba(132, 129, 165, 1)',
  star: theme.palette.primary[0]
});

const scrollBarThumbColor = generateRender({
  default: theme.palette.primary[34],
  sga: 'rgba(241, 133, 76, 1)',
  ti: theme.palette.primary[31],
  star: theme.palette.primary[25],
});

const ScrollViewStyle = styled.div`
  z-index: 11;
  flex: 1;
  height: 45vh;
  max-height: 500px;
  width: 100%;
  margin: 4px;
  margin-top: 0;
  padding: 0 10px 40px 30px;

  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-track {
    background-color: ${scrollBarTrackColor};
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${scrollBarThumbColor};
    border-radius: 10px;
  }

  scrollbar-width: thin;
  scrollbar-color: ${scrollBarThumbColor} ${scrollBarTrackColor};

  @media only screen and (max-height: 800px) {
    height: 40vh;
  }
`;

const OfferTitleWrapper = styled.div`
  flex: 1;
  align-self: flex-start;
`;

const OfferSubTitleHolder = styled.div`
  flex: 1;
  margin-top: 10px;
`;

const OfferPropertySelectorWrapper = styled.div`
  width: 65%;
  margin: -5px 10px 0 -5px;
`;

const OfferButtonWrapper = styled.div`
  width: 35%;
  height: 50px;
`;

const OfferButtonImageStyle = styled.img`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const OfferDescWrapper = styled.div`
  flex: 1;
  z-index: -1;
  margin-top: 10px;
`;

const OfferTermsWrapper = styled.div`
  flex: 1;
  margin-top: 10px;
`;

const offerDetailStyles = {
  offerButtonImageStyle: {
    width: '220px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  offerButtonContainerStyle: {
    width: '220px',
  },
  offerButtonWrapperStyle: {
    alignItems: 'center',
  },
};

export {
  offerDetailStyles,
  ScrollViewStyle,
  OfferTitleWrapper,
  OfferSubTitleHolder,
  OfferPropertySelectorWrapper,
  OfferButtonWrapper,
  OfferButtonImageStyle,
  OfferDescWrapper,
  OfferTermsWrapper,
};

