import useFeatureFlags from '../../../../hooks/useFeatureFlags';
import { FE_TIMEOUTS } from '../../../../utils/featureFlags/constants';
export var DEFAULT_TIMEOUT = 60000;
export var getTimeout = function getTimeout(endpoint, method) {
  var _getFeatureConfig, _getFeatureConfig$end;

  var _useFeatureFlags = useFeatureFlags(),
      getFeatureConfig = _useFeatureFlags.getFeatureConfig;

  var timeout = (_getFeatureConfig = getFeatureConfig(FE_TIMEOUTS)) === null || _getFeatureConfig === void 0 ? void 0 : (_getFeatureConfig$end = _getFeatureConfig[endpoint]) === null || _getFeatureConfig$end === void 0 ? void 0 : _getFeatureConfig$end[method];
  return timeout !== null && timeout !== void 0 ? timeout : DEFAULT_TIMEOUT;
};