import Screens from 'shared/screens';
import VideoAds from '../screens/VideoAds';
import Messages from '../screens/Messages';
import Settings from '../screens/Settings';
import News from '../screens/News';
import Shop from '../screens/Shop';
import PurchaseFlow from '../screens/PurchaseFlow';
import UpgradeAccountFlow from '../screens/UpgradeAccountFlow';
import DailySpin from '../screens/DailySpin';
import CurrencySelector from '../screens/CurrencySelector';
import KYCLockout from '../screens/PurchaseFlow/KYCLockout';
import GameUnavailable from '../screens/Game/GameUnavailable';
import SessionExpired from '../screens/SessionExpired';
import LimitedTimeOffer from '../screens/LimitedTimeOffer';
import FavouritesUnlocked from '../screens/FavouritesUnlocked';
import FavouritesExpired from '../screens/FavouritesExpired';
import FavouritesInformation from '../screens/FavouritesInformation';
import GenericMessage from '../screens/GenericMessage/GenericMessage';
import WatchAndEarnInfo from '../screens/VideoAds/Info';
import VipAccessFlow from '../screens/VipAccessFlow';
import PurchaseSucceeded from '../screens/PurchaseSucceeded';
import OutOfCoin from '../screens/OutOfCoin';
import LinkAccount from '../screens/LinkAccount';
import LinkAccountForm from '../screens/LinkAccountForm';
import ComingSoon from '../screens/ComingSoon';
import Congrats from '../screens/VideoAds/Congrats';
import BonusLinkMessage from '../screens/BonusLinkMessage/BonusLinkMessage';
import CheatMenu from '../screens/CheatMenu';
import RewardCenterOnboarding from '../screens/RewardCenter/Onboarding';
import LinkCardOnboarding from '../screens/RewardCenter/LinkCardOnboarding';
import WelcomeBonus from '../screens/WelcomeBonus';
import DailySpinOnboarding from '../screens/DailySpin/Onboarding';
import SignUp from '../screens/Signup';
import RegisterRewardCard from '../screens/RegisterRewardCard';
import AdBlocker from '../screens/AdBlocker';
import SneakPeekOnboarding from '../screens/SneakPeekOnboarding';
import OneTimePassword from '../screens/OneTimePassword/OneTimePassword';
import DynamicPopups from '../screens/DynamicPopups';

export default {
  [Screens.VideoAds]: VideoAds,
  [Screens.Messages]: Messages,
  [Screens.Settings]: Settings,
  [Screens.News]: News,
  [Screens.Shop]: Shop,
  [Screens.PurchaseFlow]: PurchaseFlow,
  [Screens.UpgradeAccountFlow]: UpgradeAccountFlow,
  [Screens.DailySpin]: DailySpin,
  [Screens.CurrencySelector]: CurrencySelector,
  [Screens.KYCLockout]: KYCLockout,
  [Screens.GameUnavailable]: GameUnavailable,
  [Screens.SessionExpired]: SessionExpired,
  [Screens.ComingSoon]: ComingSoon,
  [Screens.LimitedTimeOffer]: LimitedTimeOffer,
  [Screens.SneakPeekOnboarding]: SneakPeekOnboarding,
  [Screens.FavouritesUnlocked]: FavouritesUnlocked,
  [Screens.FavouritesExpired]: FavouritesExpired,
  [Screens.FavouritesInformation]: FavouritesInformation,
  [Screens.WatchAndEarnInfo]: WatchAndEarnInfo,
  [Screens.GenericMessage]: GenericMessage,
  [Screens.VipAccessFlow]: VipAccessFlow,
  [Screens.PurchaseSucceeded]: PurchaseSucceeded,
  [Screens.OutOfCoin]: OutOfCoin,
  [Screens.LinkAccount]: LinkAccount,
  [Screens.LinkAccountForm]: LinkAccountForm,
  [Screens.Congrats]: Congrats,
  [Screens.BonusLinkMessage]: BonusLinkMessage,
  [Screens.CheatMenu]: CheatMenu,
  [Screens.RewardCenterOnboarding]: RewardCenterOnboarding,
  [Screens.LinkCardOnboarding]: LinkCardOnboarding,
  [Screens.WelcomeBonus]: WelcomeBonus,
  [Screens.DailySpinOnboarding]: DailySpinOnboarding,
  [Screens.Signup]: SignUp,
  [Screens.RegisterRewardCard]: RegisterRewardCard,
  [Screens.AdBlocker]: AdBlocker,
  [Screens.OneTimePassword]: OneTimePassword,
  [Screens.DynamicPopups]: DynamicPopups,
};

