import React from 'react';
import ENVIRONMENT_VARIABLES from 'shared/utils/environmentVariables';
import useRewardCenterHistoryDetails from 'shared/screens/RewardCenter/hooks/useRewardCenterHistoryDetails';
import StandardHistoryDetail from './components/StandardHistoryDetail';
import QrHistoryDetail from './components/QrHistoryDetail';
import ActivityIndicator from '../../../../components/ActivityIndicator/ActivityIndicator';

const RewardCenterHistoryDetail = props => {
  const { activeHistoryItem } = props;
  const { offerHistoryDetails, onSendEmail, emailSentMessage, voucherCode, isLoading } = useRewardCenterHistoryDetails(
    activeHistoryItem.rpid
  );

  return !isLoading && offerHistoryDetails ? (
    <>
      {ENVIRONMENT_VARIABLES.REWARD_CENTER_QR_LAYOUT !== 'true' ? (
        <StandardHistoryDetail
          offerHistoryDetails={offerHistoryDetails}
          emailSentMessage={emailSentMessage}
          onSendEmail={onSendEmail}
        />
      ) : (
        <QrHistoryDetail
          activeHistoryItem={activeHistoryItem}
          offerHistoryDetails={offerHistoryDetails}
          emailSentMessage={emailSentMessage}
          onSendEmail={onSendEmail}
          voucherCode={voucherCode}
        />
      )}
    </>
  ) : (
    <div className="RewardCenterHistoryDetail">
      <ActivityIndicator />
    </div>
  );
};

export default RewardCenterHistoryDetail;

