import React, { Component } from 'react';
import styled from 'styled-components';
import SLinkAccount from 'shared/screens/LinkAccount';
import SSOLinksInterface from 'shared/api/SSOLinksInterface';
import { SSOLinksResponseKeys } from 'shared/api/Constants';
import './LinkAccount.scss';
import ENVIRONMENT_VARIABLES from 'shared/utils/environmentVariables';
import ThemeContext from 'shared/context/ThemeContext';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import { AUTH_TYPES } from 'shared/utils/authFlow/constants';
import store from 'shared/state/store';
import Button from '../../components/Button/Button';

export default class UnityLinkAccount extends Component {
  static get AsModalProps() {
    return {
      dialogClassName: 'gtp-modal-link-account',
      hideBeforeSelector: ENVIRONMENT_VARIABLES.APPLICATION_TARGET === 'hri',
    };
  }

  handleLinkAccountClick = parentComponentMethod => async () => {
    if (ENVIRONMENT_VARIABLES.SSO_ENABLED.toString() !== 'true') {
      parentComponentMethod();
      return false;
    }
    const ssoLinks = await SSOLinksInterface.getSSOLinks();

    store.dispatch(this.props.setAuthCallbackIntent(AUTH_TYPES.LINK));
    let destinationUrl = ssoLinks[SSOLinksResponseKeys.LOGIN_URL];

    if (ENVIRONMENT_VARIABLES.SSO_DEV && ENVIRONMENT_VARIABLES.SSO_DEV.toString() === 'true') {
      destinationUrl = destinationUrl.replace(
        new RegExp(`(redirect_uri=)(.*)(/${ENVIRONMENT_VARIABLES.SSO_CALLBACK_PATH})`),
        `$1${document.location.origin}$3`
      );
    }
    document.location = destinationUrl;
  };

  render() {
    const themeContext = this.context;

    return (
      <SLinkAccount
        navigation={this.props.navigation}
        {...this.props}
        render={args => {
          const { linkAccount, handleLinkAccountForm, hideLinkButton } = args;

          return (
            <div style={themeContext.LinkAccount.LinkAccountContainer}>
              <span style={themeContext.LinkAccount.LinkAccountTitle}>
                {getText(TEXT_KEY.UNITY_LINK_ACCOUNT_INFO_TITLE)}
              </span>
              <SubTitle>{getText(TEXT_KEY.UNITY_LINK_ACCOUNT_INFO_SUBTITLE)}</SubTitle>
              <MainImageWrapper>
                <MainImage src={linkAccount.icon ? linkAccount.icon : require(asset`Points_Cards.png`)} />
              </MainImageWrapper>
              {!hideLinkButton && (
                <ButtonWrapper>
                  <Button
                    imageSource={require(asset`images/Link_Account_Info/Link_now_button.png`)}
                    onClick={this.handleLinkAccountClick(handleLinkAccountForm)}
                    containerStyle={Styles.ButtonContainerStyle}
                    objectFit="contain"
                    imageStyle={Styles.ButtonImageStyle}
                  />
                </ButtonWrapper>
              )}
            </div>
          );
        }}
      />
    );
  }
}

UnityLinkAccount.hideOverlay = true;
UnityLinkAccount.contextType = ThemeContext;

const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 4rem;
  align-items: center;
  justify-content: center;
`;

const MainImage = styled.img`
  object-fit: contain;
  height: 130%;
  width: 100%;
`;

const SubTitle = styled.p`
  width: 100%;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 5px;
  font-weight: bold;
`;

const LightDividerImage = styled.img`
  width: 100%;
  position: absolute;
  top: 11%;
`;

const MainImageWrapper = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  margin: 1rem;
  height: auto;
`;

const Styles = {
  ButtonContainerStyle: {
    width: '25%',
    height: '4rem',
  },
  ButtonImageStyle: {
    width: '25%',
  },
};

