import React, { Component } from 'react';
import SNews from 'shared/screens/News';
import QueryParamsProxy from 'shared/utils/queryParamProxy';
import RouteParamConstants from 'shared/utils/routeParamConstants';
import { sendWelcomeNewsEvent } from 'shared/screens/News/analytics/welcomeNewsLogger';
import { WELCOME_NEWS_EVENT_TYPES } from 'shared/screens/News/constants/events';

export default class News extends Component {
  static get AsModalProps() {
    return {
      dialogClassName: 'gtp-modal-news',
    };
  }

  // eslint-disable-next-line getter-return
  static get DismissHandler() {
    sendWelcomeNewsEvent(WELCOME_NEWS_EVENT_TYPES.DISMISS_WELCOME_NEWS);

    QueryParamsProxy.setQueryParams({
      [RouteParamConstants.PARAM_MODAL]: undefined,
    });
  }

  render() {
    return (
      <SNews
        navigation={this.props.navigation}
        render={args => {
          const { image, clickHandler, showButton } = args;

          return (
            <div
              className="newsBody"
              style={{ cursor: showButton && 'pointer' }}
              onClick={() => {
                showButton && clickHandler();
              }}
            >
              {image && <img alt="Welcome News" src={image} style={{ width: '100%' }} />}
            </div>
          );
        }}
      />
    );
  }
}

News.hideDefaultBackground = true;
