import _defineProperty from "/var/jenkins/workspace/Web Clients and Images - Production/Code/WEB CLIENT - GTP 2.0 Deploy/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";

var _EVENT_SCREENS;

import { EVENT_DOMAINS } from './domains';
/**
 * EVENT_SCREENS defines a specific section of the current domain
 */

export var EVENT_SCREENS = (_EVENT_SCREENS = {}, _defineProperty(_EVENT_SCREENS, EVENT_DOMAINS.GENERAL, {
  LOBBY: 'LOBBY',
  TUTORIAL: 'TUTORIAL',
  MAIN: 'MAIN',
  INFO: 'INFO'
}), _defineProperty(_EVENT_SCREENS, EVENT_DOMAINS.ACTIONS_COMMANDS, {
  APP_MODAL: 'APP_MODAL',
  NATIVE_MODAL: 'NATIVE_MODAL',
  ONBOARDING_TUTORIAL: 'ONBOARDING_TUTORIAL',
  MISSIONS_TUTORIAL: 'MISSIONS_TUTORIAL',
  APP_TOOLTIP: 'APP_TOOLTIP'
}), _EVENT_SCREENS);