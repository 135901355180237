var _require = require('../../../utils/platformCheck'),
    isWeb = _require.isWeb;

module.exports = {
  dimensions: {
    width: 900,
    height: 56
  },
  nine: {
    top: isWeb ? 39 : 4,
    left: 58,
    right: 58,
    bottom: 3
  },
  optical: {
    top: isWeb ? 4 : 3,
    left: isWeb ? 4 : 2,
    right: isWeb ? 5 : 1,
    bottom: 1
  }
};