import { REWARD_CENTER_OFFERS_STATUS } from '../constants';
export var hasSoldOutState = function hasSoldOutState(ribbontype) {
  return ribbontype === REWARD_CENTER_OFFERS_STATUS.SOLD_OUT;
};
export var hasLimitedState = function hasLimitedState(ribbontype) {
  return ribbontype === REWARD_CENTER_OFFERS_STATUS.LIMITED;
};

var isValidNumber = function isValidNumber(num) {
  return Number(num) && Number(num) > 0;
};

var calculateMinRedeems = function calculateMinRedeems(maxperplayer, maxredemption) {
  var isMaxPerPlayerValid = isValidNumber(maxperplayer);
  var isMaxRedemptionValid = isValidNumber(maxredemption);
  if (!isMaxPerPlayerValid && !isMaxRedemptionValid) return 0;
  if (isMaxPerPlayerValid && isMaxRedemptionValid) return Math.min(Number(maxperplayer), Number(maxredemption));
  if (isMaxPerPlayerValid) return Number(maxperplayer);
  return Number(maxredemption);
};

export var getOfferDescription = function getOfferDescription(offer) {
  var rewardName = offer.rewardName,
      ribbontype = offer.ribbontype,
      remainingredeems = offer.remainingredeems,
      maxperplayer = offer.maxperplayer,
      maxredemption = offer.maxredemption;
  var minRedeems = calculateMinRedeems(maxperplayer, maxredemption);

  if (hasLimitedState(ribbontype) && isValidNumber(remainingredeems) && isValidNumber(minRedeems) && remainingredeems <= minRedeems) {
    return "".concat(rewardName, " ").concat(remainingredeems, "/").concat(minRedeems);
  }

  return rewardName;
};