import RewardLoyaltyPointsTab from '../../screens/RewardCenter/components/RewardLoyaltyPointsTab';
import RewardCenterHistory from '../../screens/RewardCenter/components/RewardCenterHistory';
import RewardProfileTab from '../../screens/RewardCenter/components/RewardProfileTab';
import RewardCenterHistoryDetail from '../../screens/RewardCenter/components/RewardCenterHistoryDetail';
import { REWARD_CENTER_TABS } from 'shared/screens/RewardCenter/constants';

const RewardTabContent = {
  [REWARD_CENTER_TABS.HOME]: RewardProfileTab,
  [REWARD_CENTER_TABS.HISTORY]: RewardCenterHistory,
  [REWARD_CENTER_TABS.HISTORY_DETAIL]: RewardCenterHistoryDetail,
  [REWARD_CENTER_TABS.PROFILE]: RewardProfileTab,
  [REWARD_CENTER_TABS.LOYALTY_POINTS]: RewardLoyaltyPointsTab,
};

export default RewardTabContent;

