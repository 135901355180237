/** Available URL params. */
export default {
  /** If set, display a modal with the type equal to the value. */
  PARAM_MODAL: 'm',

  /** Used in the shop modal. Stores the tab currently being browsed. */
  PARAM_SHOP_TYPE: 'st',

  /** Used in the purchase flow. Stores the purchase type of what we are buying. */
  PARAM_PURCHASE_TYPE: 'pt',

  /** Used in purchase flow. Stores the sku of the item selected from the store. */
  PARAM_PURCHASE_SKU: 'psk',

  /** Used in purchase flow. Stores the name of the item selected from the store. */
  PARAM_PURCHASE_NAME: 'pn',

  /** Used in purchase flow. After redirecting from CAMS,  */
  PARAM_PURCHASE_FINISHED_RESULT: 'pfr',

  /** Used in purchase flow, and potentially elsewhere. If set, some message occurred and we should display it to the user. */
  PARAM_MSG: 'msg',

  /** Used by game screen to know what game to init. Route paramter. */
  PARAM_GAME_ID: 'gid',
  PARAM_CHEAT_MENU: 'chm',
  PARAM_SNEAK_PEEK_END_DATE: 'sped',

  /** Used by upgrade account flow to know which upgrade flow is the user doing */
  PARAM_UPGRADE_TYPE: 'ut',

  /** Used by upgrade account flow to know where else to navigate to after, potentially useful for other screens */
  PARAM_NAVIGATE_TO: 'to',

  /** Used by leaderboard to know which leaderboard to show */
  PARAM_LEADERBOARD_TYPE: 'lbt',

  /** Used by message inbox to know which message category to show */
  PARAM_MESSAGE_INBOX_CATEGORY: 'mic',

  /** Used by message inbox to know which message to show in details */
  PARAM_MESSAGE_ID: 'mid',

  /** Used by dynamic action to open an external link */
  PARAM_EXTERNAL_URL: 'exturl',

  /** Reward Center for offer redemption & history */
  PARAM_REWARD_CENTER: 'rw',
  PARAM_RC_CATEGORY: 'rc_cat',
  PARAM_RC_TAB: 'rc_tab',
  PARAM_RC_PROFILE_LINK_BENEFITS: 'rc_profile_link_benefits',
  PARAM_STATUS_MESSAGE: 'sm',
  PARAM_ACTION: 'a',
  PARAM_SUCCESS_INTENT: 'si',
  PARAM_NEXT_SCREEN: 'ns',
  PARAM_PREV_SCREEN: 'ps',

  /** Used in purchase flow. Stores the Dynamic Triggered Sale (DTS) ID of the item selected from the DTS Screen. */
  PARAM_PURCHASE_DTS_ID: 'pdi',

  /** Used in favourite onboarding to pass the data from livepanel to the confirmation dialogue */
  PARAM_FAVOURITE_GAME_ID: 'fgid',

  /** Used in the settings modal. Stores the settings screen currently being browsed. */
  PARAM_SETTINGS_SCREEN: 'ss',

  /** Used for the cheat menu. It determines if the screen has been accessed through it. */
  PARAM_CHEAT_MENU_ACTIVE: 'cma',

  /** Video Ads Reward Amount */
  PARAM_VIDEO_REWARD_AMOUNT: 'vra',

  /** Video Ads Reward Currency */
  PARAM_VIDEO_REWARD_CURRENCY: 'vrc',

  /** Tracking Url Ref ID */
  PARAM_TRACKING_URL_AFF_ID: 'aff_id',

  /** Message Offer Detail Param */
  PARAM_OFFER_DETAIL: 'off_d',
  PARAM_OFFER_ENABLE: 'poe',

  /** Tracking sessionToken and restartToken */
  PARAM_TRACKING_SESSION_TOKEN: 'sessionToken',
  PARAM_TRACKING_RESTART_TOKEN: 'restartToken',

  /** Recover Password URL params */
  PARAM_RECOVER_PASS_ID: 'i',
  PARAM_RECOVER_PASS_TOKEN: 't',
  PARAM_RECOVER_PASS_PLATFORM: 'platform',

  /** OTP Params */
  PARAM_OTP_PROPERTY_ID: 'opid',
  PARAM_OTP_DOB: 'odob',
  PARAM_OTP_CARD_NUMBER: 'ocn',
  PARAM_OTP_TRANSACTION_ID: 'otid',
  PARAM_OTP_USER_ID: 'ouid',

  /** Action Commands Params */
  PARAM_ACTION_COMMANDS: 'ac',

  /** Swwpstakes params */
  PARAM_SWEEPSTAKE_TUTORIAL: 'sstut'
};
export var ShopTypes = {
  /** Coin store */
  COINS: 'c',

  /** VIP store */
  VIP: 'v'
};
export var PurchaseTypes = {
  CURRENCY: 'currency',
  VIP: 'vip',
  // Used in purchase logic. Blanket purchase type for API that covers boosts and vip.
  CONSUMABLE: 'consumable',
  OUTOFCOINS: 'outofcoins'
};
export var PurchaseFinishedResults = {
  /** Purchase was successfully completed. */
  SUCCESS: 's',

  /** If result is error, there will be a PARAM_ERROR_MSG to display to the user. */
  ERROR: 'e'
};
export var MOUNT_INTENTS = {
  FACEBOOK_LOGIN: 'fb',
  GOOGLE_LOGIN: 'google',
  GUEST_LOGIN: 'gl',
  APPLE_LOGIN: 'apl'
};
export var BONUS_LINK = {
  HASH: 'blink_hash',
  ID: 'blink_id'
};
export var PROMO_LINK = {
  CODE: 'promo_code'
};