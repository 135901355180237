import Logger from '../../../lib/analytics/logger';
import { MISSIONS_EVENT_TYPES } from '../constants/events';
import { EVENT_ACTIONS, EVENT_DOMAINS, EVENT_SCREENS, EVENT_WIDGETS } from '../../../lib/analytics/constants';
import { EVENT_TYPES } from '../../../lib/analytics/constants';
import { MissionTutorialStepsKeys } from '../../../utils/tutorial';
export var sendMissionsEvent = function sendMissionsEvent(eventType, payload) {
  switch (eventType) {
    case MISSIONS_EVENT_TYPES.ENTER_MISSIONS_SCREEN:
      Logger.sendEvent(EVENT_TYPES.UI_EVENT, {
        action: EVENT_ACTIONS.DISPLAY,
        domain: EVENT_DOMAINS.MISSIONS,
        screen: EVENT_SCREENS[EVENT_DOMAINS.GENERAL].MAIN
      });
      break;

    case MISSIONS_EVENT_TYPES.MISSIONS_TUTORIAL_NEXT_STEP:
      Logger.sendEvent(EVENT_TYPES.UI_EVENT, {
        action: EVENT_ACTIONS.PRESS,
        domain: EVENT_DOMAINS.MISSIONS,
        screen: payload.step >= MissionTutorialStepsKeys.EXPLORE ? EVENT_SCREENS[EVENT_DOMAINS.GENERAL].MAIN : EVENT_SCREENS[EVENT_DOMAINS.GENERAL].LOBBY,
        widget: EVENT_WIDGETS[EVENT_DOMAINS.MISSIONS].TUTORIAL_NEXT_STEP,
        context_tutorial_step: payload.step
      });
      break;

    case MISSIONS_EVENT_TYPES.DISPLAY_MISSIONS_TUTORIAL_STEP:
      Logger.sendEvent(EVENT_TYPES.UI_EVENT, {
        action: EVENT_ACTIONS.DISPLAY,
        domain: EVENT_DOMAINS.MISSIONS,
        screen: EVENT_SCREENS[EVENT_DOMAINS.GENERAL].MAIN,
        context_tutorial_step: payload.step
      });
      break;

    case MISSIONS_EVENT_TYPES.MISSIONS_TUTORIAL_SKIP:
      Logger.sendEvent(EVENT_TYPES.UI_EVENT, {
        action: EVENT_ACTIONS.PRESS,
        domain: EVENT_DOMAINS.MISSIONS,
        screen: payload.step >= MissionTutorialStepsKeys.EXPLORE ? EVENT_SCREENS[EVENT_DOMAINS.GENERAL].MAIN : EVENT_SCREENS[EVENT_DOMAINS.GENERAL].LOBBY,
        widget: EVENT_WIDGETS[EVENT_DOMAINS.MISSIONS].TUTORIAL_SKIP,
        context_tutorial_step: payload.step,
        isFtueFlow: payload.isFtueFlow
      });
      break;

    case MISSIONS_EVENT_TYPES.MISSIONS_TUTORIAL_COMPLETE:
      Logger.sendEvent(EVENT_TYPES.UI_EVENT, {
        action: EVENT_ACTIONS.COMPLETE,
        domain: EVENT_DOMAINS.MISSIONS,
        screen: EVENT_SCREENS[EVENT_DOMAINS.GENERAL].MAIN,
        context_tutorial_step: payload.step
      });
      break;

    case MISSIONS_EVENT_TYPES.DISPLAY_MISSIONS_ICON:
      Logger.sendEvent(EVENT_TYPES.UI_EVENT, {
        action: EVENT_ACTIONS.DISPLAY,
        domain: EVENT_DOMAINS.MISSIONS,
        screen: EVENT_SCREENS[EVENT_DOMAINS.GENERAL].LOBBY,
        widget: EVENT_WIDGETS[EVENT_DOMAINS.GENERAL].MISSIONS_ICON
      });
      break;

    case MISSIONS_EVENT_TYPES.MISSIONS_CARD_REWARD_CLAIM:
      Logger.sendEvent(EVENT_TYPES.UI_EVENT, {
        action: EVENT_ACTIONS.PRESS,
        domain: EVENT_DOMAINS.MISSIONS,
        screen: EVENT_SCREENS[EVENT_DOMAINS.GENERAL].MAIN,
        widget: EVENT_WIDGETS[EVENT_DOMAINS.MISSIONS].MISSION_CARD_REWARD_CLAIM_BUTTON
      });
      break;

    case MISSIONS_EVENT_TYPES.MISSIONS_OPEN_INFO_SCREEN:
      Logger.sendEvent(EVENT_TYPES.UI_EVENT, {
        action: EVENT_ACTIONS.PRESS,
        domain: EVENT_DOMAINS.MISSIONS,
        screen: EVENT_SCREENS[EVENT_DOMAINS.GENERAL].MAIN,
        widget: EVENT_WIDGETS[EVENT_DOMAINS.MISSIONS].MISSIONS_INFO_BUTTON
      });
      break;

    default:
      break;
  }
};