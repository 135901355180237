export var REWARD_CENTER_TABS = {
  OFFERS: 'OFFERS',
  HISTORY: 'HISTORY',
  OFFER: 'OFFER',
  HISTORY_DETAIL: 'HISTORY_DETAIL',
  BACK: 'BACK',
  CATEGORIES: 'CATEGORIES',
  HOME: 'HOME',
  PROFILE: 'PROFILE',
  LOYALTY_POINTS: 'LOYALTY_POINTS'
};