import theme from 'shared/assets/style/theme';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const LoyaltyPointsText = styled.p`
  color: ${theme.palette.common[1]};
  text-transform: uppercase;
  text-align: center;
  text-shadow: -1px 1px 1px ${theme.palette.common[28]};
  font-size: 2em;
  width: 75%;
  font-weight: 700;
`;

export { Container, LoyaltyPointsText };

