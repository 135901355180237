import React from 'react';
import { EventsEventTextWrapper, TooltipBackground } from './styledComponent';
import useGenerateEventsIconRender from 'shared/screens/Events/hooks/useGenerateEventsIconRender';
import { useSelector } from 'shared/node_modules/react-redux';
import { useGetEventsQuery } from 'shared/state/query/events';

export default function EventsTooltip({ props }) {
  const { displayText, style } = props;

  const assets = useSelector(state => state.assetsPackage.assets.EVENTS_FEATURE);
  const { playerId } = useSelector(state => state.playerInfo);
  const { data } = useGetEventsQuery({ requestAssetsData: true, playerId });
  const eventsAssets = assets?.[data?.eventsFeatureState?.assetsPackageId];

  const { EVENTS_DEFAULT_TOOLTIP } = useGenerateEventsIconRender({ eventsAssets });
  const tooltipBG = eventsAssets?.EVENTS_TOOTLIP ?? EVENTS_DEFAULT_TOOLTIP;

  return (
    <div className="Events-tooltip AnimationFadeIn" style={style}>
      <TooltipBackground src={tooltipBG} />
      <EventsEventTextWrapper>{displayText}</EventsEventTextWrapper>
    </div>
  );
}

