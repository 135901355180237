import _defineProperty from "/var/jenkins/workspace/Web Clients and Images - Production/Code/WEB CLIENT - GTP 2.0 Deploy/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";

var _queryReducer;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import storage from '@react-native-community/async-storage';
import { combineReducers } from '../node_modules/redux';
import { persistReducer } from '../node_modules/redux-persist';
import autoMergeLevel2 from '../node_modules/redux-persist/lib/stateReconciler/autoMergeLevel2';
import { apiRootReducer } from './reducers/apiRootReducer';
import appReducer from './reducers/appReducer';
import attPermissionReducer from './reducers/attPermissionReducer';
import audioReducer from './reducers/audioReducer';
import { authorizedReducer, fbTokenReducer, lastActiveReducer, restartReducer, tokenReducer } from './reducers/authReducer';
import availableServicesReducer from './reducers/availableServicesReducer';
import { betInfoReducer } from './reducers/betInfoReducer';
import { callbackIntentReducer } from './reducers/callbackIntentReducer';
import { consumablesReducer } from './reducers/consumablesReducer';
import currentOnboardingReducer from './reducers/currentOnboardingReducer';
import { deviceReducer } from './reducers/deviceReducer';
import { appsFlyerValidationReducer, coinReducer, lastWinReducer, loyaltyReducer, ltoReducer, premiumReducer, timeRemainingReducer, videoAdsReducer } from './reducers/extrasReducer';
import featureFlagsReducer from './reducers/featureFlagsReducer';
import formInfoReducer from './reducers/formInfoReducer';
import ftueReducer from './reducers/ftueReducer';
import gameDownloadReducer from './reducers/gameDownloadReducer';
import { favouriteGameReducer, featuredGamesReducer, gameInfoReducer, newGamesReducer, slotGamesReducer, tableGamesReducer, vipGamesReducer } from './reducers/gameReducer';
import loadReducer from './reducers/loadReducer';
import modalReducer from './reducers/modalReducer';
import navStateReducer from './reducers/navStateReducer';
import notificationReducer from './reducers/notificationReducer';
import { panelsReducer } from './reducers/panelsReducer';
import pendingMessagesReducer from './reducers/pendingMessagesReducer';
import { playerInfoReducer } from './reducers/playerInfoReducer';
import { prePurchaseReducer } from './reducers/prePurchaseReducer';
import { promotionsReducer } from './reducers/promotionsReducer';
import { purchaseReducer } from './reducers/purchaseReducer';
import rateTheAppReducer from './reducers/rateTheAppReducer';
import { rewardCenterReducer } from './reducers/rewardCenterReducer';
import { shopListReducer } from './reducers/shopListReducer';
import sidebarStateReducer from './reducers/sidebarStateReducer';
import { tooltipReducer } from './reducers/tooltipReducer';
import { updateReducer } from './reducers/updateReducer';
import termsAndConditionsReducer from './slices/termsAndConditions/termsAndConditions';
import missionsReducer from './slices/missions/missions';
import assetsPackageReducer from './slices/assetsPackage/assetsPackage';
import spinEventReducer from './slices/spinEvent/spinEvent';
import progressiveJackpotsReducer from './slices/progressiveJackpots/progressiveJackpots';
import agsiReducer from './reducers/agsiReducer';
import tutorialReducer from './slices/tutorial/tutorial';
import propertiesReducer from './slices/properties/properties';
import xpInfoReducer from './slices/xp/xp';
import levelInfoReducer from './slices/level/level';
import actionCommandsReducer from './slices/actionCommands/actionCommands';
import layoutReducer from './slices/layout/layout';
import lottieAnimationsReducer from './slices/lottieAnimations/lottieAnimations';
import loginProgressReducer from './slices/login/loginProgress';
import { missionsQueryReducer, missionsReducerPath } from './query/missions';
import { assetsPackageQueryReducer, assetsPackageReducerPath } from './query/assetsPackage';
import { linkGuestAccountQueryReducer, linkGuestAccountReducerPath } from './query/linkGuestAccount';
import { purchaseQueryReducer, purchaseReducerPath } from './query/purchase';
import { restartSessionQueryReducer, restartSessionReducerPath } from './query/restartSession';
import { urlsQueryReducer, urlsReducerPath } from './query/urls';
import { loginQueryReducer, loginReducerPath } from './query/login';
import { signupQueryReducer, signupReducerPath } from './query/signup';
import { oneTimePasswordQueryReducer, oneTimePasswordReducerPath } from './query/oneTimePassword';
import { rewardCenterQueryReducer, rewardCenterReducerPath } from './query/rewardCenter';
import { textsReducerPath, textsQueryReducer } from './query/texts';
import { dailyWheelQueryReducer, dailyWheelReducerPath } from './query/dailyWheel';
import { gamesQueryReducer, gamesReducerPath } from './query/games';
import { actionCommandsQueryReducer, actionCommandsReducerPath } from './query/actionCommands';
import { registerNewCardQueryReducer, registerNewCardReducerPath } from './query/RegisterNewCard';
import { eventsQueryReducer, eventsReducerPath } from './query/events';
import { shopQueryReducer, shopReducerPath } from './query/shop';
var persistConfig = {
  key: 'root',
  storage: storage,
  stateReconciler: autoMergeLevel2,
  timeout: 0,
  whitelist: ['token', 'restart', 'lastActive', 'prePurchase', 'shopList', 'apiRootIndex', 'callbackIntent', 'legal', 'level', 'ftue', 'rateTheApp', 'pendingMessages', 'playerInfo', 'notifications', 'attPermission', 'lottieAnimations'],
  blacklist: ['gameDownload']
};
var gameDownloadPersistConfig = {
  key: 'gameDownload',
  storage: storage,
  stateReconciler: autoMergeLevel2,
  timeout: 0,
  whitelist: ['downloadedGames', 'maxDownloadedGames', 'downloadFailedGames']
};
var missionsPersistConfig = {
  key: 'missions',
  storage: storage,
  stateReconciler: autoMergeLevel2,
  timeout: 0,
  whitelist: ['currentMission']
};
var assetsPackagePersistConfig = {
  key: 'assetsPackage',
  storage: storage,
  stateReconciler: autoMergeLevel2,
  timeout: 0,
  whitelist: ['assetsPackageId', 'lastRevisionNumber']
};
var tutorialPersistConfig = {
  key: 'tutorial',
  storage: storage,
  stateReconciler: autoMergeLevel2,
  timeout: 0,
  whitelist: ['tutorialStep']
};
var levelPersistConfig = {
  key: 'level',
  storage: storage,
  stateReconciler: autoMergeLevel2,
  timeout: 0,
  whitelist: ['level', 'max']
};
var purchasePersistConfig = {
  key: 'purchase',
  storage: storage,
  stateReconciler: autoMergeLevel2,
  timeout: 0,
  whitelist: ['actionTrigger']
};
var gameInfoPersistConfig = {
  key: 'gameInfo',
  storage: storage,
  stateReconciler: autoMergeLevel2,
  timeout: 0,
  whitelist: ['gameId']
};
var queryReducer = (_queryReducer = {}, _defineProperty(_queryReducer, missionsReducerPath, missionsQueryReducer), _defineProperty(_queryReducer, assetsPackageReducerPath, assetsPackageQueryReducer), _defineProperty(_queryReducer, linkGuestAccountReducerPath, linkGuestAccountQueryReducer), _defineProperty(_queryReducer, purchaseReducerPath, purchaseQueryReducer), _defineProperty(_queryReducer, restartSessionReducerPath, restartSessionQueryReducer), _defineProperty(_queryReducer, urlsReducerPath, urlsQueryReducer), _defineProperty(_queryReducer, loginReducerPath, loginQueryReducer), _defineProperty(_queryReducer, signupReducerPath, signupQueryReducer), _defineProperty(_queryReducer, oneTimePasswordReducerPath, oneTimePasswordQueryReducer), _defineProperty(_queryReducer, rewardCenterReducerPath, rewardCenterQueryReducer), _defineProperty(_queryReducer, textsReducerPath, textsQueryReducer), _defineProperty(_queryReducer, dailyWheelReducerPath, dailyWheelQueryReducer), _defineProperty(_queryReducer, gamesReducerPath, gamesQueryReducer), _defineProperty(_queryReducer, actionCommandsReducerPath, actionCommandsQueryReducer), _defineProperty(_queryReducer, registerNewCardReducerPath, registerNewCardQueryReducer), _defineProperty(_queryReducer, eventsReducerPath, eventsQueryReducer), _defineProperty(_queryReducer, shopReducerPath, shopQueryReducer), _queryReducer);
var rootReducer = combineReducers(_objectSpread(_objectSpread({}, queryReducer), {}, {
  app: appReducer,
  load: loadReducer,
  device: deviceReducer,
  token: tokenReducer,
  fbToken: fbTokenReducer,
  restart: restartReducer,
  authorized: authorizedReducer,
  lastActive: lastActiveReducer,
  navState: navStateReducer,
  sidebarState: sidebarStateReducer,
  formInfo: formInfoReducer,
  modal: modalReducer,
  gameInfo: persistReducer(gameInfoPersistConfig, gameInfoReducer),
  newGames: newGamesReducer,
  featuredGames: featuredGamesReducer,
  slotGames: slotGamesReducer,
  tableGames: tableGamesReducer,
  vipGames: vipGamesReducer,
  favouriteGames: favouriteGameReducer,
  coin: coinReducer,
  premium: premiumReducer,
  loyalty: loyaltyReducer,
  limitedTimeOffer: ltoReducer,
  xpInfo: xpInfoReducer,
  levelInfo: persistReducer(levelPersistConfig, levelInfoReducer),
  notifications: notificationReducer,
  playerInfo: playerInfoReducer,
  prePurchase: prePurchaseReducer,
  purchase: persistReducer(purchasePersistConfig, purchaseReducer),
  shopList: shopListReducer,
  callbackIntent: callbackIntentReducer,
  rewardCenter: rewardCenterReducer,
  apiRootIndex: apiRootReducer,
  consumables: consumablesReducer,
  ftue: ftueReducer,
  lastWin: lastWinReducer,
  rateTheApp: rateTheAppReducer,
  betInfo: betInfoReducer,
  pendingMessages: pendingMessagesReducer,
  videoAds: videoAdsReducer,
  update: updateReducer,
  currentOnboarding: currentOnboardingReducer,
  panels: panelsReducer,
  promotions: promotionsReducer,
  tooltip: tooltipReducer,
  availableServices: availableServicesReducer,
  audio: audioReducer,
  timeRemaining: timeRemainingReducer,
  appsFlyerValidation: appsFlyerValidationReducer,
  attPermission: attPermissionReducer,
  gameDownload: persistReducer(gameDownloadPersistConfig, gameDownloadReducer),
  featureFlags: featureFlagsReducer,
  agsi: agsiReducer,
  termsAndConditions: termsAndConditionsReducer,
  progressiveJackpots: progressiveJackpotsReducer,
  missions: persistReducer(missionsPersistConfig, missionsReducer),
  assetsPackage: persistReducer(assetsPackagePersistConfig, assetsPackageReducer),
  lastSpinEvents: spinEventReducer,
  tutorial: persistReducer(tutorialPersistConfig, tutorialReducer),
  properties: propertiesReducer,
  loginProgressState: loginProgressReducer,
  actionCommands: actionCommandsReducer,
  layout: layoutReducer,
  lottieAnimations: lottieAnimationsReducer
}));
export var pReducer = persistReducer(persistConfig, rootReducer);