import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ACTIONS_COMMANDS_ASSETS_CATEGORY_TYPE, ACTION_COMMANDS_MODAL_TYPE, ACTION_COMMANDS_TYPE } from '../../../api/ActionCommandsInterface/constants/types';
import { EVENT_EMITTER_TYPE } from '../../../constants';
import Emitter from '../../../services/EmitterService';
import QueryParamProxy from '../../../utils/queryParamProxy';
import { generateButtonsRendering } from '../utils/generateButtonsRendering';
import { ACTION_COMMANDS_EVENT_TYPE } from '../../../api/ActionCommandsInterface/constants';
import screens from '../..';

var useDynamicPopups = function useDynamicPopups() {
  var _useSelector = useSelector(function (state) {
    return state.actionCommands.actions.find(function (action) {
      var _action$payload;

      return (action === null || action === void 0 ? void 0 : (_action$payload = action.payload) === null || _action$payload === void 0 ? void 0 : _action$payload.screen) === screens.Home;
    });
  }),
      modal = _useSelector.modal;

  var modalType = modal.modalType,
      onDisplayAction = modal.onDisplayAction;
  var actionType = onDisplayAction.actionType,
      triggerExecution = onDisplayAction.triggerExecution,
      _onDisplayAction$payl = onDisplayAction.payload,
      payload = _onDisplayAction$payl === void 0 ? null : _onDisplayAction$payl;
  var modalImageSource = useSelector(function (state) {
    var _state$assetsPackage, _state$assetsPackage$;

    return (_state$assetsPackage = state.assetsPackage) === null || _state$assetsPackage === void 0 ? void 0 : (_state$assetsPackage$ = _state$assetsPackage.assets[ACTIONS_COMMANDS_ASSETS_CATEGORY_TYPE[modalType]]) === null || _state$assetsPackage$ === void 0 ? void 0 : _state$assetsPackage$.MODAL_BACKGROUND;
  });
  var buttonsRendering = generateButtonsRendering(modal);
  useEffect(function () {
    Emitter.emit(EVENT_EMITTER_TYPE.ACTION_COMMANDS, {
      actionType: actionType,
      data: {
        event: ACTION_COMMANDS_EVENT_TYPE.DISPLAY_APP_MODAL,
        modalType: modalType,
        triggerExecution: triggerExecution,
        payload: payload
      }
    });
    return function () {
      Emitter.emit(EVENT_EMITTER_TYPE.ACTION_COMMANDS, {
        actionType: ACTION_COMMANDS_TYPE.CLEAR_ACTION_COMMAND
      });
      QueryParamProxy.modalPromiseAccept();
    };
  }, [actionType, modalType, triggerExecution, payload]);
  return {
    modalImageSource: modalImageSource,
    showLinkAccountButtons: modalType === ACTION_COMMANDS_MODAL_TYPE.SAVE_YOUR_PROGRESS,
    buttonsRendering: buttonsRendering,
    modalType: modalType,
    onDisplayAction: onDisplayAction
  };
};

export default useDynamicPopups;