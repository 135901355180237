export default {
  API_ROOT: "api.admes.greentubepro.com",
  SSO_ENABLED: undefined,
  TITLE: "ADMIRAL Fun",
  FACEBOOK_PIXEL_BASE: undefined,
  XTREMEPUSH_ID: "3tCmiB47lUfqGzvqnHpblTUET92wwEUI",
  APPLICATION_TARGET: "admes",
  GOOGLE_CLIENT_ID: "720915024349-8itqk14ncab1aq3eg43mjkjsqisr7gmh.apps.googleusercontent.com",
  GOOGLE_AD_URL: "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js",
  ENVIRONMENT: "prod",
  PRELOAD_ASSETS: "true",
  MOBILE_WEB_ONE_LINK: "https://admiralfunat.onelink.me/OFBy",
  HELP_LINK: "admiralfuneshelp.zendesk.com",
  SITE_KEY: "6Le22jcUAAAAAJoEKGduRlplqw7scLoVxpwTjo1n",
  ACCOUNT_EMAIL: "false",
  ACCOUNT_CHANGE_PASSWORD: "false",
  PLAY_AS_GUEST: "true",
  APPLE_STORE_URL: "https://apps.apple.com/us/app/admiral-fun-es/id6463748925",
  ANDROID_STORE_URL: "https://play.google.com/store/apps/details?id=com.greentubepro.admes",
  SSO_CALLBACK_PATH: undefined,
  DSDK_APP_TOKEN: "abcfd631-cbdf-48d8-806c-263fb9b2a912",
  DSDK_HASH_KEY: "6385B1A59C08D50A71399",
  DSDK_SV_URL: "https://a.bluebat.dive.games/admiralesp",
  DSDK_API_URL: "https://api.bluebat.dive.games",
  DSDK_DOMAIN: "admiralfun.es",
  EXPERIAN_ENVIRONMENT_KEY: undefined,
  SSO_DEV: undefined,
  NODE_ENV: "production",
  CDN_ROOT: "admiralfun.es",
  APP_ID: undefined,
  VERSION_MAJOR: "2",
  VERSION_MINOR: "6",
  VERSION_PATCH: "0",
  VERSION_BUILD: "1",
  PUBLIC_URL: undefined,
  ZENDESK_URL: "https://admiralfuneshelp.zendesk.com/hc/en-us/requests/new",
  LINKING_CARD_POPUP_ON_HIT_REWARD_CENTER: "false",
  SHOW_TIER_TOOLTIP_IF_PHYSICALLY_VERIFIED: "false",
  SHOW_PROPERTY_SELECTOR: "true",
  DYNAMIC_HIGHLIMIT_POPUP: "true",
  DYNAMIC_PROPERTY: "true",
  KEYCHAIN_SERVICE_KEY: undefined,
  SHARED_PREFERENCES_KEY: undefined,
  REWARD_CENTER_QR_LAYOUT: undefined,
  DIVE_ERROR_HANDLING: "yes" ? "yes" : 'no',
  ENABLE_API_TRACKING: "true",
  GOOGLE_TAG_MANAGER_CONTAINER_ID: "GTM-TF42TDS2",
  APPSFLYER_PATH_PREFIX: undefined,
  LANGUAGE: "es",
  PLAY_AS_GUEST_TYPE: "TEXT",
  ONE_TRUST_DATA_DOMAIN_SCRIPT: "a8579ba7-d26f-472f-916e-21c22412dbb9"
};