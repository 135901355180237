import React from 'react';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import { LoyaltyPointsText, Container } from './styledComponents';

const RewardLoyaltyPointsTab = ({ loyaltyExpiryDate }) => {
  return (
    <Container>
      <LoyaltyPointsText>
        {getText(TEXT_KEY.YOUR)} {getText(TEXT_KEY.LOYALTY_POINTS)}
        {getText(TEXT_KEY.EXPIRE_ON)}
        {loyaltyExpiryDate}, {getText(TEXT_KEY.BE_QUICK_AND_REDEEM_NOW)}
      </LoyaltyPointsText>
    </Container>
  );
};

export default RewardLoyaltyPointsTab;

