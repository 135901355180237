import Logger from '../../../lib/analytics/logger';
import { EVENT_ACTIONS, EVENT_DOMAINS, EVENT_SCREENS, EVENT_TYPES } from '../../../lib/analytics/constants';
export var promoSlideChangeEventHandler = function promoSlideChangeEventHandler() {
  Logger.sendEvent(EVENT_TYPES.UI_EVENT, {
    action: EVENT_ACTIONS.SCROLL,
    domain: EVENT_DOMAINS.PROMO_GRID,
    screen: EVENT_SCREENS[EVENT_DOMAINS.GENERAL].LOBBY
  });
};
export var promoSlidePressEventHandler = function promoSlidePressEventHandler(_ref) {
  var slideNumber = _ref.slideNumber,
      bannerNumber = _ref.bannerNumber,
      action = _ref.action;
  Logger.sendEvent(EVENT_TYPES.UI_EVENT, {
    action: EVENT_ACTIONS.PRESS,
    domain: EVENT_DOMAINS.PROMO_GRID,
    screen: EVENT_SCREENS[EVENT_DOMAINS.GENERAL].LOBBY,
    payload: {
      slide: slideNumber,
      banner: {
        id: bannerNumber,
        action: action
      }
    }
  });
};