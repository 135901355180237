import styled from 'styled-components';
export const GameListWrapper = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  ${({ banner }) =>
    banner
      ? `
      margin-left: 24rem;
        @media only screen and (min-width: 1800px) {
          margin-left: 35rem;
        }
        @media only screen and (min-width: 2100px) {
          margin-left: 15rem;
        }
        `
      : `
      margin-left: 14.5rem;
        @media only screen and (max-width: 1300px) {
          margin-left: 15rem;
        }
        @media only screen and (max-width: 1400px) {
          margin-left: 17rem;
        }
        @media only screen and (min-width: 1800px) {
          margin-left: 12rem;
        }
        @media only screen and (min-width: 2200px) {
          margin-left: 14rem;
        }
        @media only screen and (max-height: 790) {
          margin-left: 17rem;
        }
        `}
`;

